import React, { FC, PropsWithChildren } from "react";
import { Context } from "./index";
import { FiUsers } from "react-icons/fi";
import OfferDetails from "./OfferDetails";

const AudienceOfferDetails: FC<PropsWithChildren<{ context: Context }>> = ({ context }) => {
  const data = context.step.content.audienceOffer;
  const rewards =
    (context.step.config.audienceOffer.displayRewards &&
      context.session.config?.rewards?.audienceRewards) ||
    [];

  return (
    <OfferDetails
      data={data}
      rewards={rewards}
      icon={FiUsers}
      showRewards={context.step.config.audienceOffer.displayRewards}
    />
  );
};

export default AudienceOfferDetails;
