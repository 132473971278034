export enum ShareTypes {
  Facebook = "facebook",
  FacebookMessenger = "messenger",
  Twitter = "twitter",
  Reddit = "reddit",
  Pinterest = "pinterest",
  Vkontakte = "vkontakte",
  Line = "line",
  Whatsapp = "whatsapp",
  Viber = "viber",
  Telegram = "telegram",
}
