import React, { FC, PropsWithChildren, useContext } from "react";

import Button from "components/button";
import VisuallyHidden from "components/visually-hidden";
import renderTemplate from "lib/Template";
import { DefaultContext } from "types/StepContext.types";
import { StepContext } from "context/index";

import { CaptionStyled, WrapperStyled } from "./RedirectFriendToClient.styled";

import { Context } from ".";

const RedirectContent: FC<PropsWithChildren<{ context: Context }>> = ({ context, children }) => {
  const { sessionContentReplacements } = useContext<DefaultContext>(StepContext);

  if (!context.step.optionalContent) return null;

  return (
    <WrapperStyled>
      <CaptionStyled>
        {renderTemplate(context.step.optionalContent.form.caption, sessionContentReplacements)}
      </CaptionStyled>
      <Button
        handleOnClick={context.methods.handleContinueToClientSite}
        isLoading={context.state.loading}
      >
        {context.step.optionalContent.form.button.text}
        <VisuallyHidden>Opens in a new tab</VisuallyHidden>
      </Button>
      {children}
    </WrapperStyled>
  );
};

export default RedirectContent;
