import React, { FC } from "react";
import { Context } from ".";
import InfoMessage from "components/info-message";

const EligibilityCheckRejectionMessage: FC<{ context: Context }> = ({ context }) => {
  if (
    !context.step.optionalContent ||
    !context.session?.config?.showEligibilityCheckRejectionMessage
  ) {
    return null;
  }
  return <InfoMessage message={context.step.optionalContent.eligibilityCheckRejectionMessage} />;
};

export default EligibilityCheckRejectionMessage;
