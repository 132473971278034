import { LayoutStyle, Modifier } from "lib/Lego";

const defaultLayout = {
  containerStyle: LayoutStyle.Regular,
  bricks: [
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [{ name: "Title", modifiers: [Modifier.TextAlignCenter] }],
        },
      ],
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [{ name: "Body", modifiers: [Modifier.TextAlignCenter] }],
        },
      ],
    },
  ],
};

export default defaultLayout;
