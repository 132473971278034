import React, { FC } from "react";
import { FiCopy } from "react-icons/fi";
import { RiShareBoxLine } from "react-icons/ri";
import copy from "copy-to-clipboard";

import Button from "components/button";

import { EventEnum } from "types/graphql";

import useRecordEvent from "hooks/RecordEvent";

import { ActionButtonsStyled } from "./NativeSharingTools.styled";

type Props = {
  nativeSharingTitle: string;
  nativeSharingMessage: string;
  nativeSharingShortUrl?: string;
  nativeSharingButtonText: string;

  manualSharingCopyButtonText: string;
  manualSharingCopyText: string;

  onCopy?: (copyService: string) => void;
};

const NativeSharingTools: FC<Props> = ({
  nativeSharingTitle,
  nativeSharingMessage,
  nativeSharingShortUrl = "",
  nativeSharingButtonText,
  manualSharingCopyText,
  manualSharingCopyButtonText,
  onCopy,
}) => {
  const copyShareUrlToClipboard = (): void => {
    copy(manualSharingCopyText);

    if (onCopy) {
      onCopy("Text button");
    }
  };

  const recordEvent = useRecordEvent();

  const onShareClick = (): void => {
    navigator
      .share({
        title: nativeSharingTitle,
        text: `${nativeSharingMessage}${nativeSharingShortUrl ? " " + nativeSharingShortUrl : ""}`,
      })
      .then(() => {
        recordEvent(EventEnum.Share, { share_network: "Native Sharing" });
      })
      .catch((e) => {
        if (e.name === "AbortError") {
          recordEvent(EventEnum.Share, { share_network: "Native Sharing" });
        } else {
          console.error(e);
        }
      });
  };

  return (
    <ActionButtonsStyled>
      <Button icon={<RiShareBoxLine aria-hidden={true} />} handleOnClick={onShareClick}>
        {nativeSharingButtonText}
      </Button>
      <Button
        icon={<FiCopy aria-hidden={true} />}
        appearance="secondary"
        handleOnClick={copyShareUrlToClipboard}
      >
        {manualSharingCopyButtonText}
      </Button>
    </ActionButtonsStyled>
  );
};

export default NativeSharingTools;
