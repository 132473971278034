import React, { FC, PropsWithChildren, useContext } from "react";
import parse from "html-react-parser";
import {
  WrapperStyled,
  BodyCopyStyled,
  HeadingStyled,
  ContentStyled,
} from "./RewardClaimed.styled";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";

import renderTemplate from "lib/Template";
import {
  FriendRewardAlreadyClaimedStep,
  FriendRewardClaimedStep,
  RewardEmailedReturningStep,
  RewardEmailedStep,
} from "types/graphql";

export type RewardClaimedProps = {
  stepData:
    | FriendRewardClaimedStep
    | FriendRewardAlreadyClaimedStep
    | RewardEmailedStep
    | RewardEmailedReturningStep;
};

const RewardClaimed: FC<PropsWithChildren<RewardClaimedProps>> = ({ stepData, children }) => {
  const { sessionContentReplacements, callbacks } = useContext<DefaultContext>(StepContext);

  if (callbacks && callbacks.onLayoutLoaded) callbacks.onLayoutLoaded();

  return (
    <WrapperStyled>
      <ContentStyled>
        <HeadingStyled>{parse(stepData.content.title)}</HeadingStyled>
        <BodyCopyStyled>
          <p>{renderTemplate(stepData.content.body, sessionContentReplacements)}</p>
        </BodyCopyStyled>
      </ContentStyled>

      {children}
    </WrapperStyled>
  );
};

export default RewardClaimed;
