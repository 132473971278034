import styled from "styled-components";
import { transparentize } from "polished";
import { useForSmallContainer } from "hooks/ResponsiveStyle";

export const InfoBoxContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const InfoBoxStyled = styled.div`
  flex: 1 1 10rem;
  background-color: ${({ theme }) => transparentize(0.92, theme.colors.highlight)};
  padding: 1.2rem;
  border-radius: 0.8rem;
  margin: 0.6rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  ${() =>
    useForSmallContainer(`
      margin-left: 0;
      padding: 1.2rem 0.7rem;

      &:last-of-type {
        margin-right: 0;
      }
  `)}
`;

export const InfoBoxStatStyled = styled.data`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.highlight};

  ${() =>
    useForSmallContainer(`
    font-size: 1rem;
  `)}
`;

export const InfoBoxHeadingStyled = styled.figcaption`
  font-weight: normal;
  font-size: 1rem;
  margin: 0.6rem;

  ${() =>
    useForSmallContainer(`
    font-size: 0.9rem;
    margin: 0.4rem;
    margin-bottom: 0.6rem;
  `)}
`;
