import { Layout, LayoutStyle } from "lib/Lego";
import { Context } from "lib/Lego/RewardRedemptionJourney/RedeemReward";

const defaultLayout = {
  containerStyle: LayoutStyle.Regular,
  bricks: [
    {
      name: "Row",
      bricks: [
        {
          name: "RewardContent",
        },
      ],
    },
  ],
} as Layout<Context>;

export default (layout: string): Layout<Context> => {
  switch (layout) {
    default:
      return defaultLayout;
  }
};
