import React, { FC, PropsWithChildren } from "react";
import { IconType } from "react-icons";
import { SvgWrapperStyled } from "./Svg.styled";

const Svg: FC<PropsWithChildren<{ svgImage: IconType; className?: string }>> = ({
  svgImage: SvgImage,
  className,
  ...props
}) => {
  return (
    <SvgWrapperStyled className={className}>
      <SvgImage {...props} />
    </SvgWrapperStyled>
  );
};

export default Svg;
