import styled from "styled-components";

import { ButtonStyled } from "components/button/Button.styled";
import { resolveColor } from "styles/helpers";
import { LinkBlendedInParagraphStyled, TitleStyled } from "styles/global.styled";

export const WrapperStyled = styled.div`
  padding: 0 15px;
  width: 100%;
`;

export const ContentStyled = styled.div`
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;

  ${ButtonStyled} {
    max-width: 310px;
    align-self: center;
  }
`;

export const HeadingStyled = styled(TitleStyled)`
  margin-bottom: 1rem;
`;

export const BodyCopyStyled = styled.div`
  margin: 0 0 2rem;

  p {
    color: ${({ theme }) => resolveColor(theme, theme.layout.color)};
    margin-top: 0;
    margin-bottom: 0;
  }

  ${LinkBlendedInParagraphStyled}
`;
