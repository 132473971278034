import React, { FC, useContext } from "react";

import { StepContext } from "../../../../context";
import { DefaultContext } from "types/StepContext.types";
import { ConfirmFriendRewardOptionStep, EventEnum } from "types/graphql";

import useRecordEvent from "hooks/RecordEvent";
import ConfirmRewardOption from "templates/click_to_claim/ConfirmRewardOption/ConfirmRewardOption";
import { renderString } from "lib/Template";

export type ConfirmFriendRewardOptionProps = {
  stepData: ConfirmFriendRewardOptionStep;
};

const ConfirmFriendRewardOption: FC<ConfirmFriendRewardOptionProps> = ({ stepData }) => {
  const { goToStep, currentStep, sessionContentReplacements, callbacks } =
    useContext<DefaultContext>(StepContext);
  const recordEvent = useRecordEvent();

  const handleFooterLinkClick = (): void => {
    recordEvent(EventEnum.ClickToClaimFriendLinkClicks, {
      step: currentStep.type,
      target: "footer_link",
    });

    window.open(renderString(stepData.content.footerLink.url, sessionContentReplacements));
  };

  const handleRewardRejection = (): void => {
    goToStep({
      type: "selectFriendRewardOption",
      config: null,
      contentReplacements: null,
    });
  };

  if (callbacks && callbacks.onLayoutLoaded) callbacks.onLayoutLoaded();

  return (
    <ConfirmRewardOption
      stepData={stepData}
      handleRewardRejection={handleRewardRejection}
      userType={"friend"}
      handleFooterLinkClick={handleFooterLinkClick}
    />
  );
};

export default ConfirmFriendRewardOption;
