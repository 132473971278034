import React, { FC, PropsWithChildren } from "react";
import {
  PaginatorListStyled,
  PaginatorListItemStyled,
  PaginatorListLinkStyled,
} from "./Pager.styled";

const Pager: FC<
  PropsWithChildren<{
    pageCount: number;
    selectedPage: number;
    onPageClick: (page: number) => void;
  }>
> = ({ pageCount, selectedPage, onPageClick }) => {
  return (
    pageCount > 1 && (
      <PaginatorListStyled role={"navigation"}>
        {Array.from(Array(pageCount).keys()).map((m) => (
          <PaginatorListItemStyled key={m}>
            <PaginatorListLinkStyled
              aria-label={`Go to page ${m + 1}`}
              role="link"
              selected={m === selectedPage}
              onClick={(): void => onPageClick(m + 1)}
            >
              &nbsp;
            </PaginatorListLinkStyled>
          </PaginatorListItemStyled>
        ))}
      </PaginatorListStyled>
    )
  );
};

export default Pager;
