import React, { FC, ReactNode } from "react";

import { Wrapper } from "./VisuallyHidden.styled";

type Props = {
  children: ReactNode;
};

const VisuallyHidden: FC<Props> = ({ children }) => <Wrapper>{children}</Wrapper>;

export default VisuallyHidden;
