import React, { FC } from "react";
import { Context } from ".";
import { BodyCopyStyled, ContentStyled } from "./IneligibleForVoucher.styled";
import renderTemplate from "lib/Template";
import Button from "components/button";
import VisuallyHidden from "components/visually-hidden";

const Body: FC<{ context: Context }> = ({ context }) => {
  if (!context.step.content || !context.session) {
    return null;
  }

  return (
    <ContentStyled>
      <BodyCopyStyled>
        <p>{renderTemplate(context.step.content.body, context.state.sessionContentReplacements)}</p>
      </BodyCopyStyled>
      {context.step.config.button.show && context.session.config?.destinationUrl && (
        <Button handleOnClick={context.methods.handleContinueToClientSite}>
          {context.step.content.button.text}
          <VisuallyHidden>Opens in a new tab</VisuallyHidden>
        </Button>
      )}
    </ContentStyled>
  );
};

export default Body;
