import { useContext, useCallback } from "react";
import useMutation from "hooks/UseMutation";
import UAParser from "ua-parser-js";
import { RollbarContext, RollbarType } from "providers/RollbarProvider";

import { StepContext } from "../../context";
import { DefaultContext } from "../../types/StepContext.types";

import { EventEnum, TrackEventPayload } from "../../types/graphql";

import { sharedQueries } from "queries";
import { ExecutionResult } from "graphql";

const useRecordEvent: () => (
  name: EventEnum,
  options?: object
) => Promise<ExecutionResult<{ trackEvent: TrackEventPayload }>> = () => {
  const { appConfig, utmParams } = useContext<DefaultContext>(StepContext);
  const { rollbar } = useContext<RollbarType>(RollbarContext);

  const [recordEvent] = useMutation<{ trackEvent: TrackEventPayload }, any>(
    sharedQueries.mutations.trackEvent,
    {
      onError: (err: any) => rollbar && rollbar.error(err),
    }
  );

  const id = appConfig.id;
  const campaignSlug = appConfig.campaignSlug;
  const flowId = appConfig.flow.id;

  return useCallback(
    (name: EventEnum, options: object = {}) => {
      const userAgentInfo = new UAParser().getResult();

      return recordEvent({
        variables: {
          id,
          campaignSlug,
          event: name,
          params: {
            ...options,
            referrer: document.referrer,
            browser: userAgentInfo.browser.name,
            browser_version: userAgentInfo.browser.version,
            current_url: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
            device: userAgentInfo.device.model,
            operating_system: userAgentInfo.os.name,
            screen_height: window.screen.height,
            screen_width: window.screen.width,
          },
          utmParams,
          flowId,
        },
      });
    },
    [campaignSlug, id, flowId, recordEvent, utmParams]
  );
};

export default useRecordEvent;
