import styled from "styled-components";

type CarouselStyledProps = {
  pageCount: number;
  itemWidthPercentage?: number;
};

export const CarouselScrollContainerStyled = styled.div`
  width: 100%;
  overflow: auto;
`;

export const CarouselStyled = styled.div<CarouselStyledProps>`
  width: ${({ pageCount, itemWidthPercentage }) => (itemWidthPercentage || 100) * pageCount + "%"};
  position: relative;
  display: flex;
  gap: 0.8rem;

  > * {
    box-sizing: border-box;
    width: ${({ pageCount }) => 100 / pageCount + "%"};

    ${({ itemWidthPercentage }) =>
      itemWidthPercentage &&
      `
      &:last-of-type {
        margin-right: 0.8rem;
      }
    `}
  }
`;
