import React, { FC } from "react";
import {
  WrapperStyled,
  ItemHeaderWrapper,
  Date,
  Title,
  DateWrapper,
  ItemBodyWrapper,
} from "./Item.styled";
import FormattedDate from "components/FormattedDate";
import Tooltip from "components/Tooltip/Tooltip";
import { Name, StatusText } from "../../../Shared";
import { ReferrerDashboardActiveStepConfigReferralStatusReferral } from "types/graphql";

type Props = {
  item: ReferrerDashboardActiveStepConfigReferralStatusReferral;
  type: string;
  purchaseTitle: string;
  statusText: string;
};

const Item: FC<Props> = ({ item, purchaseTitle, statusText, type }) => {
  return (
    <WrapperStyled>
      <ItemHeaderWrapper>
        <Tooltip message={item.name} type="name" direction="top">
          <Name>{item.name}</Name>
        </Tooltip>
        <StatusText type={type} text={statusText} />
      </ItemHeaderWrapper>
      <ItemBodyWrapper>
        <DateWrapper>
          <Title>{purchaseTitle}</Title>
          <Date>
            <FormattedDate timestamp={item.date} />
          </Date>
        </DateWrapper>
      </ItemBodyWrapper>
    </WrapperStyled>
  );
};

export default Item;
