import mainCss from "./index.css?raw";

import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloClient, ApolloProvider } from "@apollo/client";
import { apolloClientFactory } from "./apolloConfig";
import RollbarProvider from "./providers/RollbarProvider";

import { EmbedParameters } from "types/embed-parameters.types";

import AppErrorBoundary from "./components/app-error-boundary";
import { bootMain, bootPreview } from "boot";
import App from "./App";
import { Config, SessionInterface } from "types/graphql";

type Boot = (
  apolloClient: ApolloClient<object>,
  params: EmbedParameters,
  sessionId: string,
) => Promise<[Config, SessionInterface]>;

export class Embed {
  private container: HTMLElement | null = null;
  private errorContainer: HTMLElement | null = null;

  private params: EmbedParameters;
  private apolloClient: ApolloClient<{}>;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  private boot: Boot = null!;

  private hasError: boolean;

  constructor(params: EmbedParameters) {
    this.hasError = false;
    this.params = params;
    this.apolloClient = apolloClientFactory(this.graphqlEndpoint());

    this.setupFlowParams();
    this.setupUtmParams();

    this.boot = this.setupBoot();
  }

  render(container: HTMLElement | string, errorContainer: HTMLElement | string | null): void {
    const root = this.setupContainers(container, errorContainer);

    if (this.hasError) {
      if (this.errorContainer) this.errorContainer.style.display = "block";

      return;
    }

    if (root) {
      let existing = document.getElementById("bp-styles-e7414a9f-42dd-41ee-8d8e-57fb492d24cb");

      if (!existing) {
        const element = document.createElement("style");
        element.id = "bp-styles-e7414a9f-42dd-41ee-8d8e-57fb492d24cb";
        element.innerHTML = mainCss;

        document.head.appendChild(element);
      }

      root.render(
        <React.StrictMode>
          <RollbarProvider>
            <AppErrorBoundary errorContainer={this.errorContainer}>
              <ApolloProvider client={this.apolloClient}>
                <App params={this.params} boot={this.boot} />
              </ApolloProvider>
            </AppErrorBoundary>
          </RollbarProvider>
        </React.StrictMode>,
      );
    }
  }

  private setupUtmParams(): void {
    const urlParams = new URLSearchParams(window.location.search);

    this.params.utm = {
      utmCampaign: urlParams.get("utm_campaign"),
      utmContent: urlParams.get("utm_content"),
      utmMedium: urlParams.get("utm_medium"),
      utmSource: urlParams.get("utm_source"),
      utmTerm: urlParams.get("utm_term"),
    };
  }

  private setupFlowParams(): void {
    if (this.params.flow) {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);

    const name = urlParams.get("bp_f");
    if (!name) {
      this.fatalError("No flow name was passed in the query string");
      return;
    }

    const id = urlParams.get("bp_f_id");
    if (!id) {
      this.fatalError("No flow id was passed in the query string");
      return;
    }

    this.params.flow = {
      name: name,
      id: id,
    };
  }

  private setupContainers(
    container: HTMLElement | string,
    errorContainer: HTMLElement | string | null,
  ): ReactDOM.Root | null {
    if (container instanceof HTMLElement) this.container = container;
    else if (typeof container === "string") this.container = document.getElementById(container);

    if (!this.container) {
      this.fatalError("Unable to find container. This must be a HTMLElement or an element ID");
      return null;
    }

    if (errorContainer instanceof HTMLElement) this.errorContainer = errorContainer;
    else if (typeof errorContainer === "string")
      this.errorContainer = document.getElementById(errorContainer);
    else this.errorContainer = null;

    const root = ReactDOM.createRoot(this.container);

    return root;
  }

  private graphqlEndpoint(): string {
    if (!this.params.graphqlEndpoint) {
      return this.normalizeUrl(this.params.endpoint) + this.graphqlEndpointPath();
    }

    return this.params.graphqlEndpoint;
  }

  private graphqlEndpointPath(): string {
    if (this.isPreview()) {
      return "graphql-preview";
    }

    return "graphql";
  }

  private normalizeUrl(url: string): string {
    url = url.trim();
    if (url[url.length - 1] !== "/") url += "/";

    return url;
  }

  private fatalError(message: string): void {
    console.error(`BP Error: ${message}`);
    this.hasError = true;
  }

  private isPreview(): boolean {
    return !!this.params.preview;
  }

  private setupBoot(): Boot {
    return this.isPreview() ? bootPreview : bootMain;
  }
}
