import styled, { DefaultTheme } from "styled-components";
import { css } from "styled-components";
import { transparentize } from "polished";
import { resolveColor, resolveFontSize, resolveWithDefault } from "styles/helpers";
import { TargetTooltipWrapperStyled } from "components/TargetTooltip/TargetTooltip.styled";
import { SvgWrapperStyled } from "../svg/Svg.styled";

type LabelProps = {
  showLabel: boolean;
};

type InputProps = {
  showError: boolean;
  hasSuffixIcon: boolean;

  hasPrefixIcon: boolean;
};

export const textInputColor = (theme: DefaultTheme) =>
  resolveWithDefault(
    resolveColor(theme, theme.textInput.color),
    resolveColor(theme, theme.layout.color),
  );

export const textInputBackgroundColor = (theme: DefaultTheme) =>
  transparentize(
    0.9,
    resolveWithDefault(
      resolveColor(theme, theme.textInput.backgroundColor),
      resolveColor(theme, theme.layout.color),
    ),
  );

export const textInputLabelColor = (theme: DefaultTheme) =>
  resolveWithDefault(
    resolveColor(theme, theme.textInput.label.color),
    resolveColor(theme, theme.layout.color),
  );

export const InputStyled = styled.input<InputProps>`
  font-family: inherit;
  height: ${({ theme }) => theme.textInput.height};
  font-size: ${({ theme }) => resolveFontSize(theme, theme.textInput.fontSize)};
  border-radius: ${({ theme }) => theme.textInput.borderRadius};
  background-color: ${({ theme }) => textInputBackgroundColor(theme)};
  outline: 0;
  margin: 0;
  border: 0;
  padding: 0 1rem;

  ${({ hasSuffixIcon }) => hasSuffixIcon && `padding-right: 3.2rem;`}

  width: 100%;
  box-sizing: border-box;
  color: ${({ theme }) => textInputColor(theme)};
  box-shadow: ${({ showError, theme }) =>
      showError ? `0 0 0 2px ${resolveColor(theme, theme.textInput.error.color)}` : 0}
    inset;
  &:focus {
    box-shadow: ${({ showError, theme }) =>
        showError
          ? `0 0 0 2px ${resolveColor(theme, theme.textInput.error.color)}`
          : `0 0 0 2px ${resolveColor(theme, theme.textInput._focus.borderColor)}`}
      inset;
  }

  &::placeholder {
    color: ${({ theme }) => transparentize(0.3, textInputColor(theme))};
  }

  ${({ theme }) => theme.textInput.css}

  ${({ hasPrefixIcon }) => hasPrefixIcon && `padding-left: 2rem;`}
`;

export const InputWrapperStyled = styled.div`
  position: relative;

  > ${SvgWrapperStyled}.prefix {
    //flex: 0 0 1rem;
    position: absolute;
    left: 0;
    top: 45%;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }

  > ${TargetTooltipWrapperStyled}, > ${SvgWrapperStyled}.suffix {
    position: absolute;
    right: 1.2rem;
    top: 50%;
    width: 1.5rem;
    height: 1.5rem;

    transform: translateY(-50%);
  }
`;

export const InputLabelStyled = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => textInputLabelColor(theme)};
`;

export const InputSpanStyled = styled.span<LabelProps>`
  font-size: ${({ theme }) => resolveFontSize(theme, theme.textInput.label.fontSize)};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
  padding-bottom: 10px;
  display: block;

  ${(props) =>
    !props.showLabel &&
    css`
      border: 0;
      clip: rect(0 0 0 0);
      clippath: inset(50%);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    `}
`;

export const InputErrorWrapper = styled.div`
  padding-top: 3px;
`;

export const InputErrorStyled = styled.div`
  font-size: ${({ theme }) => resolveFontSize(theme, "extra_small")};
  color: ${({ theme }) => resolveColor(theme, theme.textInput.error.color)};
  font-weight: bold;
`;
