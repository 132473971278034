import React, { FC } from "react";

import { FormLegal } from "components/legal";

import { Context } from ".";
import render from "lib/Template";

const formLegal: FC<{ context: Context }> = ({ context }) => {
  if (!context.step.content) {
    return null;
  }

  return (
    <FormLegal>
      {render(context.step.content.form.finePrint, context.state.sessionContentReplacements)}
    </FormLegal>
  );
};

export default formLegal;
