import Title from "./Title";
import Body from "./Body";

import { PageDisabledStep } from "types/graphql";
import { StepConfig } from "types/StepContext.types";

export type Context = {
  step: PageDisabledStep;
  session?: StepConfig;
  methods: {};
  state: {
    sessionContentReplacements: { [key: string]: string };
  };
};

export const BrickTypes: { [index: string]: React.FC<{ context: Context }> } = {
  Title: Title,
  Body: Body,
};
