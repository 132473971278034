import { useEffect, FC } from "react";
import WebFont from "webfontloader";

type Props = {
  fonts: {
    url: string;
    family: string;
  }[];
};

const FontLoader: FC<Props> = ({ fonts }) => {
  useEffect(() => {
    const families = fonts.map((font) => font.family);
    const urls = fonts.map((font) => font.url);

    const loadFonts = () => {
      WebFont.load({
        custom: {
          families,
          urls,
        },
      });
    };

    fonts.length > 0 && loadFonts();
  }, [fonts]);

  return null;
};

export default FontLoader;
