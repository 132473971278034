import React, { FC, useContext } from "react";
import { IneligibleForVoucherStep, EventEnum } from "types/graphql";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";
import useRecordEvent from "hooks/RecordEvent";
import { Context } from "lib/Lego/FriendJourney/IneligibleForVoucher";
import { Lego } from "lib/Lego";
import { BrickTypes } from "lib/Lego/FriendJourney/IneligibleForVoucher";
import getLayout from "./layouts";

export type IneligibleForVoucherProps = {
  stepData: IneligibleForVoucherStep;
};

const IneligibleForVoucher: FC<IneligibleForVoucherProps> = ({ stepData }) => {
  const { sessionContentReplacements, sessionConfig, appConfig } =
    useContext<DefaultContext>(StepContext);
  const recordEvent = useRecordEvent();

  const handleContinueToClientSite = (): void => {
    recordEvent(EventEnum.ContinueToClientSite);
    window.open(sessionConfig.config?.destinationUrl);
  };

  const context: Context = {
    step: stepData,
    session: sessionConfig,
    methods: {
      handleContinueToClientSite,
    },
    state: {
      sessionContentReplacements,
    },
    optionalStep: true,
  };

  return (
    <Lego<Context>
      brickTypes={BrickTypes}
      layout={getLayout("default")}
      context={context}
      isPreview={appConfig.isPreview}
    />
  );
};

export default IneligibleForVoucher;
