import React, { FC, PropsWithChildren } from "react";

import Input from "components/input";
import BaseForm from "components/form";
import Switch from "components/switch";
import Button from "components/button";

import { Context } from ".";
import DateEditor from "components/DateEditor";
import {
  FriendFormStyled,
  PartnerFormCaptionStyled,
  PartnerFormStyled,
} from "./CaptureFriendDetails.styled";
import {
  CustomFieldInputConfig,
  CustomFieldInputConfigInterface,
  CustomFieldListInputConfig,
} from "@/types/graphql";
import Dropdown, { DropdownOption } from "@/components/dropdown/Dropdown";

const Form: FC<PropsWithChildren<{ context: Context }>> = ({ context, children }) => {
  if (!context.step.content || !context.step.config) {
    return null;
  }

  const inputConfig = context.step.config.inputs;
  const inputContent = context.step.content.form.inputs;

  const overrideFormStyled = context.state.isPartnerAudience ? PartnerFormStyled : FriendFormStyled;

  const fields: string[] = ["fullName", "firstName", "lastName", "email"].filter(
    (f) => f && (inputConfig as { [key: string]: any })[f].show,
  );

  const showCustomFields =
    !context.step.config.partialRegistration && inputConfig.customFields.length > 0;
  const customFields = inputConfig.customFields.map((cf) => cf as CustomFieldInputConfigInterface);

  return (
    <BaseForm
      overrideFormStyled={overrideFormStyled}
      handleOnSubmit={context.methods.handleFriendRegistration}
      formCaption={context.step.content.form.caption}
      overrideFormCaptionStyled={PartnerFormCaptionStyled}
    >
      {fields.flatMap((m) => {
        const field = (inputConfig as { [key: string]: any })[m];
        const content = (inputContent as { [key: string]: any })[m];

        return (
          <Input
            key={m}
            placeholder={context.state.isPartnerAudience ? "" : content.placeholder}
            errorMessage={
              (content?.validationErrors || []).find((f: any) => f.key === "default")?.value || ""
            }
            required
            label={content.label}
            name={m}
            regex={field.pattern}
            inputType={m.indexOf("email") > -1 ? "email" : "text"}
            showLabel={context.state.isPartnerAudience}
            tooltip={content.tooltip || undefined}
          />
        );
      })}
      {showCustomFields &&
        customFields
          .slice()
          .sort((a, b) => a.position - b.position)
          .map((field, i) => {
            const fieldContent = inputContent.customFields.find((f) => f.key === field.key);
            const content = fieldContent?.content;

            switch (field.inputFieldType) {
              case "list":
                const listField = field as CustomFieldListInputConfig;

                const options: DropdownOption[] =
                  content?.items?.map((item, i) => {
                    return { value: listField?.items[i], label: item };
                  }) || [];

                return (
                  <Dropdown
                    key={`custom-field-${i}`}
                    label={content?.label || ""}
                    placeholder={content?.placeholder || ""}
                    options={options}
                    name={`customFields[${field.position}]`}
                    errorMessage={
                      (content?.validationErrors || []).find((f) => f.key === "default")?.value ||
                      ""
                    }
                  />
                );
              case "date":
                return (
                  <DateEditor
                    key={`custom-field-${i}`}
                    label={content?.label || ""}
                    errorMessage={
                      (content?.validationErrors || []).find((f) => f.key === "default")?.value ||
                      ""
                    }
                    required
                    name={`customFields[${field.position}]`}
                    tooltip={content?.tooltip || undefined}
                  />
                );
              case "phone_number":
                const phoneNumberField = field as CustomFieldInputConfig;
                return (
                  <Input
                    key={`custom-field-${i}`}
                    placeholder={content?.placeholder || ""}
                    label={content?.label || ""}
                    errorMessage={
                      (content?.validationErrors || []).find((f) => f.key === "default")?.value ||
                      ""
                    }
                    required
                    name={`customFields[${phoneNumberField.position}]`}
                    regex={phoneNumberField.pattern}
                    tooltip={content?.tooltip || undefined}
                    showLabel={context.state.isPartnerAudience}
                    inputType="tel"
                  />
                );
              case "numeric":
              case "text":
              default:
                const textField = field as CustomFieldInputConfig;
                return (
                  <Input
                    key={`custom-field-${i}`}
                    placeholder={content?.placeholder || ""}
                    label={content?.label || ""}
                    errorMessage={
                      (content?.validationErrors || []).find((f) => f.key === "default")?.value ||
                      ""
                    }
                    required
                    name={`customFields[${textField.position}]`}
                    regex={textField.pattern}
                    tooltip={content?.tooltip || undefined}
                    showLabel={context.state.isPartnerAudience}
                    numericKeypad={textField.inputFieldType === "numeric"}
                  />
                );
            }
          })}
      {inputConfig.marketingOptIn.show && (
        <Switch
          label={context.step.content.form.inputs.marketingOptIn.label}
          name="marketingOptIn"
          required={inputConfig.marketingOptIn.required}
          errorMessage={
            (inputContent.marketingOptIn.validationErrors || []).find((f) => f.key === "default")
              ?.value || ""
          }
          spaceBetween
        />
      )}
      {inputConfig.termsOptIn.show && (
        <Switch
          label={inputContent.termsOptIn.label}
          name="termsOptIn"
          required
          errorMessage={
            (inputContent.termsOptIn.validationErrors || []).find((f) => f.key === "default")
              ?.value || ""
          }
          spaceBetween
        />
      )}

      {context.step.config.button.show && (
        <Button appearance="primary" buttonType="submit" isLoading={context.state.loading}>
          {context.step.content.form.button.text}
        </Button>
      )}

      {children}
    </BaseForm>
  );
};

export default Form;
