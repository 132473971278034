import styled from "styled-components";
import { transparentize } from "polished";

type PaginatorListLinkProps = {
  selected?: boolean;
};

export const PaginatorListStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
`;

export const PaginatorListItemStyled = styled.li`
  display: flex;
  margin: 0 5px 0 0;
`;

export const PaginatorListLinkStyled = styled.a<PaginatorListLinkProps>`
  background-color: ${({ theme, selected }) =>
    theme.colors &&
    (selected ? theme.colors.highlight : transparentize(0.9, theme.colors.highlight))};
  border-radius: 10px;

  display: inline-block;
  width: ${({ selected }) => (selected ? "0.6rem;" : "0.4rem")};
  height: ${({ selected }) => (selected ? "0.6rem;" : "0.4rem")};
`;
