import { createSessionId, getSessionId } from "lib/SessionId";
import { EmbedParameters } from "types/embed-parameters.types";

export const newSession = (flow: string, market: string, campaign: string): string => {
  return createSessionId(flow, market, campaign);
};

export default (params: EmbedParameters): void => {
  if (getSessionId(params.flow.name, params.market, params.campaign).length > 0) {
    return;
  }

  newSession(params.flow.name, params.market, params.campaign);
};
