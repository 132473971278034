import React, { FC } from "react";
import { IconType } from "react-icons/lib";

import Svg from "components/svg/Svg";
import {
  DropdownErrorStyled,
  DropdownErrorWrapper,
  DropdownLabelStyled,
  DropdownSpanStyled,
  DropdownStyled,
  DropdownWrapperStyled,
  IconStyled,
} from "./Dropdown.styled";
import { IoIosArrowDown } from "react-icons/io";
import { useFormContext } from "react-hook-form";

export type DropdownOption = {
  label: string;
  value: string;
};

export type DropdownProps = {
  label: string;
  name: string;
  placeholder: string;
  options: DropdownOption[];
  register?: any;
  prefixIcon?: IconType;
  errorMessage?: string;
  showError?: boolean;
  showLabel?: boolean;
};

const Dropdown: FC<DropdownProps> = ({
  label,
  name,
  placeholder,
  options,
  prefixIcon,
  errorMessage,
  register,
  showError = false,
  showLabel = false,
}) => {
  const { watch } = useFormContext();
  const value = watch(name);

  const optionsSortedAlphabetically = options.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <>
      <DropdownLabelStyled>
        <DropdownSpanStyled $showLabel={showLabel}>{label}</DropdownSpanStyled>

        <DropdownWrapperStyled>
          {prefixIcon && <Svg svgImage={prefixIcon} className="prefix" />}

          <DropdownStyled
            name={name}
            required
            defaultValue=""
            $hasPrefixIcon={!!prefixIcon}
            $showError={showError}
            $hasValue={!!value}
            {...(register ? register(name, { required: true }) : null)}
          >
            <option value="" disabled>
              {placeholder}
            </option>

            {optionsSortedAlphabetically.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </DropdownStyled>

          <IconStyled svgImage={IoIosArrowDown} aria-hidden="true" />
        </DropdownWrapperStyled>
      </DropdownLabelStyled>

      {showError && (
        <DropdownErrorWrapper>
          <DropdownErrorStyled>{errorMessage || "Required"}</DropdownErrorStyled>
        </DropdownErrorWrapper>
      )}
    </>
  );
};

export default Dropdown;
