import { StepConfig } from "types/StepContext.types";
import { ClickToRedirectFriendStep } from "types/graphql";
import Offer from "./Offer";
import Legal from "./Legal";
import RedirectContent from "./RedirectContent";
import RedirectLegal from "./RedirectLegal";
import SubmissionError from "./SubmissionError";

export type Context = {
  step: ClickToRedirectFriendStep;
  session?: StepConfig;
  methods: {
    handleContinueToClientSite: () => void;
  };
  state: {
    registrationError: boolean;
    sessionContentReplacements: any;
    loading: boolean;
  };
  optionalStep: boolean;
};

export const BrickTypes: { [index: string]: React.FC<{ context: Context }> } = {
  Offer: Offer as React.FC<{ context: Context }>,
  Legal: Legal as React.FC<{ context: Context }>,
  RedirectContent: RedirectContent,
  RedirectLegal: RedirectLegal,
  SubmissionError: SubmissionError,
};
