import styled from "styled-components";
import { transparentize } from "polished";
import { resolveColor, resolveFontSize } from "styles/helpers";

export const WrapperStyled = styled.div`
  display: grid;
  justify-content: start;
  padding: 0.8rem;
  gap: 0.8rem;
  grid-template-columns: 1fr 1fr;
  color: ${({ theme }): string => resolveColor(theme, theme.layout.color)};
`;

export const HeaderItemStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 1.2rem;
  text-align: left;
  border-radius: 8px 8px 8px 8px;
  background-color: ${({ theme }) => transparentize(0.9, resolveColor(theme, theme.layout.color))};
`;

export const HeaderItemTitle = styled.div`
  font-weight: 200;
  font-size: ${({ theme }): string => resolveFontSize(theme, "extra_small")};
`;

export const NumericItemTitle = styled.div`
  font-weight: 600;
  font-size: ${({ theme }): string => resolveFontSize(theme, "large")};
`;
