import React, { FC } from "react";
import { StyledStatusText } from "./StyledStatusText.styled";

type Props = {
  text: string;
  type: string;
};

export const StatusText: FC<Props> = ({ text, type }) => (
  <StyledStatusText type={type}>{text}</StyledStatusText>
);
