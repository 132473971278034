import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100vh;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 35ch;
  gap: 0.625rem;
  text-align: center;
`;

export const FooterContainer = styled.div`
  margin-bottom: 1.875rem;
`;
