import React, { FC } from "react";
import { Context } from ".";
import renderTemplate from "lib/Template";
import {
  BodyCopyStyled,
  ContentStyled,
  WrapperStyled,
} from "./SuccessfulFriendRegistration.styled";
import Button from "components/button";
import { SubmissionErrorStyled } from "../CaptureFriendDetails/CaptureFriendDetails.styled";
import { ImageTiles } from "components/image-tiles/ImageTiles";

import { BasicButtonContent, TileButtonContent } from "types/graphql";
import { ContainerSize } from "hooks/Device";

import VisuallyHidden from "components/visually-hidden";

const Body: FC<{ context: Context }> = ({ context }) => {
  if (!context.step.optionalContent || !context.step.optionalConfig || !context.session) {
    return null;
  }

  let buttons = null;

  if (context.step.optionalConfig.button.show && context.session.config?.buttons) {
    const buttonAsImageTiles =
      context.session.config?.buttons?.filter((f) => !!(f as TileButtonContent).image)?.length > 0;
    const imageTileButtons = context.session.config?.buttons?.map(
      (button) => button as TileButtonContent
    );
    const standardButtons = context.session.config?.buttons?.map(
      (button) => button as BasicButtonContent
    );

    buttons = buttonAsImageTiles ? (
      <ImageTiles
        buttons={imageTileButtons}
        appearance={"primary"}
        handleCallToActionClick={context.methods.handleContinueToClientSite}
        smallContainer={context.state.device.containerSize === ContainerSize.SmallContainer}
      />
    ) : (
      <>
        {standardButtons.map((button, index) => (
          <Button
            key={`cta_${index}`}
            handleOnClick={(): void => context.methods.handleContinueToClientSite(button.url)}
            appearance="primary"
          >
            {button.text}
            <VisuallyHidden>Opens in a new tab</VisuallyHidden>
          </Button>
        ))}
      </>
    );
  }

  return (
    <WrapperStyled>
      <ContentStyled>
        <BodyCopyStyled>
          <p>
            {renderTemplate(
              context.step.optionalContent.body,
              context.state.sessionContentReplacements
            )}
          </p>
        </BodyCopyStyled>

        {buttons}

        {context.session.config?.showSecondaryButton && (
          <Button
            handleOnClick={context.methods.handleAddFriendReferral}
            appearance="secondary"
            isLoading={context.state.loading}
          >
            {context.step.optionalContent.secondaryButton.text}
          </Button>
        )}
        {context.state.error && (
          <SubmissionErrorStyled>
            {/*TODO - replace this with schema data in a future ticket*/}
            An error has occurred
          </SubmissionErrorStyled>
        )}
      </ContentStyled>
    </WrapperStyled>
  );
};

export default Body;
