import { DocumentNode } from "graphql";
import clickToClaimReferrerConfig from "./flows/click-to-claim-referrer/config.graphql";
import clickToClaimReferrerSession from "./flows/click-to-claim-referrer/session.graphql";
import clickToClaimFriendConfig from "./flows/click-to-claim-friend/config.graphql";
import clickToClaimFriendSession from "./flows/click-to-claim-friend/session.graphql";
import clickToClaimSharedClaimReward from "./flows/click-to-claim/mutations/claim-reward.graphql";
import friendJourneyConfig from "./flows/friend-journey/config.graphql";
import friendJourneySession from "./flows/friend-journey/session.graphql";
import friendJourneyLoginViaIdentifier from "./flows/friend-journey/mutations/login-via-identifier.graphql";
import friendJourneyRegisterFriend from "./flows/friend-journey/mutations/register-friend.graphql";
import friendJourneyPartiallyRegisterFriend from "./flows/friend-journey/mutations/partially-register-friend.graphql";
import friendJourneyAddFriendDetails from "./flows/friend-journey/mutations/add-friend-details.graphql";
import friendJourneyAddFriendActionId from "./flows/friend-journey/mutations/add-friend-action-id.graphql";
import friendJourneyAddFriendReferral from "./flows/friend-journey/mutations/add-friend-referral.graphql";
import referrerJourneyConfig from "./flows/referrer-journey/config.graphql";
import referrerJourneySession from "./flows/referrer-journey/session.graphql";
import referrerJourneyRegisterReferrer from "./flows/referrer-journey/mutations/register-referrer.graphql";
import referrerJourneyLoginViaAuthToken from "./flows/referrer-journey/mutations/login-via-auth-token.graphql";
import referrerJourneyLoginViaSeamless from "./flows/referrer-journey/mutations/login-via-seamless.graphql";
import referrerJourneyLogout from "./flows/referrer-journey/mutations/logout.graphql";
import partnerJourneyConfig from "./flows/partner-journey/config.graphql";
import partnerJourneySession from "./flows/partner-journey/session.graphql";
import partnerJourneyLoginViaAuthToken from "./flows/partner-journey/mutations/partner-login-via-auth-token.graphql";
import lookupUserJourneyConfig from "./flows/lookup-user-journey/config.graphql";
import lookupUserJourneySession from "./flows/lookup-user-journey/session.graphql";
import lookupUserJourneyLookupUserViaCode from "./flows/lookup-user-journey/mutations/lookup-user-via-code.graphql";
import redirectFriendToClientJourneyConfig from "./flows/redirect-friend-to-client-journey/config.graphql";
import redirectFriendToClientJourneySession from "./flows/redirect-friend-to-client-journey/session.graphql";
import redirectFriendToClientJourneyRegisterFriendForRedirection from "./flows/redirect-friend-to-client-journey/mutations/register-friend-for-redirection.graphql";
import rewardRedemptionJourneyConfig from "./flows/reward-redemption-journey/config.graphql";
import rewardRedemptionJourneySession from "./flows/reward-redemption-journey/session.graphql";
import sharedTrackEvent from "./shared/mutations/track-event.graphql";

export const clickToClaimReferrerQueries = {
  config: clickToClaimReferrerConfig,
  session: clickToClaimReferrerSession,
};

export const clickToClaimFriendQueries = {
  config: clickToClaimFriendConfig,
  session: clickToClaimFriendSession,
};

export const clickToClaimSharedQueries = {
  mutations: {
    claimReward: clickToClaimSharedClaimReward,
  },
};

export const friendJourneyQueries = {
  config: friendJourneyConfig,
  session: friendJourneySession,
  mutations: {
    loginViaIdentifier: friendJourneyLoginViaIdentifier,
    registerFriend: friendJourneyRegisterFriend,
    partiallyRegisterFriend: friendJourneyPartiallyRegisterFriend,
    addFriendDetails: friendJourneyAddFriendDetails,
    addFriendActionId: friendJourneyAddFriendActionId,
    addFriendReferral: friendJourneyAddFriendReferral,
  },
};

export const referrerJourneyQueries = {
  config: referrerJourneyConfig,
  session: referrerJourneySession,
  mutations: {
    registerReferrer: referrerJourneyRegisterReferrer,
    loginViaAuthToken: referrerJourneyLoginViaAuthToken,
    loginViaSeamless: referrerJourneyLoginViaSeamless,
    logoutReferrer: referrerJourneyLogout,
  },
};

export const partnerJourneyQueries = {
  config: partnerJourneyConfig,
  session: partnerJourneySession,
  mutations: {
    partnerLoginViaAuthToken: partnerJourneyLoginViaAuthToken,
  },
};

export const lookupUserJourneyQueries = {
  config: lookupUserJourneyConfig,
  session: lookupUserJourneySession,
  mutations: {
    lookupUserViaCode: lookupUserJourneyLookupUserViaCode,
  },
};

export const redirectFriendToClientJourneyQueries = {
  config: redirectFriendToClientJourneyConfig,
  session: redirectFriendToClientJourneySession,
  mutations: {
    registerFriendForRedirection: redirectFriendToClientJourneyRegisterFriendForRedirection,
  },
};

export const rewardRedemptionJourneyQueries = {
  config: rewardRedemptionJourneyConfig,
  session: rewardRedemptionJourneySession,
};

export const sharedQueries = {
  mutations: {
    trackEvent: sharedTrackEvent,
  },
};

const flowConfigMap: { [key: string]: DocumentNode } = {
  click_to_claim_referrer: clickToClaimReferrerQueries.config,
  click_to_claim_friend: clickToClaimFriendQueries.config,
  friend_journey: friendJourneyQueries.config,
  referrer_journey: referrerJourneyQueries.config,
  partner_journey: partnerJourneyQueries.config,
  user_lookup_journey: lookupUserJourneyQueries.config,
  redirect_friend_to_client_journey: redirectFriendToClientJourneyQueries.config,
  reward_redemption_journey: rewardRedemptionJourneyQueries.config,
};

const flowSessionMap: { [key: string]: DocumentNode } = {
  click_to_claim_referrer: clickToClaimReferrerQueries.session,
  click_to_claim_friend: clickToClaimFriendQueries.session,
  friend_journey: friendJourneyQueries.session,
  referrer_journey: referrerJourneyQueries.session,
  partner_journey: partnerJourneyQueries.session,
  user_lookup_journey: lookupUserJourneyQueries.session,
  redirect_friend_to_client_journey: redirectFriendToClientJourneyQueries.session,
  reward_redemption_journey: rewardRedemptionJourneyQueries.session,
};

export const flows = [
  "click_to_claim_referrer",
  "click_to_claim_friend",
  "friend_journey",
  "referrer_journey",
  "partner_journey",
  "user_lookup_journey",
  "redirect_friend_to_client_journey",
  "reward_redemption_journey",
];

export const configForFlow = (flow: string): DocumentNode => {
  const query = flowConfigMap[flow];

  if (!query) {
    throw new Error(`No config queries for flow: ${flow}`);
  }

  return query;
};

export const sessionForFlow = (flow: string): DocumentNode => {
  const query = flowSessionMap[flow];

  if (!query) {
    throw new Error(`No session queries for flow: ${flow}`);
  }

  return query;
};
