import React, { FC } from "react";
import { Legal } from "components/legal";

import { Context } from ".";
import render from "lib/Template";
import { NewLegalStyled } from "./Legal.styled";

const legal: FC<{ context: Context }> = ({ context }) => {
  if (!context.step.content.additionalFinePrint) {
    return null;
  }

  return (
    <Legal overrideLegalStyled={NewLegalStyled}>
      {render(context.step.content.additionalFinePrint, context.state.sessionContentReplacements)}
    </Legal>
  );
};

export default legal;
