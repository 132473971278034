import React, { FC, useContext, useState } from "react";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";

import { PartnerDashboardStep } from "types/graphql";

import DefaultLayout from "./layouts";
import { Lego } from "lib/Lego";
import { BrickTypes, Context } from "lib/Lego/PartnerJourney/PartnerDashboard";
import { useDevice } from "hooks/Device";

export type PartnerDashboardProps = {
  stepData: PartnerDashboardStep;
};

const PartnerDashboard: FC<PartnerDashboardProps> = ({ stepData }) => {
  const {
    appConfig: { isPreview },
    sessionConfig,
    sessionContentReplacements,
    localisation,
    urls,
  } = useContext<DefaultContext>(StepContext);

  const [showCopySuccessNotification, setShowCopySuccessNotification] = useState(false);
  const [showShareCodeCopySuccessNotification, setShareCodeCopySuccessNotification] =
    useState(false);

  const handleSuccessfulCopy = (): void => {
    setShowCopySuccessNotification(true);
    setTimeout(() => {
      setShowCopySuccessNotification(false);
    }, 2000);
  };

  const handleShareCodeCopy = (): void => {
    setShareCodeCopySuccessNotification(true);
    setTimeout(() => {
      setShareCodeCopySuccessNotification(false);
    }, 2000);
  };

  const context: Context = {
    step: stepData,
    session: sessionConfig,
    methods: {
      handleSuccessfulCopy,
      handleShareCodeCopy,
    },
    localisation: localisation,
    state: {
      device: useDevice(),
      sessionContentReplacements,
      showCopySuccessNotification,
      showShareCodeCopySuccessNotification,
      urls,
    },
  };

  return (
    <Lego<Context>
      brickTypes={BrickTypes}
      layout={DefaultLayout}
      context={context}
      isPreview={isPreview}
    />
  );
};

export default PartnerDashboard;
