import React, { createContext, useState, FC, PropsWithChildren } from "react";

import {
  DefaultContext,
  AppConfig,
  StepConfig,
  ActiveStep,
  ConfigStep,
  GoToStep,
  Session,
} from "../types/StepContext.types";

import { StepInterface, Localisation, Urls, Styles } from "types/graphql";
import { CallbackParams, UtmParameters } from "../types/embed-parameters.types";

type Props = {
  globalStyles: Styles;
  steps: StepInterface[];
  localisation: Localisation;
  session: Session;
  appConfig: AppConfig;
  urls: Urls;
  programType: string;
  utmParams?: UtmParameters;
  callbacks?: CallbackParams;
};

export const Context = createContext({} as DefaultContext);

const isolatedSessionSteps: string[] = ["genericError"];

const toSnakeCase = (e: string): string | undefined => {
  return e
    .match(/([A-Z])/g)
    ?.reduce((str: string, c: string) => str.replace(new RegExp(c), "_" + c.toLowerCase()), e)
    .substring(e.slice(0, 1).match(/([A-Z])/g) ? 1 : 0);
};

const buildContentReplacements = (session: Session, urls = {}): { [key: string]: string } => {
  const urlsWithSnakeCasedKeys = Object.fromEntries(
    Object.entries(urls).map(([k, v]) => [toSnakeCase(k), v]),
  );

  return { ...session.contentReplacements, ...urlsWithSnakeCasedKeys };
};

export const Provider: FC<PropsWithChildren<Props>> = ({
  globalStyles,
  steps,
  localisation,
  session,
  appConfig,
  urls,
  programType,
  utmParams,
  callbacks,
  children,
}) => {
  const [sessionConfig, setSessionConfig] = useState<StepConfig>({
    config: session.activeStep.config || null,
    validationErrors: (session.activeStep as any).validationErrors,
  });

  const [sessionContentReplacements, setSessionContentReplacements] = useState<{
    [key: string]: string;
  }>(buildContentReplacements(session, urls));

  const findStepInSessionOrConfig = (payloadType: string): ConfigStep | ActiveStep => {
    if (isolatedSessionSteps.includes(payloadType)) {
      return { type: payloadType };
    }

    const destinationStep = steps.find((step) => step.type === payloadType);

    if (typeof destinationStep === "undefined") {
      throw new Error(
        `Failed to find step '${payloadType}' in steps. Or step '${payloadType}' is not an isolated step.`,
      );
    }

    return destinationStep;
  };

  const [currentStep, setCurrentStep] = useState<ConfigStep | ActiveStep>(
    findStepInSessionOrConfig(session.activeStep.type),
  );

  const goToStep = (payload: GoToStep): void => {
    const destinationStep = findStepInSessionOrConfig(payload.type);

    if (payload.config) {
      setSessionConfig((sessionConfigState: typeof sessionConfig) => {
        return {
          config: { ...sessionConfigState.config, ...payload.config },
          validationErrors: payload.validationErrors,
        };
      });
    }

    if (payload.contentReplacements) {
      setSessionContentReplacements(
        (sessionContentReplacementState: typeof sessionContentReplacements) => {
          return { ...sessionContentReplacementState, ...payload.contentReplacements };
        },
      );
    }

    setCurrentStep(destinationStep);
  };

  const configContext = {
    globalStyles,
    currentStep,
    goToStep,
    sessionConfig,
    sessionContentReplacements,
    appConfig,
    localisation,
    urls,
    programType,
    utmParams,
    callbacks,
  };

  return <Context.Provider value={configContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
