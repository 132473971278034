import React, { FC } from "react";
import render from "lib/Template";

import {
  TitleStyled,
  PartnerContentStyled,
  PartnerBannerImageStyled,
  PartnerAboutSectionStyled,
  PartnerAboutSectionContentStyled,
  PartnerContentOfferStyled,
  PartnerAvatarStyled,
  PartnerBusinessNameStyled,
  PartnerContentParagraphStyled,
} from "./CaptureFriendDetails.styled";

import {
  CaptureFriendDetailsActiveStepConfigRewardDetail,
  CaptureFriendDetailsStep,
} from "types/graphql";
import MarkdownContent from "components/MarkdownContent";

const PartnerOffer: FC<{
  rewards: CaptureFriendDetailsActiveStepConfigRewardDetail[] | null;
  step: CaptureFriendDetailsStep;
  sessionContentReplacements: any;
}> = ({ rewards, step, sessionContentReplacements }) => {
  const { partnerDetails } = step.content;

  return (
    <PartnerContentStyled>
      <PartnerContentOfferStyled>
        {partnerDetails?.offerHeader && (
          <TitleStyled>
            {render(partnerDetails?.offerHeader, sessionContentReplacements)}
          </TitleStyled>
        )}
        <PartnerContentParagraphStyled>
          {render(step.content.body, sessionContentReplacements)}
        </PartnerContentParagraphStyled>
        {rewards && (
          <ul>
            {rewards?.map((reward) => (
              <li key={reward.title}>
                <strong>{reward.title}</strong>
              </li>
            ))}
          </ul>
        )}
      </PartnerContentOfferStyled>
      <PartnerAboutSectionStyled>
        <PartnerBannerImageStyled src={partnerDetails?.bannerUrl} />
        <PartnerAboutSectionContentStyled>
          <PartnerAvatarStyled>
            {partnerDetails?.avatarUrl && <img src={partnerDetails.avatarUrl} alt="" />}
            {partnerDetails?.displayName && (
              <PartnerBusinessNameStyled>{partnerDetails?.displayName}</PartnerBusinessNameStyled>
            )}
          </PartnerAvatarStyled>
          {partnerDetails?.quote && <MarkdownContent>{partnerDetails?.quote}</MarkdownContent>}
        </PartnerAboutSectionContentStyled>
      </PartnerAboutSectionStyled>
    </PartnerContentStyled>
  );
};

export default PartnerOffer;
