import { Layout, LayoutStyle, Modifier } from "lib/Lego";
import { Context } from "lib/Lego/ReferrerJourney/ReferrerDashboard";

const defaultLayout = {
  containerStyle: LayoutStyle.Regular,
  bricks: [
    {
      name: "Row",
      bricks: [
        {
          name: "Image",
          modifiers: {
            largeContainer: [Modifier.Hidden],
          },
        },
      ],
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [
            {
              name: "Image",
              modifiers: {
                smallContainer: [Modifier.Hidden],
              },
            },
            {
              name: "OfferSteps",
              modifiers: {
                smallContainer: [Modifier.Hidden],
              },
            },

            {
              name: "OfferDetails",
              modifiers: {
                smallContainer: [Modifier.Hidden],
              },
            },
          ],
        },
        {
          name: "Column",
          bricks: [
            { name: "LogoutLink" },
            { name: "Title" },
            {
              name: "CollapseOffer",
              modifiers: {
                largeContainer: [Modifier.Hidden],
                smallContainer: [Modifier.PaddingBottomSmall],
              },
              bricks: [{ name: "OfferSteps" }, { name: "OfferDetails" }],
            },
            {
              name: "ReferralStatusMobile",
              modifiers: {
                largeContainer: [Modifier.Hidden],
              },
            },
            {
              name: "RewardLimitMessage",
              // modifiers: {
              //   // largeContainer: [Modifier.Hidden],
              // },
            },
            { name: "ShareUrl" },
            { name: "ShareCode" },
            { name: "SharingTools" },
            {
              name: "ReferralStatusDesktop",
              modifiers: {
                smallContainer: [Modifier.Hidden],
              },
            },
          ],
        },
      ],
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [{ name: "Legal" }],
          modifiers: {
            smallContainer: [Modifier.PaddingTopMedium],
          },
        },
      ],
    },
  ],
};

const transitionLayout1 = {
  containerStyle: LayoutStyle.Regular,
  bricks: [
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [{ name: "Title", modifiers: [Modifier.TextAlignCenter] }],
        },
      ],
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Image",
          modifiers: {
            largeContainer: [Modifier.Hidden],
          },
        },
      ],
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [
            {
              name: "Image",
              modifiers: {
                smallContainer: [Modifier.Hidden],
              },
            },
            {
              name: "OfferSteps",
              modifiers: {
                smallContainer: [Modifier.Hidden],
              },
            },
          ],
        },
        {
          name: "Column",
          bricks: [
            {
              name: "CollapseOffer",
              modifiers: {
                largeContainer: [Modifier.Hidden],
                smallContainer: [Modifier.PaddingBottomMedium],
              },
              bricks: [{ name: "OfferSteps" }],
            },
            { name: "OfferDetails", modifiers: { smallContainer: [Modifier.Hidden] } },
            { name: "ShareUrl" },
            { name: "SharingTools" },
          ],
        },
      ],
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [{ name: "Legal" }],
          modifiers: {
            smallContainer: [Modifier.PaddingTopMedium],
          },
        },
      ],
    },
  ],
};

export default (layout: string): Layout<Context> => {
  switch (layout) {
    case "transition_1":
      return transitionLayout1;
    default:
      return defaultLayout;
  }
};
