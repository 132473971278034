import { css, DefaultTheme } from "styled-components";
import { transparentize } from "polished";
import styled from "styled-components";
import {
  resolveFontSize,
  resolveColor,
  setSvgCssFromAttributes,
  resolveWithDefault,
} from "styles/helpers";
import { LinkStyle } from "styles/global.styled";

type StyledButtonProps = {
  appearance?: "primary" | "secondary" | "link" | "inactive-tab" | "active-tab";
};

export const BaseStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 15px;
  border: 0;
  width: 100%;
  outline: 0;

  svg {
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const ButtonBaseStyle = css`
  text-decoration: none;
  font-family: ${({ theme }) => resolveWithDefault(theme.button.fontFamily, theme.font.family)};
  font-weight: ${({ theme }) => theme.button.fontWeight};
  font-size: ${({ theme }) => resolveFontSize(theme, theme.button.fontSize)};
  min-height: ${({ theme }) => theme.button.height};
  border-radius: ${({ theme }) => theme.button.borderRadius};

  ${({ theme }) => theme.button.css}
`;

export const primaryButtonColor = (theme: DefaultTheme) =>
  resolveWithDefault(
    resolveColor(theme, theme.button.primary.color),
    resolveColor(theme, theme.layout.backgroundColor),
  );

export const ButtonPrimaryStyle = css`
  ${ButtonBaseStyle}

  background-color: ${({ theme }) => resolveColor(theme, theme.button.primary.backgroundColor)};
  color: ${({ theme }) => primaryButtonColor(theme)};

  &:hover,
  &:focus {
    background-color: ${({ theme }) =>
      transparentize(0.3, resolveColor(theme, theme.button.primary.backgroundColor))};
  }

  &:active {
    background-color: ${({ theme }) =>
      transparentize(0.3, resolveColor(theme, theme.button.primary.backgroundColor))};
  }

  &:disabled {
    background-color: ${({ theme }) =>
      transparentize(0.4, resolveColor(theme, theme.button.primary.backgroundColor))};
    color: ${({ theme }) => transparentize(0.3, primaryButtonColor(theme))};
  }

  &:focus {
    box-shadow: 0 0 0 2px
      ${({ theme }) =>
        transparentize(0.3, resolveColor(theme, theme.button.primary.backgroundColor))}
      inset;
  }

  ${({ theme }) => theme.button.primary.css}
`;

const secondaryButtonBackgroundColor = (theme: DefaultTheme) =>
  resolveWithDefault(resolveColor(theme, theme.button.secondary.backgroundColor), "transparent");

export const ButtonSecondaryStyle = css`
  ${ButtonBaseStyle}
  background-color: ${({ theme }) => secondaryButtonBackgroundColor(theme)};
  color: ${({ theme }) => resolveColor(theme, theme.button.secondary.color)};
  box-shadow: 0 0 0 1px ${({ theme }) => resolveColor(theme, theme.button.secondary.color)} inset;

  &:hover,
  &:focus {
    background-color: ${({ theme }) =>
      transparentize(0.85, resolveColor(theme, theme.button.secondary.color))};
  }

  &:active {
    background-color: ${({ theme }) => transparentize(0.3, secondaryButtonBackgroundColor(theme))};
  }

  &:disabled {
    background-color: ${({ theme }) => transparentize(0.4, secondaryButtonBackgroundColor(theme))};
    color: ${({ theme }) => transparentize(0.3, resolveColor(theme, theme.button.secondary.color))};
  }

  &:focus {
    box-shadow: 0 0 0 2px
      ${({ theme }) => transparentize(0.3, secondaryButtonBackgroundColor(theme))} inset;
  }

  ${({ theme }) => theme.button.secondary.css}
`;

const ButtonTabBaseStyle = css`
  text-decoration: none;
  font-family: ${({ theme }) => theme.font.family};
  font-weight: ${({ theme }) => theme.tabSwitcher.fontWeight};
  font-size: ${({ theme }) => resolveFontSize(theme, theme.tabSwitcher.fontSize)};

  &:focus {
    box-shadow: 0 0 0 2px transparent inset;
  }

  svg {
    height: 18px;
  }

  ${({ theme }) => theme.tabSwitcher.tab.css}
`;

export const ButtonActiveTabStyle = css`
  ${ButtonTabBaseStyle}

  color: ${({ theme }) =>
    resolveWithDefault(
      resolveColor(theme, theme.tabSwitcher.tab._active.color),
      transparentize(0.2, resolveColor(theme, theme.layout.color)),
    )};
  background-color: transparent;
  border: 1px solid ${({ theme }) => transparentize(0.8, theme.layout.color)};
  border-radius: ${({ theme }) =>
    resolveWithDefault(theme.tabSwitcher.tab._active.borderRadius, theme.tabSwitcher.borderRadius)};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;

  ${({ theme }) => theme.tabSwitcher.tab._active.css}
`;

export const ButtonInactiveTabStyle = css`
  ${ButtonTabBaseStyle}

  color: ${({ theme }) =>
    resolveWithDefault(
      resolveColor(theme, theme.tabSwitcher.color),
      transparentize(0.2, resolveColor(theme, theme.layout.color)),
    )};
  background-color: transparent;

  border-bottom: 1px solid ${({ theme }) => transparentize(0.8, theme.layout.color)};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonLinkStyle = css`
  ${LinkStyle}

  width: auto;
  background-color: transparent;
`;

export const ButtonStyled = styled.button<StyledButtonProps>`
  ${BaseStyle}
  ${(props: StyledButtonProps) => props.appearance === "primary" && ButtonPrimaryStyle}
  ${(props: StyledButtonProps) => props.appearance === "secondary" && ButtonSecondaryStyle}
  ${(props: StyledButtonProps) => props.appearance === "link" && ButtonLinkStyle}
  ${(props: StyledButtonProps) => props.appearance === "inactive-tab" && ButtonInactiveTabStyle}
  ${(props: StyledButtonProps) => props.appearance === "active-tab" && ButtonActiveTabStyle}

  ${({ children }) => setSvgCssFromAttributes(children)}
`;

export const ButtonLoaderStyled = styled.span`
  display: flex;
  justify-content: center;

  &::before {
    animation: 1s linear infinite spinner;
    border: solid 2px rgba(255, 255, 255, 0.5);
    border-bottom-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    content: "";
    height: 15px;
    width: 15px;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
