import React, { FC } from "react";
import { transparentize } from "polished";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import parse from "html-react-parser";

import styled from "styled-components";

import { resolveFontSize, resolveColor, setSvgCssFromAttributes } from "styles/helpers";
import { ParagraphStyled } from "styles/global.styled";

const InfoMessageWrapper = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }): string => theme.textInput.borderRadius};
  background-color: ${({ theme }): string =>
    transparentize(0.9, resolveColor(theme, theme.layout.color))};
`;

const InfoMessageStyled = styled(ParagraphStyled)`
  font-size: ${({ theme }): string => resolveFontSize(theme, "extra_small")};
  font-weight: bold;
`;

const InfoMessageWrapperIconWrapper = styled.div`
  display: flex;
  margin-right: 8px;

  ${({ children }) => setSvgCssFromAttributes(children)}
  svg {
    fill: ${({ theme }): string => resolveColor(theme, theme.layout.color)};
    font-size: 28px;
  }
`;

const InfoMessage: FC<{ message: string }> = ({ message }) => (
  <InfoMessageWrapper>
    <InfoMessageWrapperIconWrapper>
      <AiOutlineExclamationCircle />
    </InfoMessageWrapperIconWrapper>
    <InfoMessageStyled>{parse(message)}</InfoMessageStyled>
  </InfoMessageWrapper>
);

export default InfoMessage;
