import React, { FC, PropsWithChildren } from "react";

import Input from "components/input";
import BaseForm from "components/form";
import Button from "components/button";

import { Context } from ".";
import DateEditor from "components/DateEditor";

import {
  EndParagraphStyled,
  FormStyled,
  ParagraphStyled,
  TitleStyled,
} from "../CaptureAdditionalFriendDetails/CaptureAdditionalFriendDetails.styled";
import parse from "html-react-parser";
import render, { renderString } from "lib/Template";
import {
  CustomFieldInputConfig,
  CustomFieldInputConfigInterface,
  CustomFieldListInputConfig,
} from "@/types/graphql";
import Dropdown, { DropdownOption } from "@/components/dropdown/Dropdown";

const Form: FC<PropsWithChildren<{ context: Context }>> = ({ context, children }) => {
  if (!context.step.optionalContent || !context.step.optionalConfig) {
    return null;
  }

  const inputConfig = context.step.optionalConfig.inputs;
  const inputContent = context.step.optionalContent.form.inputs;

  const firstOrNextVisitContent = context.state.firstVisit
    ? context.step.optionalContent?.firstVisit
    : context.step.optionalContent?.nextVisit;

  const customFields = inputConfig.customFields.map((cf) => cf as CustomFieldInputConfigInterface);

  return (
    <BaseForm
      handleOnSubmit={context.methods.handleFriendRegistration}
      overrideFormStyled={FormStyled}
    >
      <TitleStyled>
        {parse(
          renderString(
            firstOrNextVisitContent?.card.title || "",
            context.state.sessionContentReplacements,
          ),
        )}
      </TitleStyled>
      {!!firstOrNextVisitContent?.card?.body && (
        <ParagraphStyled>{parse(firstOrNextVisitContent.card.body)}</ParagraphStyled>
      )}
      {customFields
        .slice()
        .sort((a, b) => a.position - b.position)
        .map((field, i) => {
          const fieldContent = inputContent.customFields.find((f) => f.key === field.key);
          const content = fieldContent?.content;

          switch (field.inputFieldType) {
            case "list":
              const listField = field as CustomFieldListInputConfig;

              const options: DropdownOption[] =
                content?.items?.map((item, i) => {
                  return { value: listField?.items[i], label: item };
                }) || [];

              return (
                <Dropdown
                  key={`custom-field-${i}`}
                  label={content?.label || ""}
                  placeholder={content?.placeholder || ""}
                  options={options}
                  name={`customFields[${field.position}]`}
                  errorMessage={
                    (content?.validationErrors || []).find((f) => f.key === "default")?.value || ""
                  }
                />
              );
            case "date":
              return (
                <DateEditor
                  key={`custom-field-${i}`}
                  label={content?.label || ""}
                  errorMessage={
                    (content?.validationErrors || []).find((f: any) => f.key === "default")
                      ?.value || ""
                  }
                  required
                  name={`customFields[${field.position}]`}
                  tooltip={content?.tooltip || undefined}
                />
              );
            case "phone_number":
              const phoneNumberField = field as CustomFieldInputConfig;

              return (
                <Input
                  key={`custom-field-${i}`}
                  placeholder={content?.placeholder || ""}
                  label={content?.label || ""}
                  errorMessage={
                    (content?.validationErrors || []).find((f: any) => f.key === "default")
                      ?.value || ""
                  }
                  required
                  name={`customFields[${phoneNumberField.position}]`}
                  regex={phoneNumberField.pattern}
                  tooltip={content?.tooltip || undefined}
                  inputType="tel"
                />
              );
            case "numeric":
            case "text":
            default:
              const textField = field as CustomFieldInputConfig;

              return (
                <Input
                  key={`custom-field-${i}`}
                  placeholder={content?.placeholder || ""}
                  label={content?.label || ""}
                  errorMessage={
                    (content?.validationErrors || []).find((f: any) => f.key === "default")
                      ?.value || ""
                  }
                  required
                  name={`customFields[${textField.position}]`}
                  regex={textField.pattern}
                  tooltip={content?.tooltip || undefined}
                  numericKeypad={textField.inputFieldType === "numeric"}
                />
              );
          }
        })}
      <Button appearance="primary" buttonType="submit" isLoading={context.state.loading}>
        {context.step.optionalContent?.form.button.text}
      </Button>

      {!!firstOrNextVisitContent?.card?.finePrint && (
        <EndParagraphStyled>
          {render(firstOrNextVisitContent.card.finePrint, context.state.sessionContentReplacements)}
        </EndParagraphStyled>
      )}

      {children}
    </BaseForm>
  );
};

export default Form;
