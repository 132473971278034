import {
  MutationHookOptions,
  MutationTuple,
  useMutation as useRealMutation,
  DocumentNode,
} from "@apollo/client";
import { StepContext } from "context";
import { useContext } from "react";
import { DefaultContext } from "types/StepContext.types";
import { sharedQueries } from "preview-queries";

const useMutation = <TData, TVariables>(
  mutation: DocumentNode,
  options: MutationHookOptions<TData, TVariables> | undefined,
): MutationTuple<TData, TVariables> => {
  const { appConfig } = useContext<DefaultContext>(StepContext);
  const isPreview = appConfig.isPreview;

  if (isPreview) {
    mutation = sharedQueries.mutations.doNothing;
  }

  return useRealMutation<TData, TVariables>(mutation, options);
};

export default useMutation;
