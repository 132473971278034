import React, { FC, ReactNode } from "react";
import { LegalStyled, HrStyled, LegalTextStyled, FormLegalStyled } from "./Legal.styled";

type LegalProps = {
  children: ReactNode;

  overrideLegalStyled?: React.ElementType;
};

export const Legal: FC<LegalProps> = ({ children, overrideLegalStyled }) => {
  const LegalStyledToUse = overrideLegalStyled || LegalStyled;

  return (
    <LegalStyledToUse>
      <HrStyled />
      <LegalTextStyled>{children}</LegalTextStyled>
    </LegalStyledToUse>
  );
};

type FormLegalProps = {
  children: ReactNode;
};

export const FormLegal: FC<FormLegalProps> = ({ children }) => {
  return (
    <FormLegalStyled>
      <LegalTextStyled>{children}</LegalTextStyled>
    </FormLegalStyled>
  );
};
