import { BreakpointContext } from "context/BreakpointContext";
import { useContext } from "react";

const useResponsiveStyle = (breakpoint: string, value: string): string => {
  const activeBreakpoint = useContext(BreakpointContext);

  if (breakpoint === activeBreakpoint) {
    return value;
  } else {
    return "";
  }
};

export const useForSmallContainer = (value: string): string => useResponsiveStyle("small", value);
export const useForLargeContainer = (value: string): string => useResponsiveStyle("large", value);
