import React, { FC, PropsWithChildren } from "react";
import Collapse from "components/collapse";

import { Context, stepConfig, stepContent } from ".";
import { ContainerSize } from "hooks/Device";

const collapseOffer: FC<PropsWithChildren<Context>> = ({ context, children }) => {
  const content = stepContent(context);
  const config = stepConfig(context);

  if (!content) {
    return null;
  }

  return (
    <Collapse
      collapsedTitle={content.offerDetails.collapsedTitle}
      expandedTitle={content.offerDetails.expandedTitle}
      isOpen={
        (context.state.device.containerSize === ContainerSize.LargeContainer &&
          config?.offerDetails?.revealOnLoad) ||
        false
      }
    >
      {children}
    </Collapse>
  );
};

export default collapseOffer;
