import React, { PropsWithChildren } from "react";
import { ContainerSize } from "hooks/Device";

export enum Modifier {
  // Vertical Alignment of bricks within a Column,
  VerticalAlignMiddle,
  VerticalAlignTop,

  // Text Alignment inside bricks
  TextAlignCenter,

  // Optional rendering
  Hidden,

  // Padding around bricks
  PaddingHorizontalSmall,
  PaddingHorizontalMedium,
  PaddingHorizontalLarge,
  PaddingHorizontalExtraLarge,

  PaddingLeftSmall,
  PaddingLeftMedium,
  PaddingLeftLarge,
  PaddingLeftExtraLarge,

  PaddingRightSmall,
  PaddingRightMedium,
  PaddingRightLarge,
  PaddingRightExtraLarge,

  PaddingVerticalSmall,
  PaddingVerticalMedium,
  PaddingVerticalLarge,
  PaddingVerticalExtraLarge,

  PaddingTopSmall,
  PaddingTopMedium,
  PaddingTopLarge,
  PaddingTopExtraLarge,

  PaddingBottomSmall,
  PaddingBottomMedium,
  PaddingBottomLarge,
  PaddingBottomExtraLarge,

  SixtyFourtySplit,
  FourtySixtySplit,

  WidthSmall,

  V1,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ModifierType<TContext> = Modifier | (<TContext>(context: TContext) => Modifier[]);

export type Modifiers<TContext> =
  | ModifierType<TContext>[]
  | { smallContainer?: ModifierType<TContext>[]; largeContainer?: ModifierType<TContext>[] }
  | undefined;

function applyModifiers(modifiers: Modifier[], styles: any): boolean {
  if (modifiers.includes(Modifier.Hidden)) {
    return false;
  }

  if (modifiers.includes(Modifier.VerticalAlignMiddle)) {
    styles.display = "flex";
    styles.alignItems = "center";
  }

  if (modifiers.includes(Modifier.VerticalAlignTop)) {
    styles.display = "flex";
    styles.alignItems = "flex-start";
  }

  if (modifiers.includes(Modifier.TextAlignCenter)) {
    styles.textAlign = "center";
  }

  if (
    modifiers.includes(Modifier.PaddingLeftSmall) ||
    modifiers.includes(Modifier.PaddingHorizontalSmall)
  )
    styles.paddingLeft = "1rem";
  if (
    modifiers.includes(Modifier.PaddingLeftMedium) ||
    modifiers.includes(Modifier.PaddingHorizontalMedium)
  )
    styles.paddingLeft = "2rem";
  if (
    modifiers.includes(Modifier.PaddingLeftLarge) ||
    modifiers.includes(Modifier.PaddingHorizontalLarge)
  )
    styles.paddingLeft = "3rem";
  if (
    modifiers.includes(Modifier.PaddingLeftExtraLarge) ||
    modifiers.includes(Modifier.PaddingHorizontalExtraLarge)
  )
    styles.paddingLeft = "5rem";

  if (
    modifiers.includes(Modifier.PaddingRightSmall) ||
    modifiers.includes(Modifier.PaddingHorizontalSmall)
  )
    styles.paddingRight = "1rem";
  if (
    modifiers.includes(Modifier.PaddingRightMedium) ||
    modifiers.includes(Modifier.PaddingHorizontalMedium)
  )
    styles.paddingRight = "2rem";
  if (
    modifiers.includes(Modifier.PaddingRightLarge) ||
    modifiers.includes(Modifier.PaddingHorizontalLarge)
  )
    styles.paddingRight = "3rem";
  if (
    modifiers.includes(Modifier.PaddingRightExtraLarge) ||
    modifiers.includes(Modifier.PaddingHorizontalExtraLarge)
  )
    styles.paddingRight = "5rem";

  if (
    modifiers.includes(Modifier.PaddingTopSmall) ||
    modifiers.includes(Modifier.PaddingVerticalSmall)
  )
    styles.paddingTop = "1rem";
  if (
    modifiers.includes(Modifier.PaddingTopMedium) ||
    modifiers.includes(Modifier.PaddingVerticalMedium)
  )
    styles.paddingTop = "2rem";
  if (
    modifiers.includes(Modifier.PaddingTopLarge) ||
    modifiers.includes(Modifier.PaddingVerticalLarge)
  )
    styles.paddingTop = "3rem";
  if (
    modifiers.includes(Modifier.PaddingTopExtraLarge) ||
    modifiers.includes(Modifier.PaddingVerticalExtraLarge)
  )
    styles.paddingTop = "5rem";

  if (
    modifiers.includes(Modifier.PaddingBottomSmall) ||
    modifiers.includes(Modifier.PaddingVerticalSmall)
  )
    styles.paddingBottom = "1rem";
  if (
    modifiers.includes(Modifier.PaddingBottomMedium) ||
    modifiers.includes(Modifier.PaddingVerticalMedium)
  )
    styles.paddingBottom = "2rem";
  if (
    modifiers.includes(Modifier.PaddingBottomLarge) ||
    modifiers.includes(Modifier.PaddingVerticalLarge)
  )
    styles.paddingBottom = "3rem";
  if (
    modifiers.includes(Modifier.PaddingBottomExtraLarge) ||
    modifiers.includes(Modifier.PaddingVerticalExtraLarge)
  )
    styles.paddingBottom = "5rem";

  if (modifiers.includes(Modifier.WidthSmall)) styles.maxWidth = "370px";

  return true;
}

export const getModifiersToRender = <TContext,>(
  modifiers: Modifiers<TContext>,
  containerSize: ContainerSize,
  context: TContext
): Modifier[] => {
  if (Array.isArray(modifiers)) {
    return modifiers.flatMap((m) => (typeof m === "function" ? m(context) : m));
  } else if (modifiers?.smallContainer && containerSize === ContainerSize.SmallContainer) {
    return modifiers.smallContainer.flatMap((m) => (typeof m === "function" ? m(context) : m));
  } else if (modifiers?.largeContainer && containerSize === ContainerSize.LargeContainer) {
    return modifiers.largeContainer.flatMap((m) => (typeof m === "function" ? m(context) : m));
  }

  return [];
};

type ModifierWrapperProps<TContext> = {
  context: TContext;
  modifiers: Modifiers<TContext>;
  containerSize: ContainerSize;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ModifierWrapper: // noinspection BadExpressionStatementJS
<TContext>({
  context,
  modifiers,
  containerSize,
  children,
}: PropsWithChildren<ModifierWrapperProps<TContext>>) => React.ReactElement<
  PropsWithChildren<TContext>
> = ({ context, modifiers, containerSize, children }) => {
  const styles: any = {};

  let shouldRender = true;

  if (modifiers) {
    shouldRender = applyModifiers(getModifiersToRender(modifiers, containerSize, context), styles);
  }

  if (!shouldRender) {
    return null;
  }

  if (Object.keys(styles).length > 0) {
    return <div style={styles}>{children}</div>;
  } else {
    return children;
  }
};

export default ModifierWrapper;
