import React, { FC } from "react";
import styled from "styled-components";
import { TitleStyled as DefaultTitleStyled } from "styles/global.styled";

import { Context, stepContent } from ".";
import { Modifier } from "lib/Lego/Modifiers";
import MarkdownContent from "components/MarkdownContent";
import parse from "html-react-parser";

const TitleStyled = styled(DefaultTitleStyled)<{ modifiers: Modifier[] }>`
  ${({ modifiers }) => modifiers.find((f) => f === Modifier.V1) && "text-align: center;"}

  margin: 0;
`;

const Title: FC<Context & { modifiers: Modifier[] }> = ({ context, modifiers }) => {
  const content = stepContent(context);

  const isV1 = !!modifiers.find((f) => f === Modifier.V1);

  if (!content) {
    return null;
  }

  return isV1 ? (
    <MarkdownContent>{content.title}</MarkdownContent>
  ) : (
    <TitleStyled modifiers={modifiers}>{parse(content.title)}</TitleStyled>
  );
};

export default Title;
