import { StepConfig } from "types/StepContext.types";
import { CaptureAdditionalFriendDetailsStep } from "types/graphql";
import { Device } from "hooks/Device";
import Legal from "./Legal";
import Form from "./Form";
import SubmissionError from "../Shared/SubmissionError";
import SecondVisitSection from "./SecondVisitSection";
import FirstVisitSection from "./FirstVisitSection";

export type FormData = {
  customFieldValue: string;
  customFields: { [id: string]: string };
};

export type Context = {
  step: CaptureAdditionalFriendDetailsStep;
  session?: StepConfig;
  methods: {
    handleFriendRegistration: (formData: FormData) => void;
    handleCallToActionClick: (url: string, actionId?: string) => void;
  };
  state: {
    device: Device;
    sessionContentReplacements: any;
    registrationError: boolean;
    firstVisit: boolean;
    loading: boolean;
  };
  optionalStep: boolean;
};

export const BrickTypes: { [index: string]: React.FC<{ context: Context }> } = {
  Legal: Legal as React.FC<{ context: Context }>,
  Form: Form,
  SubmissionError: SubmissionError,
  SecondVisitSection,
  FirstVisitSection,
};
