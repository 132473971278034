import styled from "styled-components";
import { useForSmallContainer } from "hooks/ResponsiveStyle";

import { transparentize } from "polished";
import { SvgWrapperStyled } from "components/svg/Svg.styled";
import { resolveColor, resolveWithDefault } from "styles/helpers";

export const TitleStyled = styled.div`
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const RewardLimitMessageStyled = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 0.8rem;
  padding: 1.5rem;
  font-size: 0.88rem;

  ${TitleStyled} {
    display: flex;
    align-items: center;

    max-width: 1020px;
    ${() => useForSmallContainer(`width: 326px;`)}
    > ${SvgWrapperStyled} {
      flex: 0 0 1.2rem;

      width: 1.2rem;
      height: 1.2rem;

      margin-right: 1rem;
    }
  }

  background-color: ${({ theme }) =>
    resolveColor(
      theme,
      resolveWithDefault(
        theme.errorBanner?.backgroundColor,
        transparentize(0.92, theme.colors.error),
      ),
    )};
  color: ${({ theme }) =>
    resolveColor(theme, resolveWithDefault(theme.errorBanner?.color, theme.colors.error))};
`;

export const TermsAndConditionsUrlStyled = styled.div`
  margin-top: 1rem;
`;
