import React, { FC, useContext, useEffect } from "react";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";
import { EventEnum, SelectFriendRewardOptionStep } from "types/graphql";

import useRecordEvent from "hooks/RecordEvent";
import SelectRewardOption from "templates/click_to_claim/SelectRewardOption";
import { renderString } from "lib/Template";

export type SelectFriendRewardOptionProps = {
  stepData: SelectFriendRewardOptionStep;
};

const SelectFriendRewardOption: FC<SelectFriendRewardOptionProps> = ({ stepData }) => {
  const { goToStep, currentStep, sessionContentReplacements, callbacks } =
    useContext<DefaultContext>(StepContext);
  const recordEvent = useRecordEvent();

  const handleIntroLinkClick = (): void => {
    recordEvent(EventEnum.ClickToClaimFriendLinkClicks, {
      step: currentStep.type,
      target: "intro_link",
    });
    window.open(renderString(stepData.content.introLink.url, sessionContentReplacements));
  };

  const handleRewardOptionSelection = (optionKey: string, selectedRewardTitle: string): void => {
    goToStep({
      type: "confirmFriendRewardOption",
      config: { optionKey },
      contentReplacements: {
        "selected_reward.title": selectedRewardTitle,
      },
    });
  };

  useEffect(() => {
    recordEvent(EventEnum.VisitClickToClaimFriendPage);
  }, [recordEvent]);

  if (callbacks && callbacks.onLayoutLoaded) callbacks.onLayoutLoaded();

  return (
    <SelectRewardOption
      stepData={stepData}
      handleRewardOptionSelection={handleRewardOptionSelection}
      handleIntroLinkClick={handleIntroLinkClick}
      userType={"friend"}
    />
  );
};

export default SelectFriendRewardOption;
