import React, { FC, useContext, useState, useEffect } from "react";
import useMutation from "hooks/UseMutation";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";

import {
  LoginViaSeamlessPayload,
  LoginViaSeamlessInput,
  ReferrerDashboardActiveStep,
  SplashPageStep,
  EventEnum,
  ValidationError,
} from "types/graphql";

import { referrerJourneyQueries } from "queries";

import useRecordEvent from "hooks/RecordEvent";

import getLayout from "./layouts";
import { Lego } from "lib/Lego";
import { FormData, Context, BrickTypes } from "lib/Lego/ReferrerJourney/SplashPage";
import { FormProvider, useForm } from "react-hook-form";
import { setErrorsForField } from "templates/helpers/SetErrorsForField";
import { useDevice } from "hooks/Device";

export type SplashPageProps = {
  stepData: SplashPageStep;
};

const SplashPage: FC<SplashPageProps> = ({ stepData }) => {
  const { appConfig, goToStep, sessionConfig, sessionContentReplacements } =
    useContext<DefaultContext>(StepContext);

  const [registrationError, setRegistrationError] = useState<boolean>(false);
  const recordEvent = useRecordEvent();

  const methods = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  const { setError } = methods;

  const flowId = appConfig.flow.id;
  const id = appConfig.id;
  const locale = appConfig.locale;
  const seamlessParams = appConfig.flow.params?.seamless;

  const [submitLoginViaSeamless, { loading }] = useMutation<
    { loginViaSeamless: LoginViaSeamlessPayload },
    LoginViaSeamlessInput
  >(referrerJourneyQueries.mutations.loginViaSeamless, {
    onCompleted: (data: { loginViaSeamless: LoginViaSeamlessPayload }): void => {
      if (data.loginViaSeamless.ok) {
        const nextSession = data.loginViaSeamless.session;
        const nextActiveStep = data.loginViaSeamless.session
          .activeStep as ReferrerDashboardActiveStep;

        goToStep({
          type: nextActiveStep.type,
          config: nextActiveStep.config,
          contentReplacements: nextSession.contentReplacements,
        });
      } else {
        const nextSession = data.loginViaSeamless.session;

        setValidationErrors((nextSession.activeStep as any).validationErrors || []);

        setRegistrationError(true);
      }
    },
    onError: () => setRegistrationError(true),
  });

  const setValidationErrors = (validationErrors: ValidationError[]) => {
    validationErrors.forEach((f: ValidationError) => {
      setErrorsForField(setError, f.field, f.key, stepData.optionalContent?.form.inputs);
    });
  };

  useEffect(() => {
    setValidationErrors(sessionConfig?.validationErrors || []);
  }, [sessionConfig]);

  const handleReferrerRegistration = (formData: FormData): void => {
    if (seamlessParams) {
      const marketingOptIn = stepData.optionalConfig?.inputs.marketingOptIn.show
        ? formData.marketingOptIn
        : seamlessParams.marketing_opt_in;

      const variables: LoginViaSeamlessInput = {
        flowId,
        id,
        locale,
        fullName: seamlessParams.full_name,
        email: seamlessParams.email,
        customFieldValue: seamlessParams.custom_field_value,
        customFields: seamlessParams.custom_fields,
        signature: seamlessParams.signature,
        signedKeys: seamlessParams.signed_keys,
        marketingOptIn: marketingOptIn,
        termsOptIn: formData.termsOptIn,
        splashPageSubmit: true,
      };

      if (seamlessParams.client_data) {
        try {
          if (typeof seamlessParams.client_data === "string") {
            variables.clientData = JSON.parse(seamlessParams.client_data);
          } else {
            variables.clientData = seamlessParams.client_data;
          }
        } catch (e) {
          console.error("BP Error: invalid client_data format, ", e);
        }
      }

      submitLoginViaSeamless({ variables });
    }
  };

  const context: Context = {
    step: stepData,
    session: sessionConfig,
    methods: {
      handleReferrerRegistration,
    },
    state: {
      registrationError,
      loading,
      sessionContentReplacements,
      device: useDevice(),
    },
    optionalStep: true,
  };

  useEffect(() => {
    recordEvent(EventEnum.VisitCampaign);
    recordEvent(EventEnum.VisitSplashPage);
  }, [recordEvent]);

  return (
    <FormProvider {...methods}>
      <Lego<Context>
        brickTypes={BrickTypes}
        layout={getLayout(stepData.optionalConfig?.layout || "default")}
        context={context}
        isPreview={appConfig.isPreview}
      />
    </FormProvider>
  );
};

export default SplashPage;
