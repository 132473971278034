import styled from "styled-components";
import { transparentize } from "polished";
import { resolveColor, resolveFontSize } from "styles/helpers";

export const WrapperStyled = styled.div`
  min-width: 240px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid ${({ theme }): string => transparentize(0.8, theme.layout.color)};
`;

export const ItemHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin: 10px;
  font-size: ${({ theme }): string => resolveFontSize(theme, "medium")};
`;

export const ItemBodyWrapper = styled.div`
  background-color: ${({ theme }) => transparentize(0.95, resolveColor(theme, theme.layout.color))};
  padding: 10px;
`;

export const Title = styled.div`
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 0px;
  color: grey;
  font-size: ${({ theme }): string => resolveFontSize(theme, "extra_small")};
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Date = styled.div`
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-top: 3px;
  font-size: ${({ theme }): string => resolveFontSize(theme, "small")};
  color: ${({ theme }): string => resolveColor(theme, theme.layout.color)};
`;
