import { Layout, LayoutStyle } from "lib/Lego";
import { Context } from "lib/Lego/UserLookup/LookupUser";

const defaultLayout: Layout<Context> = {
  containerStyle: LayoutStyle.Column,
  bricks: [
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [
            {
              name: "Form",
              bricks: [
                {
                  name: "SubmissionError",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default defaultLayout;
