import React, { FC, PropsWithChildren } from "react";
import { Context } from "./index";
import render, { renderString } from "lib/Template";
import { ShareUrlStyled, PartnerSharingToolsTitle } from "./SharingTools.styled";
import NativeSharingTools from "components/native-sharing-tools";

const PartnerNativeSharingTools: FC<PropsWithChildren<{ context: Context }>> = ({ context }) => {
  const sessionConfig = context.session;
  const { sessionContentReplacements } = context.state;
  const { sharingTools } = context.step.content;
  const settings = context.step.config.sharingTools;
  const { directLink, shareCode } = sharingTools;

  return (
    <>
      {settings.displayDirectLink && (
        <>
          <ShareUrlStyled>{sessionConfig.config?.sharingUrls?.directLink || ""}</ShareUrlStyled>

          <NativeSharingTools
            nativeSharingButtonText={directLink.nativeSharing.buttonText}
            nativeSharingMessage={directLink.shareMessage}
            nativeSharingTitle={directLink.nativeSharing.title}
            nativeSharingShortUrl={sessionConfig.config?.sharingUrls?.directLink || ""}
            manualSharingCopyText={sessionConfig.config?.sharingUrls?.directLink || ""}
            manualSharingCopyButtonText={directLink.copyButtonText}
          />
        </>
      )}

      {settings.displayShareCode && (
        <>
          <PartnerSharingToolsTitle>
            {render(shareCode.label, sessionContentReplacements)}
          </PartnerSharingToolsTitle>

          <ShareUrlStyled>
            {renderString(shareCode.shareMessage, sessionContentReplacements)}
          </ShareUrlStyled>

          <NativeSharingTools
            nativeSharingButtonText={shareCode.nativeSharing.buttonText}
            nativeSharingMessage={renderString(shareCode.shareMessage, sessionContentReplacements)}
            nativeSharingTitle={shareCode.nativeSharing.title}
            manualSharingCopyText={renderString(shareCode.shareMessage, sessionContentReplacements)}
            manualSharingCopyButtonText={shareCode.copyButtonText}
          />
        </>
      )}
    </>
  );
};

export default PartnerNativeSharingTools;
