import React, { FC, useContext } from "react";
import { ReferralLimitReachedStep, EventEnum } from "types/graphql";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";
import useRecordEvent from "hooks/RecordEvent";
import { Context } from "lib/Lego/FriendJourney/ReferralLimitReached";
import { Lego } from "lib/Lego";
import { BrickTypes } from "lib/Lego/FriendJourney/ReferralLimitReached";
import getLayout from "./layouts";

export type ReferralLimitReachedProps = {
  stepData: ReferralLimitReachedStep;
};

const ReferralLimitReached: FC<ReferralLimitReachedProps> = ({ stepData }) => {
  const { sessionContentReplacements, sessionConfig, appConfig } =
    useContext<DefaultContext>(StepContext);
  const recordEvent = useRecordEvent();

  const handleContinueToClientSite = (): void => {
    recordEvent(EventEnum.ContinueToClientSite);
    window.open(sessionConfig.config?.destinationUrl);
  };

  const context: Context = {
    step: stepData,
    session: sessionConfig,
    methods: {
      handleContinueToClientSite,
    },
    state: {
      sessionContentReplacements,
    },
    optionalStep: true,
  };

  return (
    <Lego<Context>
      brickTypes={BrickTypes}
      layout={getLayout("default")}
      context={context}
      isPreview={appConfig.isPreview}
    />
  );
};

export default ReferralLimitReached;
