import { Localisation, LookupUserStep } from "types/graphql";
import { StepConfig } from "types/StepContext.types";
import { Device } from "hooks/Device";
import Form from "./Form";
import SubmissionError from "./SubmissionError";

export type FormData = {
  code: string;
};

export type Context = {
  step: LookupUserStep;
  session: StepConfig;
  methods: {
    handleLookupUser: (formData: FormData) => void;
  };
  localisation: Localisation;
  state: {
    device: Device;
    sessionContentReplacements: { [key: string]: string };
    loading: boolean;
    submissionError: boolean;
    showInvalidCodeMessage: boolean;
  };
};

export const BrickTypes: { [index: string]: React.FC<{ context: Context }> } = {
  Form,
  SubmissionError,
};
