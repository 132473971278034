import { EmbedParameters } from "types/embed-parameters.types";
import {
  LoginViaIdentifierInput,
  LoginViaIdentifierMutation,
  SessionInterface,
} from "types/graphql";
import { friendJourneyQueries } from "queries";

import { ApolloClient } from "@apollo/client";

export default (
  apolloClient: ApolloClient<object>,
  params: EmbedParameters,
  sessionId: string
): Promise<SessionInterface> | null => {
  const identifier = params.flow.params?.euid;

  if (!identifier) {
    return null;
  }

  return new Promise<SessionInterface>((resolve, reject) => {
    apolloClient
      .mutate<LoginViaIdentifierMutation, LoginViaIdentifierInput>({
        mutation: friendJourneyQueries.mutations.loginViaIdentifier,
        variables: {
          flowId: params.flow.id,
          id: sessionId,
          identifier,
        },
      })
      .then((result) => {
        const session = result.data?.loginViaIdentifier?.session;

        if (session) {
          resolve(session);
        } else {
          reject(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
