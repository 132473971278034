import { StepContext } from "context/index";
import React, { FC, useContext } from "react";
import strftime from "strftime";
import { Localisation } from "types/graphql";
import { DefaultContext } from "types/StepContext.types";
import { DateTime } from "luxon";

type Timestamp = string | number;

type Props = {
  timestamp: Timestamp;
  format?: Format;
};

export enum Format {
  Short = "shortFormat",
  Long = "longFormat",
  MonthOnly = "monthOnlyFormat",
}

export const getDateFormatter = (localisation: Localisation) => {
  const localtimeformat = {
    days: localisation.date.dayNames,
    shortDays: localisation.date.dayNames,
    months: localisation.date.monthNames,
    shortMonths: localisation.date.abbreviatedMonthNames,

    formats: {
      D: localisation.date.shortFormat,
      F: localisation.date.longFormat,
    },
  };

  return strftime.localize(localtimeformat);
};

export const formatDate = (timestamp: Timestamp, format = Format.Long) => {
  const { localisation } = useContext<DefaultContext>(StepContext);
  const localisedStrftime = getDateFormatter(localisation);

  // Use luxon to
  // properly read timezone information from ISO string
  // or default to parsing as if in the browser's timezone
  let date;
  if (typeof timestamp === "number") {
    date = DateTime.fromSeconds(timestamp);
  } else {
    date = DateTime.fromISO(timestamp);
  }

  return localisedStrftime(localisation.date[format], date.toJSDate());
};

const FormattedDate: FC<Props> = ({ timestamp, format = Format.Long }) => {
  return <>{formatDate(timestamp, format)} </>;
};

export default FormattedDate;
