import parse, { domToReact } from "html-react-parser";

const escapeRegExp = (string: string): string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

export const renderString = (template: string, items: {}): string => {
  const variables: RegExpMatchArray | null = template?.match(/%{[.\w]+\}/g);
  const linkVariables: RegExpMatchArray | null = template?.match(/%\[[^\n\r}]+]{[.\w]+_link}/g);

  if (!variables && !linkVariables) {
    return template;
  }

  if (variables) {
    variables.forEach((variableItem) => {
      const regex = new RegExp(variableItem, "g");
      const item = variableItem.split(/{|}/g)[1];
      const toReplace = items[item as keyof typeof items];

      template = template.replace(regex, toReplace ? toReplace : variableItem);
    });
  }

  if (linkVariables) {
    linkVariables.forEach((linkVariableItem) => {
      const regex = new RegExp(escapeRegExp(linkVariableItem), "g");
      const [, text, item] = linkVariableItem.split(/\[|\]{|}/g);
      const href = items[item.replace("_link", "_url") as keyof typeof items];

      let toReplace;

      if (!!href && !!text) {
        toReplace = `<a href="${href}" target="_blank">${text}</a>`;
      }

      template = template.replace(regex, toReplace ? toReplace : linkVariableItem);
    });
  }

  return template;
};

const render = (template: string, items: {}): ReturnType<typeof domToReact> =>
  parse(renderString(template, items));

export default render;
