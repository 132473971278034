import React, { FC } from "react";
import { Context } from ".";
import {
  InstructionsStyled,
  UpperSectionWrapperStyled,
} from "./CaptureAdditionalFriendDetails.styled";
import parse from "html-react-parser";
import { renderString } from "lib/Template";

import { TileButtonContent } from "types/graphql";
import { ContainerSize } from "hooks/Device";

import { ImageTiles } from "components/image-tiles/ImageTiles";
import { CallToActionButtonList } from "components/call-to-action-button-list/CallToActionButtonList";

const FirstVisitSection: FC<{ context: Context }> = ({ context }) => {
  if (!context.step.optionalContent || !context.state.firstVisit) return null;

  const content = context.step.optionalContent;

  const smallContainer = context.state.device.containerSize === ContainerSize.SmallContainer;

  const buttonAsImageTiles =
    context.step.optionalContent.buttons?.filter((f) => !!(f as TileButtonContent).image)?.length >
    0;

  const imageTileButtons = context.step.optionalContent.buttons.map(
    (button) => button as TileButtonContent
  );

  const buttons = buttonAsImageTiles ? (
    <ImageTiles
      buttons={imageTileButtons}
      appearance={context.state.firstVisit ? "primary" : "secondary"}
      handleCallToActionClick={context.methods.handleCallToActionClick}
      smallContainer={smallContainer}
      carouselStyle={{
        width: "375px",
        marginLeft: "-1rem",
        marginRight: "-1rem",
      }}
    />
  ) : (
    <CallToActionButtonList
      buttons={context.step.optionalContent.buttons}
      appearance={context.state.firstVisit ? "primary" : "secondary"}
      handleCallToActionClick={context.methods.handleCallToActionClick}
    />
  );

  return (
    <UpperSectionWrapperStyled>
      <InstructionsStyled>
        {parse(
          renderString(content.firstVisit.instructions, context.state.sessionContentReplacements)
        )}
      </InstructionsStyled>
      {buttons}
    </UpperSectionWrapperStyled>
  );
};

export default FirstVisitSection;
