import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { PerformanceTableStyled, PerformanceTableContainerStyled } from "./ResponsiveTable.styled";
import { ContainerSize, useDevice } from "hooks/Device";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const ResponsiveTable: FC<
  PropsWithChildren<{
    currentColumn?: number;
    onScroll?: (columnIndex: number) => void;
    firstColumnWidthPercent?: number;
    otherColumnWidthPercent?: number;
  }>
> = ({
  children,
  currentColumn,
  onScroll,
  firstColumnWidthPercent = 45,
  otherColumnWidthPercent = 55,
}) => {
  const device = useDevice();
  const tableScrollRef = useRef() as React.MutableRefObject<HTMLTableElement>;
  const tableScrollContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const smallContainer = device.containerSize === ContainerSize.SmallContainer;

  const [columnCount, setColumnCount] = useState(0);
  const [columnWidth, setColumnWidth] = useState(0);
  const [overlayChildren, setOverlayChildren] = useState<any[]>([]);
  const [firstColumnWidth, setFirstColumnWidth] = useState(0);
  const [selectedColumn, setSelectedColumn] = useState(0);

  const moveToPage = useCallback(
    (pageIndex: number): void => {
      tableScrollRef.current.querySelectorAll("thead tr th")[pageIndex]?.scrollIntoView({
        behavior: "smooth",
        inline: "end",
      });
    },
    [tableScrollRef]
  );

  useLayoutEffect(() => {
    const overlayProps = {
      style: {
        display: "none",
      },
      "aria-hidden": true,
    };

    const countTableColumns = (children: React.ReactNode): number => {
      const entries = React.Children.toArray(children);

      if (entries.every((e) => React.isValidElement(e) && e.type === "th")) {
        return entries.length;
      }

      for (let i = 0; i < entries.length; i++) {
        if (React.isValidElement(entries[i])) {
          const count = countTableColumns((entries[i] as React.ReactElement).props.children);

          if (count > 0) return count;
        }
      }

      return 0;
    };

    const cloneTableChildren = (children: React.ReactNode): React.ReactNode | null => {
      return React.Children.map(children, (element, index) => {
        if (React.isValidElement(element)) {
          let mapped: React.ReactNode;
          if (React.Children.count(element.props.children) > 0) {
            mapped = cloneTableChildren(element.props.children);
          }

          // clone the table contents, hiding all but the first column if building an overlay
          return React.cloneElement(element, {
            ...((element.type === "td" || element.type === "th") && index > 0 ? overlayProps : {}),
            ...(React.Children.count(mapped) > 0 ? { children: mapped } : {}),
          } as any);
        }

        return element;
      });
    };

    setOverlayChildren(React.Children.toArray(cloneTableChildren(children)));
    setColumnCount(countTableColumns(children));
  }, [children, moveToPage]);

  const observer: any = React.useRef(
    new ResizeObserver((entries) => {
      // Only care about the first element, we expect one element ot be watched
      const { width } = entries[0].contentRect;

      setColumnWidth(width);
    })
  );

  useLayoutEffect(() => {
    const observerRef = observer.current;

    if (tableScrollRef.current) {
      const thead = tableScrollRef.current.querySelector("thead");
      const tr = thead?.children[0];
      const secondColumn = tr?.children[1];

      if (tr) {
        const firstColumn = tr.children[0].getBoundingClientRect();
        setFirstColumnWidth(firstColumn.width);

        observerRef.observe(secondColumn);
      }
    }

    return (): void => {
      if (observerRef) observerRef.disconnect();
    };
  }, [tableScrollRef, observer]);

  useScrollPosition(
    ({ currPos }) => {
      if (smallContainer) {
        // offset the first column from the scroll to column calculation
        const newColumn = Math.max(0, Math.round((currPos.x + firstColumnWidth) / columnWidth) - 1);
        onScroll && onScroll(newColumn);
      }
    },
    [columnWidth, onScroll],
    tableScrollRef,
    false,
    500,
    tableScrollContainerRef
  );

  useLayoutEffect(() => {
    if (currentColumn !== undefined && currentColumn !== selectedColumn) {
      setSelectedColumn(currentColumn);
      moveToPage(currentColumn);
    }
  }, [currentColumn, moveToPage, selectedColumn, setSelectedColumn]);

  return (
    <PerformanceTableContainerStyled>
      <div style={{ width: "100%", overflow: "auto" }} ref={tableScrollContainerRef}>
        <PerformanceTableStyled
          columnCount={columnCount}
          ref={tableScrollRef}
          firstColumnWidthPercent={firstColumnWidthPercent}
          otherColumnWidthPercent={otherColumnWidthPercent}
        >
          {children}
        </PerformanceTableStyled>
      </div>
      {smallContainer ? (
        <PerformanceTableStyled
          columnCount={columnCount}
          overlay={true}
          aria-hidden={true}
          firstColumnWidthPercent={firstColumnWidthPercent}
          otherColumnWidthPercent={otherColumnWidthPercent}
        >
          {overlayChildren}
        </PerformanceTableStyled>
      ) : null}
    </PerformanceTableContainerStyled>
  );
};

export default ResponsiveTable;
