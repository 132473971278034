import { resolveFontSize } from "styles/helpers";
import styled from "styled-components";

export const TableCell = styled.td`
  font-weight: 400;
  font-size: ${({ theme }): string => resolveFontSize(theme, "small")};
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
  padding-right: 0;
  padding-left: 0.4rem;
  max-width: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;

  &:last-child {
    text-align: right;
    padding-right: 0.4rem;
  }
`;
