import React, { FC, useContext, useState } from "react";
import parse from "html-react-parser";
import useMutation from "hooks/UseMutation";

import { StepContext } from "../../../context";
import { DefaultContext } from "types/StepContext.types";
import { clickToClaimSharedQueries } from "queries";
import {
  ClaimRewardPayload,
  ClaimRewardInput,
  RewardEmailedActiveStep,
  SessionInterface,
  ConfirmFriendRewardOptionStep,
  ClickToClaimOptionConfirmationStep,
} from "types/graphql";

import Button from "components/button";
import VisuallyHidden from "components/visually-hidden";

import {
  WrapperStyled,
  SubmissionErrorStyled,
  IntroStyled,
  ContentWrapperStyled,
} from "./ConfirmRewardOption.styled";

import renderTemplate from "lib/Template";

export type ConfirmRewardOptionProps = {
  stepData: ConfirmFriendRewardOptionStep | ClickToClaimOptionConfirmationStep;
  handleRewardRejection: () => void;
  handleFooterLinkClick: () => void;
  userType: string;
};

const ConfirmRewardOption: FC<ConfirmRewardOptionProps> = ({
  stepData,
  handleRewardRejection,
  handleFooterLinkClick,
  userType,
}) => {
  const { goToStep, sessionContentReplacements, sessionConfig, appConfig, callbacks } =
    useContext<DefaultContext>(StepContext);

  const [error, setError] = useState<boolean>(false);
  const flowId = appConfig.flow.id;
  const id = appConfig.id;
  const optionKey = sessionConfig.config?.optionKey;

  const [submitSelectedReward, { loading }] = useMutation<
    { claimReward: ClaimRewardPayload },
    ClaimRewardInput
  >(clickToClaimSharedQueries.mutations.claimReward, {
    onCompleted: (data: { claimReward: ClaimRewardPayload }): void => {
      if (data.claimReward.ok) {
        const nextSession = data.claimReward.session as SessionInterface;
        const nextActiveStep = nextSession.activeStep as RewardEmailedActiveStep;

        goToStep({
          type: nextActiveStep.type,
          config: null,
          contentReplacements: nextSession.contentReplacements,
        });
      } else {
        setError(true);
      }
    },
    onError: () => setError(true),
  });

  const handleRewardClaim = (): void => {
    if (flowId && optionKey) {
      submitSelectedReward({
        variables: {
          flowId,
          optionKey,
          id,
          userType,
        },
      });
    } else {
      setError(true);
    }
  };

  const showFooterLink = stepData.config.footerLink.show;

  if (callbacks && callbacks.onLayoutLoaded) callbacks.onLayoutLoaded();

  return (
    <WrapperStyled>
      <ContentWrapperStyled>
        <IntroStyled>
          {renderTemplate(stepData.content.title, sessionContentReplacements)}
        </IntroStyled>
        <Button handleOnClick={handleRewardClaim} isLoading={loading}>
          {stepData.content.confirmButton}
        </Button>
        <Button appearance="secondary" handleOnClick={(): void => handleRewardRejection()}>
          {stepData.content.cancelButton}
        </Button>
        {error && (
          <SubmissionErrorStyled>{parse(stepData.content.claimRewardError)}</SubmissionErrorStyled>
        )}
      </ContentWrapperStyled>
      {showFooterLink && (
        <Button handleOnClick={(): void => handleFooterLinkClick()} appearance="link">
          {stepData.content.footerLink.text}
          <VisuallyHidden>Opens in a new tab</VisuallyHidden>
        </Button>
      )}
    </WrapperStyled>
  );
};

export default ConfirmRewardOption;
