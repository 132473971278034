import React, { FC, PropsWithChildren, useState } from "react";
import { Context } from "./index";
import { BsFileText } from "react-icons/bs";
import CollapsibleCard from "components/collapsible-card/CollapsibleCard";

import parse from "html-react-parser";

const ProgramRules: FC<PropsWithChildren<{ context: Context }>> = ({ context }) => {
  const [isOpen, setIsOpen] = useState(false);

  const title = context.step.content.rules.title;
  const body = context.step.content.rules.body;

  if (!title || !body) return null;

  return (
    <CollapsibleCard
      icon={BsFileText}
      title={title}
      isOpen={isOpen}
      onHeaderClick={(): void => setIsOpen(!isOpen)}
    >
      {parse(body)}
    </CollapsibleCard>
  );
};

export default ProgramRules;
