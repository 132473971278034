export const setErrorsForField = (
  setError: any,
  name: string,
  errorKey: string,
  inputs: any
): any => {
  const result = inputs[name];

  if (!result) {
    // check for custom field
    const customField = inputs.customFields.find((f: any) => f.key === name);

    if (customField) {
      const customFieldIndex = inputs.customFields.indexOf(customField) || 0;
      const customFieldName = `customFields[${customFieldIndex + 1}]`;
      const message =
        customField.content.validationErrors?.find((d: any) => d.key === errorKey)?.value ||
        errorKey;
      setError(customFieldName as never, { message: message });
    }

    return;
  }

  const message = result?.validationErrors?.find((d: any) => d.key === errorKey)?.value || errorKey;
  setError(name as never, { message: message });
};
