import Title from "../Shared/Title";
import Image from "../Shared/Image";
import Legal from "./Legal";
import CollapseOffer from "../Shared/CollapseOffer";
import OfferSteps from "../Shared/OfferSteps";
import OfferDetails from "../Shared/OfferDetails";
import ShareUrl from "./ShareUrl";
import SharingTools from "./SharingTools";
import ReferralStatusMobile from "./ReferralStatus/Mobile";
import ReferralStatusDesktop from "./ReferralStatus/Desktop";
import LogoutLink from "./LogoutLink/";
import { Localisation, ReferrerDashboardStep, Urls } from "types/graphql";
import { StepConfig } from "types/StepContext.types";
import { Device } from "hooks/Device";
import RewardLimitMessage from "./rewardLimitMessage/rewardLimitMessage";
import { Modifier } from "lib/Lego/Modifiers";
import ShareCode from "./ShareCode";

export type FormData = {
  fullName: string;
  email: string;
};
type LogoutState = {
  show: boolean;
  loading: boolean;
};

export type Context = {
  step: ReferrerDashboardStep;
  session: StepConfig;
  methods: {
    handleShareCodeCopy: (copyService: string) => void;
    handleSuccessfulCopy: (copyService: string) => void;
    handleLogout: () => void;
  };
  localisation: Localisation;
  state: {
    showShareCodeCopySuccessNotification: boolean;
    sessionContentReplacements: any;
    device: Device;
    logout: LogoutState;
    showCopySuccessNotification: boolean;
    urls: Urls;
  };
};

export const BrickTypes: {
  [index: string]: React.FC<{ context: Context; modifiers: Modifier[] }>;
} = {
  Title: Title as React.FC<{ context: Context; modifiers: Modifier[] }>,
  Image: Image as React.FC<{ context: Context; modifiers: Modifier[] }>,
  CollapseOffer: CollapseOffer as React.FC<{ context: Context }>,
  OfferSteps: OfferSteps as React.FC<{ context: Context }>,
  OfferDetails: OfferDetails as React.FC<{ context: Context }>,
  Legal: Legal as React.FC<{ context: Context }>,
  ReferralStatusDesktop,
  ReferralStatusMobile,
  ShareUrl,
  LogoutLink,
  SharingTools,
  RewardLimitMessage,
  ShareCode,
};
