import React, { FC } from "react";
import { ContainerStyled } from "./GenericError.styled";

type Props = {};

const GenericError: FC<Props> = () => (
  <ContainerStyled>An error has occurred, try again later</ContainerStyled>
);

export default GenericError;
