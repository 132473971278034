import styled from "styled-components";
import { transparentize } from "polished";

export const NetworkSharingToolsStyled = styled.div`
  border: 1px solid ${({ theme }) => transparentize(0.8, theme.layout.color)};
  border-top: none;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 18px;
  width: 100%;
  padding: 20px 5px;
`;
