import React, { FC } from "react";
import StepsList from "templates/referrer_journey/components/steps_list";

import { Context, stepContent, stepConfig } from ".";

const offerSteps: FC<Context> = ({ context }) => {
  const content = stepContent(context);
  const config = stepConfig(context);
  const showIcons = !config ? true : config.offerDetails.showStepIcons;

  if (!content || !(content.offerDetails?.steps || []).some((step) => step)) {
    return null;
  }

  return <StepsList steps={content.offerDetails.steps} showIcons={showIcons} />;
};

export default offerSteps;
