import React, { FC, PropsWithChildren } from "react";
import { Context } from "./index";
import { RiShareBoxLine } from "react-icons/ri";
import CollapsibleCard from "components/collapsible-card/CollapsibleCard";
import render from "lib/Template";
import PartnerNativeSharingTools from "./PartnerNativeSharingTools";
import PartnerStandardSharingTools from "./PartnerStandardSharingTools";

const SharingTools: FC<PropsWithChildren<{ context: Context }>> = ({ context }) => {
  const settings = context.step.config.sharingTools;

  if (settings.displayDirectLink === false && settings.displayShareCode === false) {
    return null;
  }

  const device = context.state.device;
  const isNative = device.nativeSharingSupported;

  const { sharingTools } = context.step.content;
  const { directLink } = sharingTools;

  const title = sharingTools.title;

  const body = isNative ? directLink.nativeSharing.title : directLink.label;
  return (
    <CollapsibleCard title={title} icon={RiShareBoxLine} isOpen={true} collapsible={false}>
      {settings.displayDirectLink && render(body, context.state.sessionContentReplacements)}
      {isNative ? (
        <PartnerNativeSharingTools context={context} />
      ) : (
        <PartnerStandardSharingTools context={context} />
      )}
    </CollapsibleCard>
  );
};

export default SharingTools;
