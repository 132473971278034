import React, { FC } from "react";
import parse from "html-react-parser";

import { ParagraphStyled } from "styles/global.styled";

import { Context } from ".";

const body: FC<{ context: Context }> = ({ context }) => {
  return <ParagraphStyled>{parse(context.step.content.body)}</ParagraphStyled>;
};

export default body;
