import React, { FC } from "react";

import { Context } from ".";
import ShareUrl from "components/share-area";
import NativeSharingTools from "components/native-sharing-tools";

const shareUrl: FC<{ context: Context }> = ({ context }) => {
  const device = context.state.device;

  if (!context.step.config.showDirectLink) return null;

  return (
    <>
      <ShareUrl
        title={context.step.content.manualSharing.title}
        copySuccessNotification={context.step.content.manualSharing.copySuccessNotification}
        showCopySuccessNotification={context.state.showCopySuccessNotification}
        // TODO: if we start using a sessionConfig.config that is type-specific to this step
        // this property won't need to be string | undefined, and therefore we won't need the || ""
        url={context.session?.config?.manualSharingShortUrl || ""}
        onCopy={context.methods.handleSuccessfulCopy}
      />

      {device.nativeSharingSupported && (
        <NativeSharingTools
          nativeSharingTitle={context.step.content.nativeSharing.title || ""}
          nativeSharingMessage={context.step.content.shareMessage || ""}
          nativeSharingShortUrl={context.session?.config?.nativeSharingShortUrl || ""}
          nativeSharingButtonText={context.step.content.nativeSharing.buttonText}
          manualSharingCopyText={context.session?.config?.manualSharingShortUrl || ""}
          manualSharingCopyButtonText={context.step.content.manualSharing.copyButtonText}
          onCopy={context.methods.handleSuccessfulCopy}
        />
      )}
    </>
  );
};
export default shareUrl;
