import { Layout, LayoutStyle } from "lib/Lego";
import { Context } from "lib/Lego/FriendJourney/SuccessfulFriendRegistration";

const defaultLayout = {
  containerStyle: LayoutStyle.Flush,
  bricks: [
    {
      name: "Column",
      bricks: [
        {
          name: "Heading",
        },
        {
          name: "Body",
        },
      ],
    },
  ],
};

export default (layout: string): Layout<Context> => {
  switch (layout) {
    default:
      return defaultLayout;
  }
};
