import { DocumentNode } from "graphql";

import clickToClaimReferrerGetConfigPreview from "./flows/click-to-claim-referrer/config.graphql";
import clickToClaimReferrerGetSessionPreview from "./flows/click-to-claim-referrer/session.graphql";
import clickToClaimFriendGetConfigPreview from "./flows/click-to-claim-friend/config.graphql";
import clickToClaimFriendGetSessionPreview from "./flows/click-to-claim-friend/session.graphql";
import friendJourneyGetConfigPreview from "./flows/friend-journey/config.graphql";
import friendJourneyGetSessionPreview from "./flows/friend-journey/session.graphql";
import referrerJourneyGetConfigPreview from "./flows/referrer-journey/config.graphql";
import referrerJourneyGetSessionPreview from "./flows/referrer-journey/session.graphql";
import partnerJourneyGetConfigPreview from "./flows/partner-journey/config.graphql";
import partnerJourneyGetSessionPreview from "./flows/partner-journey/session.graphql";
import lookupUserJourneyGetConfig from "./flows/lookup-user-journey/config.graphql";
import lookupUserJourneyGetSession from "./flows/lookup-user-journey/session.graphql";
import redirectFriendToClientJourneyGetConfig from "./flows/redirect-friend-to-client-journey/config.graphql";
import redirectFriendToClientJourneyGetSessionPreview from "./flows/redirect-friend-to-client-journey/session.graphql";

import doNothing from "./shared/mutations/do-nothing.graphql";

export const clickToClaimReferrerQueries = {
  config: clickToClaimReferrerGetConfigPreview,
  session: clickToClaimReferrerGetSessionPreview,
};

export const clickToClaimFriendQueries = {
  config: clickToClaimFriendGetConfigPreview,
  session: clickToClaimFriendGetSessionPreview,
};

export const friendJourneyQueries = {
  config: friendJourneyGetConfigPreview,
  session: friendJourneyGetSessionPreview,
};

export const referrerJourneyQueries = {
  config: referrerJourneyGetConfigPreview,
  session: referrerJourneyGetSessionPreview,
};

export const partnerJourneyQueries = {
  config: partnerJourneyGetConfigPreview,
  session: partnerJourneyGetSessionPreview,
};

export const lookupUserJourneyQueries = {
  config: lookupUserJourneyGetConfig,
  session: lookupUserJourneyGetSession,
};

export const redirectFriendToClientJourneyQueries = {
  config: redirectFriendToClientJourneyGetConfig,
  session: redirectFriendToClientJourneyGetSessionPreview,
};

const flowConfigMap: { [key: string]: DocumentNode } = {
  click_to_claim_referrer: clickToClaimReferrerQueries.config,
  click_to_claim_friend: clickToClaimFriendQueries.config,
  friend_journey: friendJourneyQueries.config,
  referrer_journey: referrerJourneyQueries.config,
  partner_journey: partnerJourneyQueries.config,
  user_lookup_journey: lookupUserJourneyQueries.config,
  redirect_friend_to_client_journey: redirectFriendToClientJourneyQueries.config,
};

const flowSessionMap: { [key: string]: DocumentNode } = {
  click_to_claim_referrer: clickToClaimReferrerQueries.session,
  click_to_claim_friend: clickToClaimFriendQueries.session,
  friend_journey: friendJourneyQueries.session,
  referrer_journey: referrerJourneyQueries.session,
  partner_journey: partnerJourneyQueries.session,
  user_lookup_journey: lookupUserJourneyQueries.session,
  redirect_friend_to_client_journey: redirectFriendToClientJourneyQueries.session,
};

export const sharedQueries = {
  mutations: {
    doNothing: doNothing,
  },
};

export const flows = [
  "click_to_claim_referrer",
  "click_to_claim_friend",
  "friend_journey",
  "referrer_journey",
  "user_lookup_journey",
  "partner_journey",
  "redirect_friend_to_client_journey",
];

export const configPreviewForFlow = (flow: string): DocumentNode => {
  const query = flowConfigMap[flow];

  if (!query) {
    throw new Error(`No config preview queries for flow: ${flow}`);
  }

  return query;
};

export const sessionPreviewForFlow = (flow: string): DocumentNode => {
  const query = flowSessionMap[flow];

  if (!query) {
    throw new Error(`No session preview queries for flow: ${flow}`);
  }

  return query;
};
