type PopupWindow = (url: string, widowWidth?: number, windowHeight?: number) => Window | null;

const usePopupWindow = (): PopupWindow => {
  const popupWindow = (url: string, windowWidth = 500, windowHeight = 600): Window | null => {
    const y = window.screen.height / 2 - windowHeight / 2;
    const x = window.screen.width / 2 - windowWidth / 2;

    return window.open(
      url,
      undefined,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, width=${windowWidth}, height=${windowHeight}, top=${y}, left=${x}`
    );
  };

  return popupWindow;
};

export default usePopupWindow;
