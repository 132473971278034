import React, { FC, useContext } from "react";

import { FriendRewardAlreadyClaimedStep } from "types/graphql";
import RewardClaimed from "templates/click_to_claim/RewardClaimed";
import { DefaultContext } from "types/StepContext.types.ts";
import { StepContext } from "context";

export type FriendRewardAlreadyClaimedProps = {
  stepData: FriendRewardAlreadyClaimedStep;
};

const FriendRewardAlreadyClaimed: FC<FriendRewardAlreadyClaimedProps> = ({ stepData }) => {
  const { callbacks } = useContext<DefaultContext>(StepContext);

  if (callbacks && callbacks.onLayoutLoaded) callbacks.onLayoutLoaded();

  return <RewardClaimed stepData={stepData} />;
};

export default FriendRewardAlreadyClaimed;
