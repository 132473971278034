import React, { FC, useContext } from "react";
import render from "lib/Template";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";

import {
  TitleStyled,
  ParagraphStyled,
  OfferStyled,
  OfferDescriptionStyled,
  OfferImageStyled,
} from "./RedirectFriendToClient.styled";

import { ClickToRedirectFriendStep } from "types/graphql";

const Offer: FC<{}> = () => {
  const { currentStep, sessionContentReplacements } = useContext<DefaultContext>(StepContext);
  const stepData = currentStep as ClickToRedirectFriendStep;

  if (!stepData.optionalContent) return null;

  return (
    <OfferStyled>
      <OfferImageStyled>
        <img
          src={stepData.optionalContent.image?.url}
          alt={stepData.optionalContent.image?.altText}
        />
      </OfferImageStyled>
      <OfferDescriptionStyled>
        {stepData.optionalContent.title && (
          <TitleStyled>
            {render(stepData.optionalContent.title, sessionContentReplacements)}
          </TitleStyled>
        )}
        <ParagraphStyled>
          {render(stepData.optionalContent.body, sessionContentReplacements)}
        </ParagraphStyled>
      </OfferDescriptionStyled>
    </OfferStyled>
  );
};

export default Offer;
