import React, { FC, useContext, useEffect, useState } from "react";

import useRecordEvent from "hooks/RecordEvent";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";

import { ReferrerDashboardStep, EventEnum } from "types/graphql";

import getLayout from "./layouts";
import { Lego } from "lib/Lego";
import { BrickTypes, Context } from "lib/Lego/ReferrerJourney/ReferrerDashboard";
import { useDevice } from "hooks/Device";
import useMutation from "hooks/UseMutation";
import { referrerJourneyQueries } from "queries";
import { CaptureReferrerDetailsActiveStep, LogoutReferrerPayload } from "types/graphql";
import { ThemeProvider } from "styled-components";

export type ReferrerDashboardProps = {
  stepData: ReferrerDashboardStep;
};

const ReferrerDashboard: FC<ReferrerDashboardProps> = ({ stepData }) => {
  const {
    appConfig,
    sessionConfig,
    sessionContentReplacements,
    goToStep,
    localisation,
    globalStyles,
    currentStep,
    urls,
  } = useContext<DefaultContext>(StepContext);

  const flowId = appConfig.flow?.id;
  const [showCopySuccessNotification, setShowCopySuccessNotification] = useState(false);
  const [showShareCodeCopySuccessNotification, setShareCodeShowCopySuccessNotification] =
    useState(false);

  const recordEvent = useRecordEvent();

  const [logout, { loading }] = useMutation(referrerJourneyQueries.mutations.logoutReferrer, {
    onCompleted: (data: { logoutReferrer: LogoutReferrerPayload }): void => {
      if (data.logoutReferrer.ok) {
        const nextSession = data.logoutReferrer.session;
        const nextActiveStep = data.logoutReferrer.session
          .activeStep as CaptureReferrerDetailsActiveStep;

        goToStep({
          type: nextActiveStep.type,
          config: nextActiveStep.config,
          contentReplacements: nextSession.contentReplacements,
        });
      }
    },
  });

  const handleLogout = (): void => {
    logout({
      variables: {
        flowId: flowId,
        id: appConfig.id,
      },
    });
  };

  const handleSuccessfulCopy = (copyService: string): void => {
    recordEvent(EventEnum.Share, { share_network: "Copy", copy_service: copyService });
    setShowCopySuccessNotification(true);
    setTimeout(() => {
      setShowCopySuccessNotification(false);
    }, 2000);
  };

  const handleShareCodeCopy = (copyService: string): void => {
    recordEvent(EventEnum.Share, { share_network: "Copy", copy_service: copyService });
    setShareCodeShowCopySuccessNotification(true);
    setTimeout(() => {
      setShareCodeShowCopySuccessNotification(false);
    }, 2000);
  };

  const context: Context = {
    step: stepData,
    session: sessionConfig,
    localisation: localisation,
    methods: {
      handleSuccessfulCopy,
      handleShareCodeCopy,
      handleLogout,
    },
    state: {
      device: useDevice(),
      sessionContentReplacements,
      showCopySuccessNotification,
      logout: {
        show: sessionConfig.config?.canLogout || false,
        loading: loading,
      },
      showShareCodeCopySuccessNotification,
      urls,
    },
  };

  useEffect(() => {
    recordEvent(EventEnum.VisitReferrerDashboardPage);
  }, [recordEvent]);

  const theme = {
    ...globalStyles,
    currentStepStyle: (currentStep as ReferrerDashboardStep).style,
  };

  return (
    <ThemeProvider theme={theme}>
      <Lego<Context>
        brickTypes={BrickTypes}
        layout={getLayout(stepData.config.layout)}
        context={context}
        isPreview={appConfig.isPreview}
      />
    </ThemeProvider>
  );
};

export default ReferrerDashboard;
