import React, { FC, ReactNode } from "react";
import { PillStyled, TextStyled } from "./Pill.styled";

type Props = {
  handleToggle: () => void;
  text: string;
  icon: ReactNode;
};

const Pill: FC<Props> = ({ handleToggle, text, icon }) => (
  <PillStyled onClick={handleToggle}>
    <TextStyled>{text}</TextStyled>
    {icon}
  </PillStyled>
);

export default Pill;
