import styled from "styled-components";

export const ActionButtonsStyled = styled.div`
  display: inline-flex;
  width: 100%;

  button:first-of-type {
    margin-right: 5px;
  }

  button:last-of-type {
    margin-left: 5px;
  }
`;
