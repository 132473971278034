import React, { FC, useContext, useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import { RiShareBoxLine } from "react-icons/ri";
import NetworkSharingTools from "components/network-sharing-tools";
import EmailSharingTools from "components/email-sharing-tools";
import Button from "components/button/Button";
import { ReferrerDashboardActiveStepConfigShareNetworkShortUrls } from "types/graphql";

import { ActionButtonsStyled } from "./StandardSharingTools.styled";
import { DefaultContext } from "types/StepContext.types";
import { StepContext } from "context/index";

type Props = {
  shareNetworks: string[];
  shareNetworkShortUrls: ReferrerDashboardActiveStepConfigShareNetworkShortUrls;

  shareMessage: string;
  shareImage?: string;

  emailSharingSubject: string;
  emailSharingShortUrl: string;
};

const StandardSharingTools: FC<Props> = ({
  shareNetworks,
  shareNetworkShortUrls,
  shareMessage,
  shareImage,
  emailSharingSubject,
  emailSharingShortUrl,
}) => {
  const { localisation } = useContext<DefaultContext>(StepContext);

  const [visibleSection, setVisibleSection] = useState("network");

  const onNetworkSharingClick = (): void => {
    setVisibleSection("network");
  };

  const onEmailSharingClick = (): void => {
    setVisibleSection("email");
  };

  return (
    <>
      <ActionButtonsStyled>
        <Button
          icon={<RiShareBoxLine />}
          appearance={visibleSection === "network" ? "active-tab" : "inactive-tab"}
          handleOnClick={onNetworkSharingClick}
        >
          {localisation.sharing.tabs.socialButtonText}
        </Button>
        <Button
          icon={<FaEnvelope />}
          appearance={visibleSection === "network" ? "inactive-tab" : "active-tab"}
          handleOnClick={onEmailSharingClick}
        >
          {localisation.sharing.tabs.emailButtonText}
        </Button>
      </ActionButtonsStyled>

      {visibleSection === "network" && (
        <NetworkSharingTools
          networks={shareNetworks}
          networkShareUrls={shareNetworkShortUrls}
          networkShareMessage={shareMessage}
          networkShareImageUrl={shareImage}
        />
      )}

      {visibleSection === "email" && (
        <EmailSharingTools
          subject={emailSharingSubject}
          message={shareMessage}
          shareUrl={emailSharingShortUrl}
        />
      )}
    </>
  );
};

export default StandardSharingTools;
