import React, { FC, PropsWithChildren } from "react";
import { TitleStyled } from "styles/global.styled";
import { Context } from "./index";

import renderTemplate from "lib/Template";

const PartnerDashboardTitle: FC<PropsWithChildren<{ context: Context }>> = ({ context }) => {
  return (
    <TitleStyled>
      {renderTemplate(context.step.content.title, context.state.sessionContentReplacements)}
    </TitleStyled>
  );
};

export default PartnerDashboardTitle;
