import React, { FC, useContext, useEffect } from "react";
import { EventEnum } from "types/graphql";
import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";
import useRecordEvent from "hooks/RecordEvent";
import { BrickTypes, Context } from "lib/Lego/RewardRedemptionJourney/RedeemReward";
import { Lego } from "lib/Lego";
import getLayout from "./layouts";

const RedeemReward: FC = () => {
  const { appConfig, sessionContentReplacements, sessionConfig } =
    useContext<DefaultContext>(StepContext);

  const recordEvent = useRecordEvent();

  const context: Context = {
    step: {},
    session: sessionConfig,
    methods: {},
    state: {
      sessionContentReplacements,
    },
    optionalStep: false,
  };

  useEffect(() => {
    recordEvent(EventEnum.VisitRewardRedemptionPage);
  }, [recordEvent]);

  return (
    <Lego<Context>
      brickTypes={BrickTypes}
      layout={getLayout("default")}
      context={context}
      isPreview={appConfig.isPreview}
    />
  );
};

export default RedeemReward;
