import React, { FC, useContext, useState, useEffect } from "react";

import useMutation from "hooks/UseMutation";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";

import {
  CaptureFriendDetailsStep,
  RegisterFriendPayload,
  PartiallyRegisterFriendPayload,
  RegisterFriendInput,
  PartiallyRegisterFriendInput,
  SessionInterface,
  SuccessfulFriendRegistrationActiveStep,
  IneligibleForVoucherActiveStep,
  EventEnum,
  ValidationError,
} from "types/graphql";

import { friendJourneyQueries } from "queries";

import useRecordEvent from "hooks/RecordEvent";

import getLayout from "./layouts";
import { Lego } from "lib/Lego";
import { FormData, Context, BrickTypes } from "lib/Lego/FriendJourney/CaptureFriendDetails";
import { FormProvider, useForm } from "react-hook-form";
import { setErrorsForField } from "templates/helpers/SetErrorsForField";

export type CaptureFriendDetailsProps = {
  stepData: CaptureFriendDetailsStep;
};

interface NextSessionPayload extends SessionInterface {
  activeStep: SuccessfulFriendRegistrationActiveStep | IneligibleForVoucherActiveStep;
}

const CaptureFriendDetails: FC<CaptureFriendDetailsProps> = ({ stepData }) => {
  const { appConfig, goToStep, sessionConfig, sessionContentReplacements, programType } =
    useContext<DefaultContext>(StepContext);

  const [registrationError, setRegistrationError] = useState<boolean>(false);

  const recordEvent = useRecordEvent();

  const methods = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  const { setError } = methods;

  const flowId = appConfig.flow.id;
  const id = appConfig.id;
  const locale = appConfig.locale;

  const isPartnerAudience = programType === "partner";

  const {
    config: { partialRegistration, inputs: inputConfig },
  } = stepData;

  const [submitFriendRegistration, { loading: submitFriendRegistrationLoading }] = useMutation<
    { registerFriend: RegisterFriendPayload },
    RegisterFriendInput
  >(friendJourneyQueries.mutations.registerFriend, {
    onCompleted: (data: { registerFriend: RegisterFriendPayload }): void => {
      if (data.registerFriend.ok) {
        const nextSession = data.registerFriend.session as NextSessionPayload;

        goToStep({
          type: nextSession.activeStep.type,
          config: nextSession.activeStep.config,
          validationErrors: (nextSession.activeStep as any).validationErrors || [],
          contentReplacements: nextSession.contentReplacements,
        });
      } else {
        const nextSession = data.registerFriend.session;

        setValidationErrors((nextSession.activeStep as any).validationErrors || []);

        setRegistrationError(true);
      }
    },
    onError: () => setRegistrationError(true),
  });

  const setValidationErrors = (validationErrors: ValidationError[]) => {
    validationErrors.forEach((f: ValidationError) => {
      setErrorsForField(setError, f.field, f.key, stepData.content?.form.inputs);
    });
  };

  useEffect(() => {
    setValidationErrors(sessionConfig?.validationErrors || []);
  }, [sessionConfig]);

  const [submitPartialFriendRegistration, { loading: submitPartialFriendRegistrationLoading }] =
    useMutation<
      { partiallyRegisterFriend: PartiallyRegisterFriendPayload },
      PartiallyRegisterFriendInput
    >(friendJourneyQueries.mutations.partiallyRegisterFriend, {
      onCompleted: (data: { partiallyRegisterFriend: PartiallyRegisterFriendPayload }): void => {
        if (data.partiallyRegisterFriend.ok) {
          const nextSession = data.partiallyRegisterFriend.session as NextSessionPayload;

          goToStep({
            type: nextSession.activeStep.type,
            config: nextSession.activeStep.config,
            validationErrors: (nextSession.activeStep as any).validationErrors || [],
            contentReplacements: nextSession.contentReplacements,
          });
        } else {
          setRegistrationError(true);
        }
      },
      onError: () => setRegistrationError(true),
    });
  const loading = submitFriendRegistrationLoading || submitPartialFriendRegistrationLoading;

  const handleFriendRegistration = (formData: FormData): void => {
    const { fullName, firstName, lastName, email, marketingOptIn, termsOptIn, customFields } =
      formData;

    const variables = {
      flowId,
      id,
      locale,
      fullName,
      firstName,
      lastName,
      email,
      marketingOptIn,
      termsOptIn,
    } as RegisterFriendInput;

    if (!partialRegistration) {
      if (customFields)
        variables.customFields = Object.keys(customFields).map((m) => ({
          key: inputConfig.customFields.find((f) => f.position === parseInt(m))?.key || "",
          value: customFields[m],
        }));

      submitFriendRegistration({ variables });
    } else {
      submitPartialFriendRegistration({ variables });
    }
  };

  const context: Context = {
    step: stepData,
    session: sessionConfig,
    methods: {
      handleFriendRegistration,
    },
    state: {
      registrationError,
      loading,
      sessionContentReplacements,
      isPartnerAudience,
    },
    optionalStep: true,
  };

  useEffect(() => {
    recordEvent(EventEnum.VisitVoucherPage);
  }, [recordEvent]);

  return (
    <FormProvider {...methods}>
      <Lego<Context>
        brickTypes={BrickTypes}
        layout={getLayout("default")}
        context={context}
        isPreview={appConfig.isPreview}
      />
    </FormProvider>
  );
};

export default CaptureFriendDetails;
