import { EmbedParameters, PreviewState } from "types/embed-parameters.types";
import { ApolloClient } from "@apollo/client";
import { sessionPreviewForFlow } from "preview-queries";
import { SessionInterface } from "types/graphql";
import { PreviewStateInput } from "types/graphql-preview";

const buildState = (state: PreviewState | undefined): PreviewStateInput => {
  if (!state) {
    return {};
  }

  return {
    showSignInLinkEmailedMessage: state.show_sign_in_link_emailed_message,
    showEligibilityCheckRejectionMessage: state.show_eligibility_check_rejection_message,
    canLogout: state.can_logout,
    withPendingReferrals: state.with_pending_referrals,
    withCompleteReferrals: state.with_complete_referrals,
    rewardTypeId: state.reward_type_id,
    selectedRewardTitle: state.selected_reward_title,
    isFirstVisitingCaptureFriendAdditionalDetails:
      state.is_first_visiting_capture_friend_additional_details,
    showInvalidCodeMessage: state.show_invalid_code_message,
    allowFriendAdditionalReferral: state.allow_friend_additional_referral,
    showRewardLimitWarningMessage: state.show_reward_limit_warning_message,
  };
};

export default (
  apolloClient: ApolloClient<object>,
  params: EmbedParameters,
): Promise<SessionInterface> => {
  return new Promise<SessionInterface>((resolve, reject) => {
    apolloClient
      .query({
        query: sessionPreviewForFlow(params.flow.name),
        variables: {
          flow: params.flow.name,
          campaignSlug: params.campaign,
          step: params.preview?.step,
          configurationId: params.preview?.configuration_id,
          state: buildState(params.preview?.state),
          locale: params.locale,
        },
      })
      .then((result) => {
        resolve(result.data.sessionPreview as SessionInterface);
      })
      .catch(reject);
  });
};
