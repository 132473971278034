import React, { FC } from "react";
import { Legal } from "components/legal";

import { Context } from ".";
import { NewLegalStyled, V1LegalStyled } from "components/legal/Legal.styled";
import { renderString } from "lib/Template";
import { Modifier } from "lib/Lego/Modifiers";
import MarkdownContent from "components/MarkdownContent";

const legal: FC<{ context: Context; modifiers: Modifier[] }> = ({ context, modifiers }) => {
  if (!context.step.optionalContent?.additionalFinePrint) {
    return null;
  }

  const isV1 = !!modifiers.find((f) => f === Modifier.V1);
  const overrideStyle = isV1 ? V1LegalStyled : NewLegalStyled;

  return (
    <Legal overrideLegalStyled={overrideStyle}>
      <MarkdownContent>
        {renderString(
          context.step.optionalContent.additionalFinePrint,
          context.state.sessionContentReplacements
        )}
      </MarkdownContent>
    </Legal>
  );
};

export default legal;
