import { StepContext } from "context/index";
import React, { FC, useContext } from "react";

import { CgScreen } from "react-icons/cg";
import { DefaultContext } from "types/StepContext.types";

import {
  EmailButtonOuterStyled,
  EmailButtonStyled,
  EmailButtonLabelStyled,
} from "../EmailButton.styled";

import { Props } from "../EmailButton.types";

const Desktop: FC<Props> = ({ subject, message, onPopupOpen }) => {
  const { localisation } = useContext<DefaultContext>(StepContext);

  const onClick = (): void => {
    window.location.assign(
      `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`,
    );

    onPopupOpen();
  };

  return (
    <EmailButtonOuterStyled>
      <EmailButtonStyled onClick={onClick}>
        <CgScreen />
      </EmailButtonStyled>
      <EmailButtonLabelStyled>
        {localisation.sharing.email.emailClientLabelText}
      </EmailButtonLabelStyled>
    </EmailButtonOuterStyled>
  );
};

export default Desktop;
