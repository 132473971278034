import styled from "styled-components";
import { useForLargeContainer } from "hooks/ResponsiveStyle";
import { ParagraphStyled, TitleStyled } from "styles/global.styled";

export const WrapperStyled = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;
  padding-bottom: 30px;
`;

export const ContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;

  button {
    margin-bottom: 10px;
    ${() => useForLargeContainer("max-width: 295px;")}
  }
`;

export const IntroStyled = styled(TitleStyled)`
  text-align: center;
  margin-bottom: 30px;
  ${() => useForLargeContainer("max-width: 475px;")}
`;

export const SubmissionErrorStyled = styled(ParagraphStyled)`
  color: ${({ theme }) => theme.colors.error};
  text-align: center;
`;
