import React, { FC, useContext, useEffect, useState } from "react";

import useMutation from "hooks/UseMutation";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";

import {
  AddFriendDetailsInput,
  AddFriendDetailsPayload,
  CaptureAdditionalFriendDetailsStep,
  SessionInterface,
  SuccessfulFriendRegistrationActiveStep,
  IneligibleForVoucherActiveStep,
  AddFriendActionIdPayload,
  AddFriendActionIdInput,
  ValidationError,
} from "types/graphql";

import { friendJourneyQueries } from "queries";

import getLayout from "./layouts";
import { Lego } from "lib/Lego";
import {
  FormData,
  Context,
  BrickTypes,
} from "lib/Lego/FriendJourney/CaptureAdditionalFriendDetails";
import { useDevice } from "hooks/Device";
import { FormProvider, useForm } from "react-hook-form";
import { setErrorsForField } from "templates/helpers/SetErrorsForField";

export type CaptureAdditionalFriendDetailsProps = {
  stepData: CaptureAdditionalFriendDetailsStep;
};

interface NextSessionPayload extends SessionInterface {
  activeStep: SuccessfulFriendRegistrationActiveStep | IneligibleForVoucherActiveStep;
}

const CaptureAdditionalFriendDetails: FC<CaptureAdditionalFriendDetailsProps> = ({ stepData }) => {
  const { sessionConfig, appConfig, goToStep, sessionContentReplacements } =
    useContext<DefaultContext>(StepContext);
  const [registrationError, setRegistrationError] = useState<boolean>(false);
  const flowId = appConfig.flow.id;
  const id = appConfig.id;

  const methods = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  const { setError } = methods;

  const [submitFriendDetails, { loading: submitFriendDetailsLoading }] = useMutation<
    { addFriendDetails: AddFriendDetailsPayload },
    AddFriendDetailsInput
  >(friendJourneyQueries.mutations.addFriendDetails, {
    onCompleted: (data: { addFriendDetails: AddFriendDetailsPayload }): void => {
      if (data.addFriendDetails.ok) {
        const nextSession = data.addFriendDetails.session as NextSessionPayload;

        goToStep({
          type: nextSession.activeStep.type,
          config: nextSession.activeStep.config,
          contentReplacements: nextSession.contentReplacements,
        });
      } else {
        const nextSession = data.addFriendDetails.session;

        setValidationErrors((nextSession.activeStep as any).validationErrors || []);

        setRegistrationError(true);
      }
    },
    onError: () => setRegistrationError(true),
  });

  const [addFriendActionId] = useMutation<
    { addFriendActionId: AddFriendActionIdPayload },
    AddFriendActionIdInput
  >(friendJourneyQueries.mutations.addFriendActionId, {});

  const config = stepData.optionalConfig;
  const content = stepData.optionalContent;

  if (!content || !config) {
    throw new Error("Invalid template is being rendered");
  }

  const inputConfig = config.inputs;
  const firstVisit = !!sessionConfig.config?.firstVisit;

  const handleFriendRegistration = (formData: FormData): void => {
    const { customFields } = formData;

    const variables = {
      flowId,
      id,
    } as AddFriendDetailsInput;

    if (customFields)
      variables.customFields = Object.keys(customFields).map((m) => ({
        key: inputConfig.customFields.find((f) => f.position === parseInt(m))?.key || "",
        value: customFields[m],
      }));

    submitFriendDetails({ variables });
  };

  const handleCallToActionClick = (url: string, actionId?: string): void => {
    if (actionId) {
      const variables = { flowId, id, actionId } as AddFriendActionIdInput;
      addFriendActionId({ variables });
    }

    window.open(url, "_blank");
  };

  const setValidationErrors = (validationErrors: ValidationError[]) => {
    validationErrors.forEach((f: ValidationError) => {
      setErrorsForField(setError, f.field, f.key, stepData.optionalContent?.form.inputs);
    });
  };

  useEffect(() => {
    setValidationErrors(sessionConfig?.validationErrors || []);
  }, [sessionConfig]);

  const context: Context = {
    step: stepData,
    session: sessionConfig,
    methods: {
      handleFriendRegistration,
      handleCallToActionClick,
    },
    state: {
      device: useDevice(),
      registrationError,
      sessionContentReplacements,
      firstVisit,
      loading: submitFriendDetailsLoading,
    },
    optionalStep: true,
  };

  return (
    <FormProvider {...methods}>
      <Lego<Context>
        brickTypes={BrickTypes}
        layout={getLayout("default")}
        context={context}
        isPreview={appConfig.isPreview}
      />
    </FormProvider>
  );
};

export default CaptureAdditionalFriendDetails;
