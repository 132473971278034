import React, { FC } from "react";
import styled from "styled-components";
import { resolveColor } from "styles/helpers";
import { transparentize } from "polished";
import { LinkBlendedInParagraphStyled, ParagraphStyled } from "styles/global.styled";

import { Context, stepContent } from ".";

import { renderString } from "lib/Template";
import MarkdownContent from "components/MarkdownContent";

const OfferDetailsStyled = styled(ParagraphStyled)`
  margin-top: 0;
  color: ${({ theme }): string => transparentize(0.2, resolveColor(theme, theme.layout.color))};

  ${LinkBlendedInParagraphStyled}
`;

const offerDetails: FC<Context> = ({ context }) => {
  const content = stepContent(context);

  if (!content) {
    return null;
  }

  return (
    <OfferDetailsStyled>
      <MarkdownContent>
        {renderString(content.offerDetails.footer, context.state.sessionContentReplacements)}
      </MarkdownContent>
    </OfferDetailsStyled>
  );
};

export default offerDetails;
