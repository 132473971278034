import styled from "styled-components";
import { resolveColor, resolveWithDefault, setSvgCssFromAttributes } from "styles/helpers";
import { transparentize } from "polished";
import { ParagraphStyled } from "styles/global.styled";
import { DefaultTheme } from "styled-components";

export const ListStyled = styled.ul`
  padding: 0;
`;

const avatarColor = (theme: DefaultTheme) =>
  resolveWithDefault(
    resolveColor(theme, theme.avatar.color),
    resolveColor(theme, theme.layout.backgroundColor),
  );

export const ListIconStyled = styled.div`
  border-radius: ${({ theme }) => theme.avatar.borderRadius};
  background-color: ${({ theme }) => resolveColor(theme, theme.avatar.backgroundColor)};
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  line-height: 48px;
  text-align: center;
  color: ${({ theme }) => avatarColor(theme)};
  margin-right: 15px;
  ${({ children }) => setSvgCssFromAttributes(children)};
  ${({ theme }) => theme.avatar.css}
`;
0.0;

export const ListItemStyled = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0 0 10px;
  svg {
    font-size: 22px;
    vertical-align: middle;
    fill: ${({ theme }) => avatarColor(theme)}};
  }
`;

export const ListItemTextStyled = styled(ParagraphStyled)`
  color: ${({ theme }) => transparentize(0.2, resolveColor(theme, theme.layout.color))};
`;
