import { Format } from "components/FormattedDate";
import { getDateFormatter } from "components/FormattedDate/FormattedDate";
import { StepContext } from "context/index";
import { renderString } from "lib/Template";
import React, { useContext } from "react";
import { FulfilmentExpiredStep, EventEnum } from "types/graphql";
import { DefaultContext } from "types/StepContext.types";
import useRecordEvent from "hooks/RecordEvent";
import { PageContainer, TextContainer, FooterContainer } from "./FulfilmentExpired.styled";
import { TitleStyled, ParagraphStyled } from "styles/global.styled";
import Button from "components/button";
import VisuallyHidden from "components/visually-hidden";
import { DateTime } from "luxon";

export type FulfilmentExpiredProps = {
  stepData: FulfilmentExpiredStep;
};

const FulfilmentExpired: React.FC<FulfilmentExpiredProps> = ({ stepData }) => {
  const { sessionContentReplacements, localisation, appConfig, currentStep, callbacks } =
    useContext<DefaultContext>(StepContext);

  const {
    title,
    body,
    footerLink: { url: footerUrl, text: footerText },
  } = stepData.content;

  interface EventKeys {
    [key: string]: any;
  }

  const eventKeys: EventKeys = {
    click_to_claim_friend: EventEnum.ClickToClaimFriendLinkClicks,
    click_to_claim_referrer: EventEnum.ClickToClaimReferrerLinkClicks,
  };

  const timestamp = sessionContentReplacements["fulfilment.expiry_date"];
  const localisedStrftime = getDateFormatter(localisation);
  const date = DateTime.fromISO(timestamp);
  const flowName = appConfig.flow.name;
  const sessionContentReplacementsWithFormatedExiryDate = {
    ...sessionContentReplacements,
    "fulfilment.expiry_date": localisedStrftime(localisation.date[Format.Long], date.toJSDate()),
  };

  const recordEvent = useRecordEvent();
  const handleFooterLinkClick = (): void => {
    recordEvent(eventKeys[flowName], {
      step: currentStep.type,
      target: "footer_link",
    });

    window.open(renderString(footerUrl, sessionContentReplacementsWithFormatedExiryDate));
  };

  const showFooterLink = stepData.config.footerLink.show;

  if (callbacks && callbacks.onLayoutLoaded) callbacks.onLayoutLoaded();

  return (
    <PageContainer>
      <TextContainer>
        <TitleStyled>
          {renderString(title, sessionContentReplacementsWithFormatedExiryDate)}
        </TitleStyled>
        <ParagraphStyled>
          {renderString(body, sessionContentReplacementsWithFormatedExiryDate)}
        </ParagraphStyled>
      </TextContainer>
      {showFooterLink && (
        <FooterContainer>
          <Button handleOnClick={(): void => handleFooterLinkClick()} appearance="link">
            {renderString(footerText, sessionContentReplacementsWithFormatedExiryDate)}
            <VisuallyHidden>Opens in a new tab</VisuallyHidden>
          </Button>
        </FooterContainer>
      )}
    </PageContainer>
  );
};

export default FulfilmentExpired;
