import styled from "styled-components";
import { transparentize } from "polished";
import { resolveColor } from "styles/helpers";
import { useForSmallContainer } from "hooks/ResponsiveStyle";

export const DesktopWrapper = styled.div`
  border: 1px solid ${({ theme }): string => transparentize(0.8, theme.layout.color)};
  border-radius: 8px;

  ${() => useForSmallContainer(`overflow: hidden;`)}
`;

export const TableWrapper = styled.div`
  border-radius: 8px;
  padding: 0.8rem;
  color: ${({ theme }): string => resolveColor(theme, theme.layout.color)};
`;
