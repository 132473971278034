import styled from "styled-components";
import { resolveColor, resolveFontSize, resolveWithDefault } from "styles/helpers";
import { css, createGlobalStyle } from "styled-components";

export const GlobalStyled = createGlobalStyle`
  html {
    background-color: ${({ theme }) => theme.layout.backgroundColor};
  }
`;

export const GlobalWrapperStyled = styled.div`
  background-color: ${({ theme }) => resolveColor(theme, theme.layout.backgroundColor)};
  font-family: ${({ theme }) => theme.font.family};
  font-weight: ${({ theme }) => theme.font.weight};
  font-size: ${({ theme }): string => resolveFontSize(theme, "medium")};
  display: flex;
  min-height: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
`;

export const EmphasisInParagraphStyled = css`
  em {
    font-family: ${({ theme }) => resolveWithDefault(theme.emphasis.fontFamily, theme.font.family)};
    font-weight: ${({ theme }) => theme.emphasis.fontWeight};
    font-style: ${({ theme }) => theme.emphasis.fontStyle};
    color: ${({ theme }) =>
        resolveWithDefault(
          resolveColor(theme, theme.emphasis.color),
          resolveColor(theme, theme.layout.color),
        )}
      ${({ theme }) => theme.emphasis.css};
  }
`;

export const LinkStyle = css`
  color: ${({ theme }) =>
    resolveWithDefault(
      resolveColor(theme, theme.link.color),
      resolveColor(theme, theme.layout.color),
    )};
  font-size: ${({ theme }) => resolveFontSize(theme, theme.link.fontSize)};
  font-weight: ${({ theme }) => theme.link.fontWeight};
  font-family: ${({ theme }) => resolveWithDefault(theme.link.fontFamily, "inherit")};
  text-decoration: ${({ theme }) => theme.link.textDecoration};
  text-align: center;

  &:active {
    color: ${({ theme }) =>
      resolveWithDefault(
        resolveColor(theme, theme.link._active.color),
        resolveColor(theme, theme.layout.color),
      )};
  }

  &:visited {
    color: ${({ theme }) =>
      resolveWithDefault(
        resolveColor(theme, theme.link._visited.color),
        resolveColor(theme, theme.layout.color),
      )};
  }

  &:hover {
    color: ${({ theme }) => resolveColor(theme, theme.link._hover.color)};
  }
`;

export const LinkHighlightedInParagraphStyled = css`
  a {
    ${LinkStyle}
  }
`;

export const LinkBlendedInParagraphStyled = css`
  a {
    ${LinkStyle}
  }

  a {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
  }
`;

export const TitleStyled = styled.h4`
  font-family: ${({ theme }): string =>
    resolveWithDefault(theme.title.fontFamily, theme.font.family)};
  font-size: ${({ theme }): string => resolveFontSize(theme, theme.title.fontSize)};
  font-weight: ${({ theme }): string => theme.title.fontWeight};
  color: ${({ theme }): string =>
    resolveWithDefault(resolveColor(theme, theme.title.color), theme.layout.color)};

  ${({ theme }) => theme.title.css};

  ${LinkBlendedInParagraphStyled}
`;

export const StrongInParagraphStyled = css`
  strong {
    font-family: ${({ theme }) => resolveWithDefault(theme.strong.fontFamily, theme.font.family)};
    font-weight: ${({ theme }) => theme.strong.fontWeight};
    color: ${({ theme }) =>
        resolveWithDefault(
          resolveColor(theme, theme.strong.color),
          resolveColor(theme, theme.layout.color),
        )}
      ${({ theme }) => theme.strong.css};
  }
`;

export const ParagraphStyled = styled.p`
  font-family: ${({ theme }) => theme.font.family};
  font-weight: ${({ theme }) => theme.font.weight};
  color: ${({ theme }) => resolveColor(theme, theme.layout.color)};

  ${StrongInParagraphStyled}
  ${EmphasisInParagraphStyled}
  ${LinkBlendedInParagraphStyled}
`;
