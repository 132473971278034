import React, { FC } from "react";
import { Legal } from "components/legal";

import { Context } from ".";
import render from "lib/Template";

const legal: FC<{ context: Context }> = ({ context }) => {
  if (!context.step.optionalContent?.additionalFinePrint) {
    return null;
  }

  return (
    <Legal>
      {render(
        context.step.optionalContent.additionalFinePrint,
        context.state.sessionContentReplacements,
      )}
    </Legal>
  );
};

export default legal;
