import { Layout, LayoutStyle, Modifier } from "lib/Lego";
import { Context } from "lib/Lego/RedirectFriendToClientJourney/ClickToRedirectFriend";

const defaultLayout = {
  containerStyle: {
    largeContainer: LayoutStyle.Regular,
    smallContainer: LayoutStyle.Flush,
  },
  bricks: [
    {
      name: "Row",
      bricks: [
        {
          name: "Offer",
          modifiers: [Modifier.VerticalAlignMiddle],
        },
        {
          name: "RedirectContent",
          modifiers: {
            largeContainer: [Modifier.VerticalAlignMiddle, Modifier.PaddingLeftLarge],
          },
          bricks: [
            {
              name: "RedirectLegal",
            },
            {
              name: "SubmissionError",
            },
          ],
        },
      ],
    },
    {
      name: "Row",
      bricks: [{ name: "Legal" }],
      modifiers: {
        smallContainer: [Modifier.PaddingTopMedium],
      },
    },
  ],
} as Layout<Context>;

export default (layout: string): Layout<Context> => {
  switch (layout) {
    default:
      return defaultLayout;
  }
};
