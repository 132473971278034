import React, { FC } from "react";
import parse from "html-react-parser";
import styled from "styled-components";
import { resolveColor } from "styles/helpers";
import { ParagraphStyled } from "styles/global.styled";

import { Context } from ".";

export const SubmissionErrorStyled = styled(ParagraphStyled)`
  color: ${({ theme }): string => resolveColor(theme, theme.form.error.color)};
  text-align: center;
`;

const SubmissionError: FC<{ context: Context }> = ({ context }) => {
  if (!context.step.content || !context.state.registrationError) {
    return null;
  }

  return (
    <SubmissionErrorStyled>{parse(context.step.content.form.submitError)}</SubmissionErrorStyled>
  );
};

export default SubmissionError;
