import React, { FC } from "react";

import { AiFillYahoo } from "react-icons/ai";

import {
  EmailButtonOuterStyled,
  EmailButtonStyled,
  EmailButtonLabelStyled,
} from "../EmailButton.styled";
import usePopupWindow from "../../../hooks/PopupWindow";

import { Props } from "../EmailButton.types";

const Yahoo: FC<Props> = ({ subject, message, buttonText = "Yahoo", onPopupOpen }) => {
  const popupWindow = usePopupWindow();
  const onClick = (): void => {
    popupWindow(
      `http://compose.mail.yahoo.com/?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(message)}`
    );

    onPopupOpen();
  };

  return (
    <EmailButtonOuterStyled>
      <EmailButtonStyled onClick={onClick}>
        <AiFillYahoo />
      </EmailButtonStyled>
      <EmailButtonLabelStyled>{buttonText}</EmailButtonLabelStyled>
    </EmailButtonOuterStyled>
  );
};

export default Yahoo;
