import styled from "styled-components";
import { transparentize } from "polished";
import { resolveFontSize, resolveColor, setSvgCssFromAttributes } from "styles/helpers";

export const EmailButtonStyled = styled.button`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: #fff;
  text-decoration: none;
  display: flex;
  border: 0;
  outline: 0;
  cursor: pointer;
  font-size: ${({ theme }) => theme.font.sizes["medium"]};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  background-color: ${({ theme }) => transparentize(0.9, theme.layout.color)};

  ${({ children }) => setSvgCssFromAttributes(children)}
  svg {
    color: ${({ theme }): string => resolveColor(theme, theme.layout.color)};
    fill: ${({ theme }): string => resolveColor(theme, theme.layout.color)};
    font-size: 22px;
    ${({ theme }) => theme.currentStepStyle?.shareButtons?.icon?.css}
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.highlight};
    svg {
      color: ${({ theme }) => resolveColor(theme, theme.layout.backgroundColor)};
      fill: ${({ theme }) => resolveColor(theme, theme.layout.backgroundColor)};
      ${({ theme }) => theme.currentStepStyle?.shareButtons?.icon?._hover?.css}
    }
    ${({ theme }) => theme.currentStepStyle?.shareButtons?._hover?.css}
  }

  &:active {
    background-color: ${({ theme }) => transparentize(0.8, theme.colors.highlight)};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${({ theme }) => transparentize(0.8, theme.colors.highlight)};
  }

  ${({ theme }) => theme.currentStepStyle?.shareButtons?.css}
`;

export const EmailButtonOuterStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EmailButtonLabelStyled = styled.span`
  vertical-align: middle;
  text-align: center;
  line-height: 20px;
  font-size: ${({ theme }) => resolveFontSize(theme, "extra_small")};
  color: ${({ theme }) => resolveColor(theme, theme.layout.color)};
`;
