import React, { FC, useContext } from "react";

import { RewardEmailedReturningStep } from "types/graphql";
import RewardClaimed from "templates/click_to_claim/RewardClaimed";
import { DefaultContext } from "types/StepContext.types.ts";
import { StepContext } from "context";

export type ReferrerRewardAlreadyClaimedProps = {
  stepData: RewardEmailedReturningStep;
};

const ReferrerRewardAlreadyClaimed: FC<ReferrerRewardAlreadyClaimedProps> = ({ stepData }) => {
  const { callbacks } = useContext<DefaultContext>(StepContext);

  if (callbacks && callbacks.onLayoutLoaded) callbacks.onLayoutLoaded();

  return <RewardClaimed stepData={stepData} />;
};

export default ReferrerRewardAlreadyClaimed;
