import React, { FC } from "react";
import {
  HeaderItemStyled,
  WrapperStyled,
  NumericItemTitle,
  HeaderItemTitle,
} from "./header.styled";
import {
  ReferralStatusStatsContent,
  ReferrerDashboardActiveStepConfigReferralStatus,
  ReferrerDashboardStepConfig,
} from "types/graphql";

type Props = {
  stats: ReferralStatusStatsContent;
  config: ReferrerDashboardActiveStepConfigReferralStatus;
  stepConfig: ReferrerDashboardStepConfig;
};

const Header: FC<Props> = ({ stats, config, stepConfig }) => {
  type StatsKey = keyof typeof stats;
  type ConfigKey = keyof typeof config;

  if (!stepConfig.showReferralStats) {
    return null;
  }

  return (
    <WrapperStyled>
      {stepConfig.referralStats.map((stat) => {
        const statName = stats[stat as StatsKey];
        const statCount = config[stat as ConfigKey];

        return typeof statName === "string" && typeof statCount === "number" ? (
          <HeaderItemStyled key={stat}>
            <HeaderItemTitle>{statName}</HeaderItemTitle>
            <NumericItemTitle>{statCount}</NumericItemTitle>
          </HeaderItemStyled>
        ) : null;
      })}
    </WrapperStyled>
  );
};

export default Header;
