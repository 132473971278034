import styled from "styled-components";

import { FormCaptionStyled, FormStyled } from "components/form/Form.styled";
import { NewFormStyled as GlobalFormStyled } from "components/form/Form.styled";
import { ButtonStyled } from "components/button/Button.styled";
import { resolveColor } from "styles/helpers";
import { useForLargeContainer, useForSmallContainer } from "hooks/ResponsiveStyle";
import {
  TitleStyled as GlobalTitleStyled,
  ParagraphStyled as GlobalParagraphStyled,
  LinkHighlightedInParagraphStyled,
  StrongInParagraphStyled,
  LinkBlendedInParagraphStyled,
} from "styles/global.styled";
import { SwitchGroup } from "components/switch/Switch.styled";

export const OfferStyled = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => resolveColor(theme, theme.layout.backgroundColor)};

  ${() =>
    useForLargeContainer(`
        box-shadow: 0 0 15px 0 rgba(21, 40, 50, 0.15);
        border-radius: 14px;
        max-width: 380px;
        width: 100%;
      `)}

  ${LinkHighlightedInParagraphStyled}
  ${StrongInParagraphStyled}
`;

export const OfferImageStyled = styled.div`
  width: 100%;

  img {
    display: block;
    margin: auto;
    max-width: 100%;
    object-fit: cover;
    ${() =>
      useForLargeContainer(`
        border-top-right-radius: 14px;
        border-top-left-radius: 14px;
      `)}
  }
`;

export const OfferDescriptionStyled = styled.div`
  word-break: break-word;
  ${() => useForSmallContainer("padding: 30px 20px 10px;")}
  ${() => useForLargeContainer("padding: 28px 30px 38px;")}
`;

export const TitleStyled = styled(GlobalTitleStyled)`
  margin: 0 0 20px;
`;

export const ParagraphStyled = styled(GlobalParagraphStyled)`
  margin: 0;
`;

export const WrapperStyled = styled.div`
  padding-bottom: 40px;
  ${() =>
    useForLargeContainer(`
      padding-top: 40px;
      max-width: 750px;
      width: 100%;
    `)}
`;

export const BodyWrapperStyled = styled.div`
  display: flex;
  ${() => useForSmallContainer("flex-direction: column;")}
  ${() => useForLargeContainer("flex-direction: row;")}
  align-items: center;
  margin-bottom: 50px;

  ${ButtonStyled} {
    margin-top: 10px;
  }

  ${() =>
    useForLargeContainer(`
      ${OfferStyled} {
        flex: 1;
        margin-right: 60px;
      }
      ${FormStyled} {
        max-width: 310px;
        flex: 1;
      }
    `)}
`;

export const SubmissionErrorStyled = styled(GlobalParagraphStyled)`
  color: ${({ theme }) => resolveColor(theme, theme.form.error.color)};
  text-align: center;
`;

export const PartnerFormCaptionStyled = styled(FormCaptionStyled)`
  font-weight: normal;
`;

export const PartnerFormStyled = styled(GlobalFormStyled)`
  padding: 15px;
  flex: 1;
  grid-gap: 0;

  ${SwitchGroup} {
    margin: 0;
  }

  ${() =>
    useForSmallContainer(`
    margin: 1.2rem;
    width: auto;
  `)}
`;

export const PartnerContentStyled = styled.div`
  flex: 1;
  margin-right: 1rem;
  width: 100%;

  ${() =>
    useForSmallContainer(`
    margin: 1.2rem 1.2rem 0;
  `)}
`;

export const PartnerContentOfferStyled = styled.div`
  margin-bottom: 1.1rem;
  line-height: 1.5rem;

  ${TitleStyled} {
    line-height: 2rem;
  }
`;

export const PartnerAboutSectionStyled = styled.section`
  overflow: hidden;
  border-radius: 0.8rem;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

  color: ${({ theme }) => resolveColor(theme, theme.layout.color)};
`;

export const PartnerBannerImageStyled = styled.img`
  width: 100%;
`;

export const PartnerAboutSectionContentStyled = styled.div`
  padding: 1rem;

  color: ${({ theme }) => resolveColor(theme, theme.layout.color)};

  p {
    margin: 0.8rem 0 0;
    line-height: 1.5rem;
  }
`;

export const PartnerBodyWrapperStyled = styled(BodyWrapperStyled)`
  align-items: start;

  ${() => useForSmallContainer(`align-items: center;`)}
`;

export const PartnerAvatarStyled = styled.div`
  display: flex;
  align-items: center;

  > *:first-child {
    margin-right: 0.8rem;
  }
`;

export const PartnerBusinessNameStyled = styled.span`
  font-size: 1rem;
  font-weight: bold;
`;

export const PartnerContentParagraphStyled = styled(ParagraphStyled)`
  ${LinkBlendedInParagraphStyled}
`;

export const FriendFormStyled = styled(GlobalFormStyled)`
  border: none;
  padding: 15px;
  margin: 0;
  width: 100%;
`;
