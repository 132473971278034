import React, { FC } from "react";
import { BasicButtonContent } from "types/graphql";

import VisuallyHidden from "components/visually-hidden";
import Button from "components/button";
import { CallToActionsStyled } from "./CallToActionButtonList.styled";

type CallToActionButtonListProps = {
  appearance: "primary" | "secondary";
  buttons: Array<BasicButtonContent>;
  handleCallToActionClick: (url: string, actionId?: string) => void;
};

export const CallToActionButtonList: FC<CallToActionButtonListProps> = ({
  buttons,
  appearance,
  handleCallToActionClick,
}) => {
  return (
    <CallToActionsStyled>
      {buttons.map((button, index) => (
        <Button
          key={`cta_${index}`}
          handleOnClick={(): void => handleCallToActionClick(button.url)}
          appearance={appearance}
        >
          {button.text}
          <VisuallyHidden>Opens in a new tab</VisuallyHidden>
        </Button>
      ))}
    </CallToActionsStyled>
  );
};
