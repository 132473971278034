import React, { FC, PropsWithChildren, useState } from "react";
import { Context } from "./index";
import {
  InfoBoxStyled,
  InfoBoxContainerStyled,
  InfoBoxStatStyled,
  InfoBoxHeadingStyled,
} from "./Referrals.styled";
import CollapsibleCard from "components/collapsible-card/CollapsibleCard";

const YourReferrals: FC<PropsWithChildren<{ context: Context }>> = ({ context }) => {
  const content = context.step.content.referralStats;
  const config = context.session.config?.referralStats;
  const stepConfig = context.step.config;

  type StatsKey = keyof typeof content;
  type ConfigKey = keyof typeof config;

  const title = context.step.content.referralStats.title;

  const [isOpen, setIsOpen] = useState(true);

  if (!stepConfig.showReferralStats || !config) return null;

  return (
    <CollapsibleCard
      isOpen={isOpen}
      title={title}
      onHeaderClick={(): void => setIsOpen(!isOpen)}
      collapsible={false}
    >
      <InfoBoxContainerStyled>
        {stepConfig.referralStats.map((stat) => {
          const statName = content[stat as StatsKey];
          const statCount = config[stat as ConfigKey];

          return typeof statName === "string" && typeof statCount === "number" ? (
            <InfoBoxStyled key={stat}>
              <InfoBoxHeadingStyled>{statName}</InfoBoxHeadingStyled>
              <InfoBoxStatStyled>{statCount}</InfoBoxStatStyled>
            </InfoBoxStyled>
          ) : null;
        })}
      </InfoBoxContainerStyled>
    </CollapsibleCard>
  );
};

export default YourReferrals;
