import { Layout, LayoutStyle, Modifier } from "lib/Lego";
import { Context } from "lib/Lego/FriendJourney/ReferralLimitReached";

const defaultLayout = {
  containerStyle: LayoutStyle.Flush,
  bricks: [
    {
      name: "Column",
      modifiers: {
        largeContainer: [Modifier.WidthSmall],
      },
      bricks: [
        {
          name: "Heading",
        },
        {
          name: "Body",
        },
      ],
    },
  ],
};

export default (layout: string): Layout<Context> => {
  switch (layout) {
    default:
      return defaultLayout;
  }
};
