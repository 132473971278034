import React, { FC } from "react";
import {
  WrapperStyled,
  TitleWrapper,
  StyledTableHeader,
  StyledTable,
  Title,
  StyledHeaderText,
} from "./Table.styled";
import Item from "./Row";
import { ReferrerDashboardActiveStepConfigReferralStatusReferral } from "types/graphql";
import Tooltip from "components/Tooltip";

type Props = {
  items: ReferrerDashboardActiveStepConfigReferralStatusReferral[];
  title: string;
  headers: HeaderContent;
  type: string;
  tooltipMessage: string;
  statuses: { pending: string; complete: string };
};

interface HeaderContent {
  name: string;
  date: string;
  status: string;
}

const Table: FC<Props> = ({ items, tooltipMessage, headers, title, type, statuses }) => {
  const statusTexts: { [key: string]: string } = {
    pending: statuses.pending,
    complete: statuses.complete,
  };

  return (
    <WrapperStyled>
      <TitleWrapper>
        <Tooltip type={`${type}_referral`} message={tooltipMessage}>
          <Title type={`${type}_referral`}>{title}</Title>
        </Tooltip>
      </TitleWrapper>
      <StyledTable>
        <StyledTableHeader>
          <tr>
            <StyledHeaderText>{headers.name}</StyledHeaderText>
            <StyledHeaderText>{headers.date}</StyledHeaderText>
            <StyledHeaderText>{headers.status}</StyledHeaderText>
          </tr>
        </StyledTableHeader>
        <tbody>
          {items.map((item, i) => (
            <tr key={i}>
              <Item type={`${type}_referral`} item={item} statusText={statusTexts[type]} />
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </WrapperStyled>
  );
};

export default Table;
