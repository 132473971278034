import { resolveColor } from "styles/helpers";
import styled from "styled-components";
import {
  LinkBlendedInParagraphStyled,
  TitleStyled as DefaultTitleStyled,
} from "../../../styles/global.styled";

export const WrapperStyled = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  margin: 0 15px;
  padding-bottom: 30px;
`;

export const ContentStyled = styled.div`
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const HeadingStyled = styled(DefaultTitleStyled)`
  ${LinkBlendedInParagraphStyled}
`;

export const BodyCopyStyled = styled.div`
  margin: 0 0 30px;

  p {
    color: ${({ theme }) => resolveColor(theme, theme.layout.color)};
  }

  ${LinkBlendedInParagraphStyled}
`;
