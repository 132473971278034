import React, { FC, PropsWithChildren } from "react";

import Form from "components/form";
import {
  NewFormStyled,
  NewFormCaptionStyled,
  NewFormElementStyled,
} from "components/form/Form.styled";
import Switch from "components/switch";
import Button from "components/button";

import { Context } from ".";

const form: FC<PropsWithChildren<{ context: Context }>> = ({ context, children }) => {
  if (!context.step.optionalContent || !context.step.optionalConfig) {
    return null;
  }

  const inputConfig = context.step.optionalConfig.inputs;
  const inputContent = context.step.optionalContent.form.inputs;

  return (
    <Form
      handleOnSubmit={context.methods.handleReferrerRegistration}
      formCaption={context.step.optionalContent.form.caption}
      overrideFormStyled={NewFormStyled}
      overrideFormCaptionStyled={NewFormCaptionStyled}
      overrideFormElementStyled={NewFormElementStyled}
    >
      {inputConfig.marketingOptIn.show && (
        <Switch
          label={inputContent.marketingOptIn.label}
          name="marketingOptIn"
          required={inputConfig.marketingOptIn.required}
          errorMessage={
            (inputContent.marketingOptIn.validationErrors || []).find((f) => f.key === "default")
              ?.value || ""
          }
          spaceBetween
        />
      )}
      {inputConfig.termsOptIn.show && (
        <Switch
          label={inputContent.termsOptIn.label}
          name="termsOptIn"
          required
          errorMessage={
            (inputContent.termsOptIn.validationErrors || []).find((f) => f.key === "default")
              ?.value || ""
          }
          spaceBetween
        />
      )}
      <Button appearance="primary" buttonType="submit" isLoading={context.state.loading}>
        {context.step.optionalContent.form.button.text}
      </Button>

      {children}
    </Form>
  );
};

export default form;
