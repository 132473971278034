import React, { FC } from "react";
import {
  WrapperStyled,
  TitleWrapper,
  ConfirmReferalTitle,
  ItemWrapper,
  IndividualWrapper,
} from "./StatusList.styled.";
import Item from "./Item";
import { ReferrerDashboardActiveStepConfigReferralStatusReferral } from "types/graphql";

type Props = {
  body: ReferrerDashboardActiveStepConfigReferralStatusReferral[];
  title: string;
  type: string;
  purchaseTitle: string;
  statuses: { pending: string; complete: string };
};

const StatusList: FC<Props> = ({ body, purchaseTitle, title, type, statuses }) => {
  const statusTexts: { [key: string]: string } = {
    pending: statuses.pending,
    complete: statuses.complete,
  };

  return (
    <WrapperStyled>
      <TitleWrapper>
        <ConfirmReferalTitle type={`${type}_referral`}>
          <span>{title}</span>
        </ConfirmReferalTitle>
      </TitleWrapper>
      <ItemWrapper>
        {body &&
          body.map((item, i) => {
            return (
              <IndividualWrapper key={i}>
                <Item
                  type={`${type}_referral`}
                  purchaseTitle={purchaseTitle}
                  item={item}
                  statusText={statusTexts[type]}
                />
              </IndividualWrapper>
            );
          })}
      </ItemWrapper>
    </WrapperStyled>
  );
};

export default StatusList;
