import React, { FC, JSXElementConstructor, ReactElement, useContext } from "react";
import { FieldError, useFormContext } from "react-hook-form";

import renderTemplate, { renderString } from "lib/Template";

import { DefaultContext } from "types/StepContext.types";
import { StepContext } from "context/index";

import Button from "components/button";
import { FormStyled, FormCaptionStyled, FormElementStyled } from "./Form.styled";
import Switch from "components/switch";
import Input from "components/input";
import Dropdown from "components/dropdown";

import DateEditor from "../DateEditor";
import MarkdownContent from "../MarkdownContent";

type Props = {
  children: any;
  handleOnSubmit?: any;
  formCaption?: string;

  overrideFormStyled?: React.ElementType;
  overrideFormCaptionStyled?: React.ElementType;
  overrideFormElementStyled?: React.ElementType;

  canSubmit?: boolean;

  useMarkdown?: boolean;
};

const Form: FC<Props> = ({
  children,
  handleOnSubmit,
  formCaption,
  overrideFormStyled,
  overrideFormCaptionStyled,
  overrideFormElementStyled,
  canSubmit = true,
  useMarkdown = false,
}) => {
  const { sessionContentReplacements } = useContext<DefaultContext>(StepContext);

  const methods = useFormContext();

  const { register, handleSubmit, control, formState } = methods;
  const { errors, isValid } = formState;

  const FormStyledToUse = overrideFormStyled || FormStyled;
  const FormCaptionStyledToUse = overrideFormCaptionStyled || FormCaptionStyled;
  const FormElementStyledToUse = overrideFormElementStyled || FormElementStyled;

  const getErrorMessageForField = (name: string, errors: any): FieldError => {
    const converted = name.replace(/\[([^\]]+)\]/g, ".$1");
    const properties = converted.split(".");
    const result = properties.reduce((prev, curr) => prev && prev[curr], errors);
    return result;
  };

  const isFormField = (componentType: string | JSXElementConstructor<any>): boolean => {
    if (componentType === Switch) return true;
    if (componentType === Input) return true;
    if (componentType === DateEditor) return true;
    if (componentType === Dropdown) return true;

    return false;
  };

  const renderFormElement = (child: ReactElement): ReactElement => {
    if (isFormField(child.type)) {
      return React.createElement(child.type, {
        ...{
          ...child.props,
          register: register,
          control: control,
          key: child.props.name,
          showError: child.props.showError || !!getErrorMessageForField(child.props.name, errors),
          errorMessage:
            getErrorMessageForField(child.props.name, errors)?.message || child.props.errorMessage,
        },
      });
    } else if (child.type === Button) {
      return React.createElement(child.type, {
        ...{
          ...child.props,
          disabled: !canSubmit || !isValid,
        },
      });
    } else {
      return child;
    }
  };

  const submitForm = (data: any) => {
    handleSubmit(handleOnSubmit)(data);
  };

  return (
    <FormStyledToUse noValidate onSubmit={submitForm}>
      {formCaption && (
        <FormCaptionStyledToUse>
          {useMarkdown ? (
            <MarkdownContent>
              {renderString(formCaption, sessionContentReplacements)}
            </MarkdownContent>
          ) : (
            renderTemplate(formCaption, sessionContentReplacements)
          )}
        </FormCaptionStyledToUse>
      )}
      {React.Children.map(children, (m) => m)
        .flat()
        .filter((f: any) => f)
        .map((child: ReactElement, childItem: number) => {
          return (
            child.props && (
              <FormElementStyledToUse key={childItem}>
                {renderFormElement(child)}
              </FormElementStyledToUse>
            )
          );
        })}
    </FormStyledToUse>
  );
};

export default Form;
