import styled from "styled-components";
import { transparentize } from "polished";

import { resolveColor, resolveWithDefault } from "styles/helpers";
import { useForSmallContainer, useForLargeContainer } from "hooks/ResponsiveStyle";

export const ImageTilesContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;

  gap: 0.8rem;

  ${() =>
    useForSmallContainer(`
    flex-wrap: none;
    width: 100%;
  `)}

  ${() =>
    useForLargeContainer(`
    justify-content: center;
  `)}
`;

export const ImageTileContainerStyled = styled.div`
  border-radius: 20px;
  border: 1px solid
    ${({ theme }): string =>
      resolveWithDefault(
        resolveColor(theme, theme.form.borderColor),
        transparentize(0.8, resolveColor(theme, theme.layout.color)),
      )};
  flex: 0 0 calc(50% - 0.8rem);

  ${() =>
    useForSmallContainer(`
    flex: 1 1 calc(50% - 0.8rem);
  `)}

  overflow: hidden;

  display: flex;
  flex-direction: column;
  min-width: 255px;
`;

export const ImageTileContentStyled = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;

  padding: 15px 20px 20px;

  color: ${({ theme }) => theme.layout.color};

  p {
    margin-top: 0;
    font-size: 0.9rem;
    flex: 1;
  }

  > *:last-child {
    margin-top: auto;
  }
`;

export const ImageTileImageStyled = styled.img`
  width: 100%;
`;

export const ImageTileTitleStyled = styled.h5`
  font-size: 1rem;
  margin: 0 0 0.8rem 0;
`;

export const ImageTilePagerContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem;
`;
