import { StepConfig } from "types/StepContext.types";
import RewardContent from "./RewardContent";

export type Context = {
  step: {};
  session?: StepConfig;
  methods: {};
  state: {
    sessionContentReplacements: any;
  };
  optionalStep: boolean;
};

export const BrickTypes: { [index: string]: React.FC<{ context: Context }> } = {
  RewardContent: RewardContent as React.FC<{ context: Context }>,
};
