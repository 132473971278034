import { setSvgCssFromAttributes } from "styles/helpers";
import styled from "styled-components";

export const SvgWrapperStyled = styled.div`
  ${({ children }) => setSvgCssFromAttributes(children)}

  svg {
    width: 100%;
    height: 100%;
  }
`;
