import React, { FC, PropsWithChildren } from "react";

import { IframeStyled } from "./RewardContent.styled";

import { Context } from ".";

const RewardContent: FC<PropsWithChildren<{ context: Context }>> = ({ context }) => {
  if (!context.session?.config) {
    return null;
  }

  return <IframeStyled src={context.session.config.destinationUrl} allow="clipboard-write" />;
};

export default RewardContent;
