import { StepConfig } from "types/StepContext.types";
import { CaptureFriendDetailsStep } from "types/graphql";
import Offer from "./Offer";
import Legal from "./Legal";
import Form from "./Form";
import SubmissionError from "./SubmissionError";
import FormLegal from "./FormLegal";

export type FormData = {
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  marketingOptIn: boolean;
  termsOptIn: boolean;
  customFields: { [id: string]: string };
};

export type Context = {
  step: CaptureFriendDetailsStep;
  session?: StepConfig;
  methods: {
    handleFriendRegistration: (formData: FormData) => void;
  };
  state: {
    sessionContentReplacements: any;
    registrationError: boolean;
    loading: boolean;
    isPartnerAudience: boolean;
  };
  optionalStep: boolean;
};

export const BrickTypes: { [index: string]: React.FC<{ context: Context }> } = {
  Offer: Offer as React.FC<{ context: Context }>,
  Legal: Legal as React.FC<{ context: Context }>,
  Form: Form,
  SubmissionError: SubmissionError,
  FormLegal: FormLegal,
};
