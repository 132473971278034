import styled, { DefaultTheme } from "styled-components";
import { transparentize } from "polished";
import { resolveColor, resolveFontSize, resolveWithDefault } from "styles/helpers";
import { useForLargeContainer, useForSmallContainer } from "hooks/ResponsiveStyle";
import { LinkBlendedInParagraphStyled, ParagraphStyled } from "styles/global.styled";
import { InputStyled } from "../input/Input.styled";
import { InputStyled as DateInputStyled } from "components/DateEditor/DateEditor.styled";
import { DropdownStyled } from "../dropdown/Dropdown.styled";

const marginRight = "20px";
const marginLeft = "20px";

export const FormStyled = styled.form`
  padding: 15px;
  margin: 20px ${marginRight} 0 ${marginLeft};
  width: calc(100% - (${marginRight} + ${marginLeft}));

  ${() =>
    useForLargeContainer(`
      padding: 0;
      margin: 0;
      width: 100%;
    `)}
`;

const formCaptionColor = (theme: DefaultTheme) =>
  resolveWithDefault(
    resolveColor(theme, theme.form.captionColor),
    resolveColor(theme, theme.layout.color),
  );

const formBorderColor = (theme: DefaultTheme) =>
  resolveWithDefault(
    resolveColor(theme, theme.form.borderColor),
    transparentize(0.8, resolveColor(theme, theme.layout.color)),
  );

export const FormCaptionStyled = styled(ParagraphStyled)`
  color: ${({ theme }) => formCaptionColor(theme)};
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 15px;
  ${({ theme }) => useForLargeContainer(`font-size: ${resolveFontSize(theme, "medium")};`)}
  ${({ theme }) => useForSmallContainer(`font-size: ${resolveFontSize(theme, "small")};`)}

  ${LinkBlendedInParagraphStyled}
`;

export const FormElementStyled = styled.fieldset`
  margin: 0 0 10px;
  padding: 0;
  border: 0;

  &:last-of-type {
    margin: 0;
  }
`;

export const NewFormStyled = styled.form`
  border: 1px solid ${({ theme }): string => formBorderColor(theme)};
  background-color: ${({ theme }): string =>
    resolveWithDefault(theme.form.backgroundColor, theme.layout.backgroundColor)};
  border-radius: 8px;
  padding: 15px;
  margin: 0;
  width: 100%;

  display: grid;
  grid-gap: 1rem;
  grid-template-rows: min-content 1fr;
`;

export const V1FormStyle = styled(NewFormStyled)`
  border: 0;
  padding: 1rem;
  width: auto;
  border-radius: 0;
  min-height: 100%;

  display: flex;
  flex-direction: column;

  ${InputStyled}, ${DateInputStyled}, ${DropdownStyled} {
    height: 2.3125rem;
    //padding: 0.5rem;
    border-radius: 0;

    background-color: ${({ theme }) => theme.textInput.backgroundColor};

    border: 1px solid ${({ theme }): string => formBorderColor(theme)};
  }

  .prefix {
    svg {
      color: ${({ theme }): string =>
        resolveWithDefault(theme.colors.highlight, theme.layout.color)};
    }
  }
`;

export const V1FormCaptionStyled = styled.div`
  &,
  & p {
    color: ${({ theme }) => formCaptionColor(theme)};
    margin: 0;
    ${({ theme }) => useForLargeContainer(`font-size: ${resolveFontSize(theme, "medium")};`)}
    ${({ theme }) => useForSmallContainer(`font-size: ${resolveFontSize(theme, "small")};`)}
  }
`;

export const NewFormCaptionStyled = styled(ParagraphStyled)`
  color: ${({ theme }) => formCaptionColor(theme)};
  font-weight: bold;
  text-align: left;
  margin: 0;
  ${({ theme }) => useForLargeContainer(`font-size: ${resolveFontSize(theme, "medium")};`)}
  ${({ theme }) => useForSmallContainer(`font-size: ${resolveFontSize(theme, "small")};`)}
`;

export const NewFormElementStyled = styled.fieldset`
  margin: 0 0;
  padding: 0;
  border: 0;
  text-align: left;

  &:empty {
    display: none;
  }
`;
