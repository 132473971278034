import Svg from "components/svg/Svg";
import React, { FC, PropsWithChildren } from "react";
import { IconType } from "react-icons";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  CardStyled,
  TitleWrapperStyled,
  TitleStyled,
  CardContentStyled,
  ClickableTitleStyled,
} from "./CollapsibleCard.styled";

const CollapsibleCard: FC<
  PropsWithChildren<{
    title: string;
    isOpen?: boolean;
    icon?: IconType;
    onHeaderClick?: () => void;
    collapsible?: boolean;
    scrollable?: boolean;
    additionalContent?: JSX.Element;
    expandVertically?: boolean;
  }>
> = ({
  children,
  title,
  isOpen = true,
  onHeaderClick,
  icon,
  collapsible = true,
  scrollable = true,
  additionalContent = undefined,
  expandVertically = false,
}) => {
  const contentId = `bp-${title.replace(/[^\w]/g, "")}-content`;

  const accessibleIcon = icon ? <Svg svgImage={icon} aria-hidden={true} /> : null;

  return (
    <CardStyled isOpen={!collapsible || isOpen} expandVertically={expandVertically}>
      {collapsible ? (
        <ClickableTitleStyled
          onClick={onHeaderClick}
          collapsible={collapsible}
          isOpen={!collapsible || isOpen}
          aria-controls={contentId}
          aria-expanded={isOpen}
        >
          {accessibleIcon}
          <TitleStyled>{title}</TitleStyled>
          {additionalContent}
          {collapsible ? (
            isOpen ? (
              <Svg svgImage={IoIosArrowUp} aria-hidden="true" />
            ) : (
              <Svg svgImage={IoIosArrowDown} aria-hidden="true" />
            )
          ) : null}
        </ClickableTitleStyled>
      ) : (
        <TitleWrapperStyled>
          {accessibleIcon}
          <TitleStyled>{title}</TitleStyled>
          {additionalContent}
        </TitleWrapperStyled>
      )}
      <CardContentStyled
        id={contentId}
        collapsible={collapsible}
        scrollable={scrollable}
        {...(collapsible && !isOpen ? { "aria-hidden": true } : {})}
      >
        {children}
      </CardContentStyled>
    </CardStyled>
  );
};

export default CollapsibleCard;
