import { StepConfig } from "types/StepContext.types";
import { SuccessfulFriendRegistrationStep } from "types/graphql";
import Heading from "./Heading";
import Body from "./Body";
import { Device } from "hooks/Device";

export type FormData = {
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  marketingOptIn: boolean;
  termsOptIn: boolean;
  customFields: { [id: string]: string };
};

export type Context = {
  step: SuccessfulFriendRegistrationStep;
  session?: StepConfig;
  methods: {
    handleAddFriendReferral: () => void;
    handleContinueToClientSite: (url: string) => void;
  };
  state: {
    device: Device;
    sessionContentReplacements: any;
    loading: boolean;
    error: boolean;
  };
  optionalStep: boolean;
};

export const BrickTypes: { [index: string]: React.FC<{ context: Context }> } = {
  Body: Body as React.FC<{ context: Context }>,
  Heading: Heading as React.FC<{ context: Context }>,
};
