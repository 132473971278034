import React, { useContext } from "react";

import styled from "styled-components";

import { Row, Column, V1Row } from "./Basic";
import ModifierWrapper, { getModifiersToRender, Modifiers } from "./Modifiers";

import { ContainerSize, useDevice } from "hooks/Device";
import { useForLargeContainer } from "hooks/ResponsiveStyle";
import { DefaultContext } from "types/StepContext.types.ts";
import { StepContext } from "context";

export { Modifier } from "./Modifiers";

export enum LayoutStyle {
  Dashboard,
  Regular,
  Flush,
  Wide,
  V1,
  Column,
}

export type LayoutStyleComposite<TContext> = LayoutStyle | ((context: TContext) => LayoutStyle);
export type LayoutStyleType<TContext> =
  | LayoutStyleComposite<TContext>
  | {
      smallContainer: LayoutStyleComposite<TContext>;
      largeContainer: LayoutStyleComposite<TContext>;
    };

export type Layout<TContext> = {
  containerStyle: LayoutStyleType<TContext>;
  bricks: BrickProps<TContext>[];
};

type Props<TContext> = {
  context: TContext;

  brickTypes: { [index: string]: React.FC<any> };
  layout: Layout<TContext>;
  isPreview: boolean;
};

type BrickProps<TContext> = {
  name: string;
  modifiers?: Modifiers<TContext>;
  bricks?: BrickProps<TContext>[];
};

type MainBrickProps<TContext> = {
  name: string;
  modifiers?: Modifiers<TContext>;
  bricks?: BrickProps<TContext>[];
  isPreview: boolean;

  context: TContext;
  brickTypes: { [index: string]: React.FC<any> };
};

const LegoStyled = styled.div`
  width: 100%;
  max-width: 750px;
  ${() => useForLargeContainer(`padding: 1rem;`)}
  padding-top: 3rem;
`;

const LegoColumnStyled = styled(LegoStyled)`
  width: auto;
`;

const LegoFlushStyled = styled.div`
  max-width: 750px;
`;

const LegoWideStyled = styled.div`
  max-width: 1075px;
  padding-top: 3rem;
`;

const LegoDashboardStyled = styled.div`
  max-width: 1150px;
  width: 100%;
  padding-top: 3rem;
`;

const LegoV1Styled = styled.div`
  margin: 0 1rem;
  max-width: 786px;
  padding-top: 3rem;
`;

const basicBrickTypes: { [index: string]: React.FC<any> } = {
  Row,
  Column,
  V1Row,
};

function Brick<TContext>({
  name,
  context,
  modifiers,
  brickTypes,
  bricks,
  isPreview,
}: MainBrickProps<TContext>): JSX.Element {
  const brickType: React.FC<any> | undefined = brickTypes[name] || basicBrickTypes[name];

  if (!brickType) {
    throw new Error(`Brick type '${name}' not found`);
  }

  let children: React.ReactNode[] = [];
  if (bricks) {
    children = bricks.map((props, index) => {
      const mainProps = props as MainBrickProps<TContext>;
      mainProps.context = context;
      mainProps.brickTypes = brickTypes;
      mainProps.isPreview = isPreview;

      return <Brick<TContext> key={index} {...mainProps} />;
    });
  }

  const device = useDevice();
  const modifiersToRender = getModifiersToRender(modifiers, device.containerSize, context);
  const element = React.createElement(
    brickType,
    { context: context, modifiers: modifiersToRender, isPreview: isPreview },
    children,
  );

  return (
    <ModifierWrapper modifiers={modifiers} containerSize={device.containerSize} context={context}>
      {element}
    </ModifierWrapper>
  );
}

const getContainerStyle = <TContext,>(
  containerStyle: LayoutStyleType<TContext>,
  containerSize: ContainerSize,
  context: TContext,
) => {
  let relevantSize: any = LayoutStyle.Regular;

  const styleTypeChecker = containerStyle as { [key: string]: any };

  if (styleTypeChecker.smallContainer && containerSize === ContainerSize.SmallContainer) {
    relevantSize = styleTypeChecker.smallContainer;
  } else if (styleTypeChecker.largeContainer && containerSize === ContainerSize.LargeContainer) {
    relevantSize = styleTypeChecker.largeContainer;
  } else {
    relevantSize = styleTypeChecker as unknown as LayoutStyle;
  }

  return typeof relevantSize === "function" ? relevantSize(context) : relevantSize;
};

export function Lego<TContext>({
  context,
  brickTypes,
  layout,
  isPreview,
}: Props<TContext>): JSX.Element {
  const device = useDevice();
  const { callbacks } = useContext<DefaultContext>(StepContext);

  let Container = null;

  switch (getContainerStyle(layout.containerStyle, device.containerSize, context)) {
    case LayoutStyle.Wide:
      Container = LegoWideStyled;
      break;
    case LayoutStyle.Dashboard:
      Container = LegoDashboardStyled;
      break;
    case LayoutStyle.Flush:
      Container = LegoFlushStyled;
      break;
    case LayoutStyle.V1:
      Container = LegoV1Styled;
      break;
    case LayoutStyle.Column:
      Container = LegoColumnStyled;
      break;
    default:
      Container = LegoStyled;
  }

  if (callbacks && callbacks.onLayoutLoaded) callbacks.onLayoutLoaded();

  return (
    <Container>
      {layout.bricks.map((props, index) => {
        const mainProps = props as MainBrickProps<TContext>;
        mainProps.context = context;
        mainProps.brickTypes = brickTypes;
        mainProps.modifiers = props.modifiers;
        mainProps.isPreview = isPreview;

        return <Brick<TContext> key={index} {...mainProps} />;
      })}
    </Container>
  );
}
