import React, { FC, useContext, useState } from "react";

import GoogleMailEmailButton from "components/email-button/google-mail";
import YahooEmailButton from "components/email-button/yahoo";
import LiveEmailButton from "components/email-button/live";
import DesktopEmailButton from "components/email-button/desktop";

import {
  EmailSharingToolsStyled,
  ToolsGridStyled,
  MessageEntryStyled,
} from "./EmailSharingTools.styled";

import { EventEnum } from "types/graphql";

import useRecordEvent from "hooks/RecordEvent";
import { DefaultContext } from "types/StepContext.types";
import { StepContext } from "context/index";

type Props = {
  subject: string;
  message: string;
  shareUrl: string;
};

const EmailSharingTools: FC<Props> = ({ subject, message, shareUrl }) => {
  const [personalisedMessage, setPersonalisedMessage] = useState(message);
  const recordEvent = useRecordEvent();
  const { localisation } = useContext<DefaultContext>(StepContext);

  const buttonProps = {
    subject: subject,
    message: personalisedMessage + "\n\n" + shareUrl,
  };

  const onPopupOpen = (): void => {
    recordEvent(EventEnum.Share, { share_network: "Email" });
  };

  return (
    <EmailSharingToolsStyled>
      <MessageEntryStyled>
        <span>{localisation.sharing.email.messageLabelText}</span>
        <textarea
          value={personalisedMessage}
          onChange={(e): void => setPersonalisedMessage(e.target.value)}
        />
      </MessageEntryStyled>

      <ToolsGridStyled>
        <GoogleMailEmailButton {...buttonProps} onPopupOpen={onPopupOpen} />
        <YahooEmailButton {...buttonProps} onPopupOpen={onPopupOpen} />
        <LiveEmailButton {...buttonProps} onPopupOpen={onPopupOpen} />
        <DesktopEmailButton {...buttonProps} onPopupOpen={onPopupOpen} />
      </ToolsGridStyled>
    </EmailSharingToolsStyled>
  );
};
export default EmailSharingTools;
