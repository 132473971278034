import styled, { DefaultTheme } from "styled-components";
import { transparentize } from "polished";
import { resolveColor, resolveFontSize, resolveWithDefault } from "styles/helpers";
import { TargetTooltipWrapperStyled } from "components/TargetTooltip/TargetTooltip.styled";

type InputProps = {
  showError: boolean;
};

export const textInputColor = (theme: DefaultTheme) =>
  resolveWithDefault(
    resolveColor(theme, theme.textInput.color),
    resolveColor(theme, theme.layout.color),
  );

export const textInputBackgroundColor = (theme: DefaultTheme) =>
  transparentize(
    0.9,
    resolveWithDefault(
      resolveColor(theme, theme.textInput.backgroundColor),
      resolveColor(theme, theme.layout.color),
    ),
  );

export const textInputLabelColor = (theme: DefaultTheme) =>
  resolveWithDefault(
    resolveColor(theme, theme.textInput.label.color),
    resolveColor(theme, theme.layout.color),
  );

export const InputStyled = styled.input<InputProps>`
  font-family: inherit;
  height: ${({ theme }) => theme.textInput.height};
  font-size: ${({ theme }) => resolveFontSize(theme, theme.textInput.fontSize)};
  border-radius: ${({ theme }) => theme.textInput.borderRadius};
  background-color: ${({ theme }) => textInputBackgroundColor(theme)};
  outline: 0;
  margin: 0;
  border: 0;
  padding: 0 1rem;

  width: 100%;
  box-sizing: border-box;
  color: ${({ theme }) => textInputColor(theme)};
  box-shadow: ${({ showError, theme }) =>
      showError ? `0 0 0 2px ${resolveColor(theme, theme.textInput.error.color)}` : 0}
    inset;

  &:focus {
    box-shadow: ${({ showError, theme }) =>
        showError
          ? `0 0 0 2px ${resolveColor(theme, theme.textInput.error.color)}`
          : `0 0 0 2px ${resolveColor(theme, theme.textInput._focus.borderColor)}`}
      inset;
  }

  &::placeholder {
    color: ${({ theme }) => transparentize(0.3, textInputColor(theme))};
  }

  ${({ theme }) => theme.textInput.css}
`;

export const InputLabelStyled = styled.label`
  position: relative;
  display: block;

  color: ${({ theme }) => textInputLabelColor(theme)};

  ${TargetTooltipWrapperStyled} {
    position: absolute;
    right: 1.2rem;
    top: 50%;
    width: 1.5rem;
    height: 1.5rem;

    transform: translateY(-50%);
  }
`;

export const InputSpanStyled = styled.span`
  font-size: ${({ theme }) => resolveFontSize(theme, theme.textInput.label.fontSize)};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
  display: block;
`;

export const InputLabelContainerStyled = styled.div`
  display: flex;
  margin-bottom: 10px;

  ${InputSpanStyled} {
    flex: 1;
  }

  ${TargetTooltipWrapperStyled} {
    flex: 0 0 1.3rem;
    position: unset;
    width: 1.2rem;
    height: 1.2rem;
    transform: unset;
  }
`;

export const InputErrorWrapper = styled.div`
  padding-top: 3px;
`;

export const InputErrorStyled = styled.div`
  font-size: ${({ theme }) => resolveFontSize(theme, "extra_small")};
  color: ${({ theme }) => resolveColor(theme, theme.textInput.error.color)};
  font-weight: bold;
`;

export const DateContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.6rem;

  > input:not(:last-child) {
    flex: 1;
  }

  > input:last-child {
    flex: 2;
  }
`;
