import styled from "styled-components";
import { transparentize } from "polished";
import { resolveColor, resolveFontSize, resolveWithDefault } from "styles/helpers";
import { LinkBlendedInParagraphStyled, LinkStyle } from "styles/global.styled";

type StyledSwitchProps = {
  spaceBetween?: boolean;
};

export const SwitchGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const SwitchLabel = styled.label<StyledSwitchProps>`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: ${(props) => props.spaceBetween && "space-between"};

  &:hover {
    cursor: pointer;
  }

  &:focus {
    box-shadow: 0 0 0 2px blue inset;
  }

  ${LinkBlendedInParagraphStyled}
`;

export const SwitchSpan = styled.span`
  user-select: none;
  margin-right: 10px;
  font-size: ${({ theme }) => resolveFontSize(theme, theme.switch.label.fontSize)};
  color: ${({ theme }) =>
    resolveWithDefault(
      resolveColor(theme, theme.switch.label.color),
      resolveColor(theme, theme.layout.color),
    )};
  font-weight: ${({ theme }) => theme.switch.label.fontWeight};

  a {
    ${LinkStyle}

    font-size: inherit;
  }
`;

export const SwitchStyled = styled.input`
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:checked + span {
    box-shadow: 0 0 0 1px
      ${({ theme }) =>
        transparentize(0.5, resolveColor(theme, theme.switch._active.backgroundColor || ""))};
    background-color: ${({ theme }) =>
      transparentize(0.7, resolveColor(theme, theme.switch._active.backgroundColor || ""))};

    &::after {
      right: 0;
      background-color: ${({ theme }) =>
        resolveColor(theme, theme.switch._active.backgroundColor || "")};
    }
  }

  &:focus + span {
    outline: 5px auto
      ${({ theme }) =>
        resolveColor(theme, theme.switch._active.backgroundColor || "rgba(0, 150, 255, 1)")};
    -webkit-outline: 5px auto
      ${({ theme }) =>
        resolveColor(theme, theme.switch._active.backgroundColor || "rgba(0, 150, 255, 1)")};
    -moz-outline: 5px auto
      ${({ theme }) =>
        resolveColor(theme, theme.switch._active.backgroundColor || "rgba(0, 150, 255, 1)")};
    -ms-outline: 5px auto
      ${({ theme }) =>
        resolveColor(theme, theme.switch._active.backgroundColor || "rgba(0, 150, 255, 1)")};
    -o-outline: 5px auto
      ${({ theme }) =>
        resolveColor(theme, theme.switch._active.backgroundColor || "rgba(0, 150, 255, 1)")};
  }
`;

export const SwitchWrapper = styled.span`
  box-shadow: 0 0 0 1px
    ${({ theme }) =>
      transparentize(
        0.8,
        resolveWithDefault(
          resolveColor(theme, theme.switch.backgroundColor),
          resolveColor(theme, theme.layout.color),
        ),
      )};
  background-color: ${({ theme }) =>
    transparentize(
      0.85,
      resolveWithDefault(
        resolveColor(theme, theme.switch.backgroundColor),
        resolveColor(theme, theme.layout.color),
      ),
    )};
  position: relative;
  height: 18px;
  width: 18px;
  border-radius: 14px;
  display: inline-flex;
  flex: 0 0 34px;

  &::after {
    content: "";
    position: absolute;
    height: 18px;
    width: 18px;
    background-color: ${({ theme }) =>
      transparentize(
        0.7,
        resolveWithDefault(
          resolveColor(theme, theme.switch.backgroundColor),
          resolveColor(theme, theme.layout.color),
        ),
      )};
    display: block;
    border-radius: 14px;
  }
`;

export const SwitchErrorWrapper = styled.div`
  padding-top: 3px;
`;

export const SwitchErrorStyled = styled.div`
  font-size: ${({ theme }) => resolveFontSize(theme, "extra_small")};
  color: ${({ theme }) => resolveColor(theme, theme.switch.error.color)};
  font-weight: bold;
`;
