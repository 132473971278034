import {
  SplashPageStep,
  CaptureReferrerDetailsStep,
  ReferrerDashboardStep,
  SplashPageStepContent,
  CaptureReferrerDetailsStepContent,
  ReferrerDashboardStepContent,
  SplashPageStepConfig,
  CaptureReferrerDetailsStepConfig,
  ReferrerDashboardStepConfig,
} from "types/graphql";
import { Device } from "hooks/Device";

type StepContext = {
  step: SplashPageStep | CaptureReferrerDetailsStep | ReferrerDashboardStep;
  state: {
    sessionContentReplacements: any;
    device: Device;
  };
  optionalStep?: boolean;
};

export type Context = {
  context: StepContext;
};

export const stepContent = (
  stepContext: StepContext,
):
  | SplashPageStepContent
  | CaptureReferrerDetailsStepContent
  | ReferrerDashboardStepContent
  | null
  | undefined => {
  let content;
  if (stepContext.optionalStep) {
    const step = stepContext.step as CaptureReferrerDetailsStep | SplashPageStep;
    content = step.optionalContent;
  } else {
    const step = stepContext.step as ReferrerDashboardStep;
    content = step.content;
  }

  return content;
};

export const stepConfig = (
  stepContext: StepContext,
):
  | SplashPageStepConfig
  | CaptureReferrerDetailsStepConfig
  | ReferrerDashboardStepConfig
  | null
  | undefined => {
  let config;
  if (stepContext.optionalStep) {
    const step = stepContext.step as CaptureReferrerDetailsStep | SplashPageStep;
    config = step.optionalConfig;
  } else {
    const step = stepContext.step as ReferrerDashboardStep;
    config = step.config;
  }
  return config;
};
