import styled from "styled-components";
import { transparentize } from "polished";
import { resolveColor, resolveFontSize } from "styles/helpers";

type Props = {
  type: string;
};

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h5<Props>`
  font-weight: 200;
  margin-bottom: 8px;
  margin-top: 0px;
  padding-top: 0.8rem;
  font-size: ${({ theme }) => theme.font.sizes["medium"]};
  color: ${({ theme }): string => theme.layout.color};
`;

export const TitleWrapper = styled.div`
  position: relative;
  margin-right: auto;
`;

export const NumericItemTitle = styled.h3`
  font-weight: 600;
  margin-top: 10px;
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
`;
export const StyledTableHeader = styled.thead`
  background-color: ${({ theme }) => transparentize(0.9, resolveColor(theme, theme.layout.color))};
`;

export const StyledHeaderText = styled.th`
  font-weight: 400;
  text-transform: uppercase;
  font-size: ${({ theme }): string => resolveFontSize(theme, "small")};
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
  padding-right: 0;
  padding-left: 0.4rem;
  color: ${({ theme }) => transparentize(0.4, resolveColor(theme, theme.layout.color))};
  font-weight: 500;
  text-align: start;
  &:last-child {
    text-align: right;
    padding-right: 0.4rem;
  }
  &:first-child {
    min-width: 5rem;
  }
`;
