import styled from "styled-components";

export const ActionButtonsStyled = styled.div`
  width: 100%;
  display: inline-flex;
  height: 50px;
  border-radius: ${({ theme }) => theme.tabSwitcher.borderRadius};
  margin-bottom: 0 !important;

  ${({ theme }) => theme.tabSwitcher.css}
`;
