import styled from "styled-components";
import {
  resolveColor,
  resolveFontSize,
  resolveWithDefault,
  setSvgCssFromAttributes,
} from "styles/helpers";

export const TextStyled = styled.span`
  flex: 1;
`;

export const PillStyled = styled.button`
  border: none;
  padding: 0.2rem 0.5rem 0.2rem 1rem;
  cursor: pointer;
  line-height: 1.2rem;
  display: inline-flex;
  align-items: center;
  border-radius: ${({ theme }): string => theme.pill.borderRadius};
  font-size: ${({ theme }): string => resolveFontSize(theme, theme.pill.fontSize)};
  color: ${({ theme }) =>
    resolveWithDefault(
      resolveColor(theme, theme.pill.color),
      resolveColor(theme, theme.layout.backgroundColor),
    )};
  background-color: ${({ theme }) =>
    resolveWithDefault(
      resolveColor(theme, theme.pill.backgroundColor),
      resolveColor(theme, theme.layout.color),
    )};
  font-weight: ${({ theme }): string => theme.pill.fontWeight};
  font-family: ${({ theme }): string =>
    resolveWithDefault(theme.pill.fontFamily, theme.font.family)};

  ${({ children }) => setSvgCssFromAttributes(children)}
  svg {
    margin-left: 4px;
    vertical-align: middle;
    font-size: 24px;
    fill: ${({ theme }) =>
      resolveWithDefault(
        resolveColor(theme, theme.pill.color),
        resolveColor(theme, theme.layout.backgroundColor),
      )};
  }
`;
