import styled from "styled-components";

import { LinkStyle as GlobalLinkStyle } from "styles/global.styled";
import { transparentize } from "polished";
import { setSvgCssFromAttributes } from "styles/helpers";

export const LinkListItemStyled = styled.li`
  list-style: none;
  margin: 0;
  padding: 0.75rem 0;
  border-bottom: solid 1px ${({ theme }) => transparentize(0.9, theme.layout.color)};
`;

export const LinkStyled = styled.a`
  ${GlobalLinkStyle}

  display: flex;
  flex-direction: row;
  align-items: center;

  text-align: left;

  span {
    flex: 1;
  }

  ${({ children }) => setSvgCssFromAttributes(children)}
`;

export const LinkListStyled = styled.ul`
  margin: 0;
  padding: 0;
`;
