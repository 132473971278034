import React, { FC, useContext, useState, useEffect } from "react";
import useMutation from "hooks/UseMutation";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";

import {
  RegisterReferrerPayload,
  RegisterReferrerInput,
  ReferrerDashboardActiveStep,
  CaptureReferrerDetailsStep,
  EventEnum,
  ValidationError,
} from "types/graphql";

import { referrerJourneyQueries } from "queries";

import useRecordEvent from "hooks/RecordEvent";

import getLayout from "./layouts";
import { Lego } from "lib/Lego";
import { FormData, Context, BrickTypes } from "lib/Lego/ReferrerJourney/CaptureReferrerDetails";
import { FormProvider, useForm } from "react-hook-form";
import { setErrorsForField } from "templates/helpers/SetErrorsForField";
import { useDevice } from "hooks/Device";

export type CaptureReferrerDetailsProps = {
  stepData: CaptureReferrerDetailsStep;
};

const CaptureReferrerDetails: FC<CaptureReferrerDetailsProps> = ({ stepData }) => {
  const { appConfig, goToStep, sessionConfig, sessionContentReplacements, utmParams } =
    useContext<DefaultContext>(StepContext);

  const [registrationError, setRegistrationError] = useState<boolean>(false);

  const recordEvent = useRecordEvent();

  const methods = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  const { setError } = methods;

  const flowId = appConfig.flow.id;
  const id = appConfig.id;
  const locale = appConfig.locale;

  const [submitReferrerRegistration, { loading }] = useMutation<
    { registerReferrer: RegisterReferrerPayload },
    RegisterReferrerInput
  >(referrerJourneyQueries.mutations.registerReferrer, {
    onCompleted: (data: { registerReferrer: RegisterReferrerPayload }): void => {
      if (data.registerReferrer.ok) {
        const nextSession = data.registerReferrer.session;
        const nextActiveStep = data.registerReferrer.session
          .activeStep as ReferrerDashboardActiveStep;

        goToStep({
          type: nextActiveStep.type,
          config: nextActiveStep.config,
          contentReplacements: nextSession.contentReplacements,
          validationErrors: (nextSession.activeStep as any).validationErrors || [],
        });
      } else {
        const nextSession = data.registerReferrer.session;

        setValidationErrors((nextSession.activeStep as any).validationErrors || []);

        setRegistrationError(true);
      }
    },
    onError: () => setRegistrationError(true),
  });

  const setValidationErrors = (validationErrors: ValidationError[]) => {
    validationErrors.forEach((f: ValidationError) => {
      setErrorsForField(setError, f.field, f.key, stepData.optionalContent?.form.inputs);
    });
  };

  useEffect(() => {
    setValidationErrors(sessionConfig?.validationErrors || []);
  }, [sessionConfig]);

  const handleReferrerRegistration = (formData: FormData): void => {
    const { fullName, firstName, lastName, email, marketingOptIn, termsOptIn, customFields } =
      formData;

    const variables = {
      flowId,
      id,
      locale,
      fullName,
      firstName,
      lastName,
      email,
      marketingOptIn,
      termsOptIn,
      utmParams,
    } as RegisterReferrerInput;

    if (customFields)
      variables.customFields = Object.keys(customFields).map((m) => ({
        key:
          stepData.optionalConfig?.inputs.customFields.find((f) => f.position === parseInt(m))
            ?.key || "",
        value: customFields[m],
      }));

    submitReferrerRegistration({
      variables,
    });
  };

  const context: Context = {
    step: stepData,
    session: sessionConfig,
    methods: {
      handleReferrerRegistration,
    },
    state: {
      device: useDevice(),
      registrationError,
      loading,
      sessionContentReplacements,
    },
    optionalStep: true,
  };

  useEffect(() => {
    recordEvent(EventEnum.VisitCampaign);
    recordEvent(EventEnum.VisitCaptureReferrerDetailsPage);
  }, [recordEvent]);

  return (
    <FormProvider {...methods}>
      <Lego<Context>
        brickTypes={BrickTypes}
        layout={getLayout(stepData.optionalConfig?.layout || "default")}
        context={context}
        isPreview={appConfig.isPreview}
      />
    </FormProvider>
  );
};

export default CaptureReferrerDetails;
