import React, { FC } from "react";
import renderTemplate from "lib/Template";
import { Context } from "../";
import styled from "styled-components";
import { resolveFontSize } from "styles/helpers";
import { transparentize } from "polished";

type Props = {
  isLoading: boolean;
};

const LogoutMessageStyled = styled.div<Props>`
  font-size: ${({ theme }): string => resolveFontSize(theme, "small")};
  color: ${({ theme, isLoading }): string =>
    transparentize(isLoading ? 0.8 : 0.1, theme.layout.color)};
`;

const LogoutStyled = styled.span<Props>`
  text-decoration: underline;
  margin-left: 0.2rem;
  cursor: ${({ isLoading }): string => (isLoading ? "wait" : "pointer")};
  color: ${({ theme, isLoading }): string =>
    transparentize(isLoading ? 0.8 : 0.6, theme.layout.color)};
`;

const LogoutLink: FC<{ context: Context }> = ({ context }) => {
  if (!context.state.logout.show) {
    return null;
  }
  const isLoading = context.state.logout.loading;

  return (
    <LogoutMessageStyled isLoading={isLoading}>
      {renderTemplate(
        context.step.content.logout.message,
        context.state.sessionContentReplacements,
      )}
      <LogoutStyled isLoading={isLoading} onClick={context.methods.handleLogout}>
        {renderTemplate(
          context.step.content.logout.linkText,
          context.state.sessionContentReplacements,
        )}
      </LogoutStyled>
    </LogoutMessageStyled>
  );
};

export default LogoutLink;
