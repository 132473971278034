import React, { FC, PropsWithChildren } from "react";
import { Context } from "./index";
import { LinkListStyled, LinkListItemStyled, LinkStyled } from "./UsefulLinks.styled";
import { BiLinkAlt } from "react-icons/bi";
import { AiOutlineArrowRight } from "react-icons/ai";
import CollapsibleCard from "components/collapsible-card/CollapsibleCard";

const UsefulLinks: FC<PropsWithChildren<{ context: Context }>> = ({ context }) => {
  const title = context.step.content.usefulLinks.title;
  const links = context.step.content.usefulLinks.buttons;

  return links && links.length > 0 ? (
    <CollapsibleCard title={title} icon={BiLinkAlt} collapsible={false}>
      <LinkListStyled>
        {links.map((m, i) => (
          <LinkListItemStyled key={i}>
            <LinkStyled target="_blank" href={m.url} rel="noopener noreferrer">
              <span>{m.text}</span>
              <AiOutlineArrowRight />
            </LinkStyled>
          </LinkListItemStyled>
        ))}
      </LinkListStyled>
    </CollapsibleCard>
  ) : null;
};

export default UsefulLinks;
