import { useForSmallContainer } from "hooks/ResponsiveStyle";
import styled from "styled-components";
import Button from "components/button/Button";
import {
  ParagraphStyled as GlobalParagraphStyled,
  TitleStyled as GlobalTitleStyled,
} from "styles/global.styled";

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const OfferContainer = styled.div`
  box-shadow: 0px 0px 8px 0px rgb(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 32rem;

  ${() =>
    useForSmallContainer(`
  box-shadow: none;
  width: 100%;
`)}
`;

export const OfferTextAndFormContainer = styled.div`
  padding: 3.1rem;
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  width: 100%;

  ${() =>
    useForSmallContainer(`
   padding: 0rem;
   `)}
`;

export const OfferTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;

  ${() =>
    useForSmallContainer(`
     gap: 1.25rem;
   `)}
`;

export const OfferTitle = styled(GlobalTitleStyled)`
  margin: 0;
`;

export const OfferText = styled(GlobalParagraphStyled)`
  margin: 0;
`;

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;

export const ButtonStyled = styled(Button)``;

export const OfferBannerImageStyled = styled.img`
  border-radius: 0.8rem;
  width: 100%;
  object-fit: contain;
`;
