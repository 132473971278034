import React, { FC, useContext } from "react";
import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";

import SelectReferrerRewardOption from "templates/click_to_claim/click_to_claim_referrer/SelectReferrerRewardOption";
import ConfirmReferrerRewardOption from "templates/click_to_claim/click_to_claim_referrer/ConfirmReferrerRewardOption";
import ReferrerRewardClaimed from "templates/click_to_claim/click_to_claim_referrer/ReferrerRewardClaimed";
import ReferrerRewardAlreadyClaimed from "templates/click_to_claim/click_to_claim_referrer/ReferrerRewardAlreadyClaimed";
import { SelectReferrerRewardOptionProps } from "templates/click_to_claim/click_to_claim_referrer/SelectReferrerRewardOption/SelectReferrerRewardOption";
import { ConfirmReferrerRewardOptionProps } from "templates/click_to_claim/click_to_claim_referrer/ConfirmReferrerRewardOption/ConfirmReferrerRewardOption";
import { ReferrerRewardClaimedProps } from "templates/click_to_claim/click_to_claim_referrer/ReferrerRewardClaimed/ReferrerRewardClaimed";
import { ReferrerRewardAlreadyClaimedProps } from "templates/click_to_claim/click_to_claim_referrer/ReferrerRewardAlreadyClaimed/ReferrerRewardAlreadyClaimed";

import SelectFriendRewardOption from "templates/click_to_claim/click_to_claim_friend/SelectFriendRewardOption/SelectFriendRewardOption";
import ConfirmFriendRewardOption from "templates/click_to_claim/click_to_claim_friend/ConfirmFriendRewardOption/ConfirmFriendRewardOption";
import FriendRewardClaimed from "templates/click_to_claim/click_to_claim_friend/FriendRewardClaimed/FriendRewardClaimed";
import FriendRewardAlreadyClaimed from "templates/click_to_claim/click_to_claim_friend/FriendRewardAlreadyClaimed";
import { SelectFriendRewardOptionProps } from "templates/click_to_claim/click_to_claim_friend/SelectFriendRewardOption/SelectFriendRewardOption";
import { ConfirmFriendRewardOptionProps } from "templates/click_to_claim/click_to_claim_friend/ConfirmFriendRewardOption/ConfirmFriendRewardOption";
import { FriendRewardClaimedProps } from "templates/click_to_claim/click_to_claim_friend/FriendRewardClaimed/FriendRewardClaimed";
import { FriendRewardAlreadyClaimedProps } from "templates/click_to_claim/click_to_claim_friend/FriendRewardAlreadyClaimed/FriendRewardAlreadyClaimed";

import CaptureFriendDetails from "templates/friend_journey/CaptureFriendDetails";
import CaptureAdditionalFriendDetails from "templates/friend_journey/CaptureAdditionalFriendDetails";
import SuccessfulFriendRegistration from "templates/friend_journey/SuccessfulFriendRegistration";
import IneligibleForVoucher from "templates/friend_journey/IneligibleForVoucher";
import { CaptureFriendDetailsProps } from "templates/friend_journey/CaptureFriendDetails/CaptureFriendDetails";
import { CaptureAdditionalFriendDetailsProps } from "templates/friend_journey/CaptureAdditionalFriendDetails/CaptureAdditionalFriendDetails";
import { SuccessfulFriendRegistrationProps } from "templates/friend_journey/SuccessfulFriendRegistration/SuccessfulFriendRegistration";
import { IneligibleForVoucherProps } from "templates/friend_journey/IneligibleForVoucher/IneligibleForVoucher";

import SeamlessLoginFailure from "templates/referrer_journey/SeamlessLoginFailure";
import SplashPage from "templates/referrer_journey/SplashPage";
import CaptureReferrerDetails from "templates/referrer_journey/CaptureReferrerDetails";
import ReferrerDashboard from "templates/referrer_journey/ReferrerDashboard";
import { SeamlessLoginFailureProps } from "templates/referrer_journey/SeamlessLoginFailure/SeamlessLoginFailure";
import { SplashPageProps } from "templates/referrer_journey/SplashPage/SplashPage";
import { CaptureReferrerDetailsProps } from "templates/referrer_journey/CaptureReferrerDetails/CaptureReferrerDetails";
import { ReferrerDashboardProps } from "templates/referrer_journey/ReferrerDashboard/ReferrerDashboard";

import PartnerDashboard from "templates/partner_journey/PartnerDashboard";
import { PartnerDashboardProps } from "templates/partner_journey/PartnerDashboard/PartnerDashboard";

import PageDisabled from "templates/PageDisabled";
import { PageDisabledProps } from "templates/PageDisabled/PageDisabled";

import GenericError from "templates/GenericError";
import FulfilmentExpired from "templates/click_to_claim/click_to_claim_shared/FulfilmentExpired";
import { FulfilmentExpiredProps } from "templates/click_to_claim/click_to_claim_shared/FulfilmentExpired/FulfilmentExpired";

import LookupUser from "templates/user_lookup_journey/LookupUser";
import { LookupUserProps } from "templates/user_lookup_journey/LookupUser/LookupUser";

import ClickToRedirectFriend from "templates/redirect_friend_to_client_journey/ClickToRedirectFriend";
import { ClickToRedirectFriendProps } from "templates/redirect_friend_to_client_journey/ClickToRedirectFriend/ClickToRedirectFriend";

import AutomaticallyRedirectFriend from "templates/redirect_friend_to_client_journey/AutomaticallyRedirectFriend";
import { AutomaticallyRedirectFriendProps } from "templates/redirect_friend_to_client_journey/AutomaticallyRedirectFriend/AutomaticallyRedirectFriend";

import ReferralLimitReached from "templates/friend_journey/ReferralLimitReached";
import { ReferralLimitReachedProps } from "templates/friend_journey/ReferralLimitReached/ReferralLimitReached";
import ContinueToClientSite from "templates/friend_journey/ContinueToClientSite/ContinueToClientSite";
import { ContinueToClientSiteProps } from "templates/friend_journey/ContinueToClientSite/ContinueToClientSite";

import RedeemReward from "templates/reward_redemption_journey/RedeemReward/RedeemReward";

const components: {
  [key: string]:
    | FC<SelectReferrerRewardOptionProps>
    | FC<ConfirmReferrerRewardOptionProps>
    | FC<ReferrerRewardClaimedProps>
    | FC<ReferrerRewardAlreadyClaimedProps>
    | FC<SelectFriendRewardOptionProps>
    | FC<ConfirmFriendRewardOptionProps>
    | FC<FriendRewardClaimedProps>
    | FC<FriendRewardAlreadyClaimedProps>
    | FC<CaptureFriendDetailsProps>
    | FC<CaptureAdditionalFriendDetailsProps>
    | FC<SuccessfulFriendRegistrationProps>
    | FC<IneligibleForVoucherProps>
    | FC<PageDisabledProps>
    | FC<SeamlessLoginFailureProps>
    | FC<CaptureReferrerDetailsProps>
    | FC<SplashPageProps>
    | FC<ReferrerDashboardProps>
    | FC<PartnerDashboardProps>
    | FC<FulfilmentExpiredProps>
    | FC<LookupUserProps>
    | FC<ClickToRedirectFriendProps>
    | FC<ReferralLimitReachedProps>
    | FC<AutomaticallyRedirectFriendProps>
    | FC<ContinueToClientSiteProps>
    | any;
} = {
  clickToClaimOptionsSelector: SelectReferrerRewardOption,
  clickToClaimOptionConfirmation: ConfirmReferrerRewardOption,
  rewardEmailed: ReferrerRewardClaimed,
  rewardEmailedReturning: ReferrerRewardAlreadyClaimed,
  selectFriendRewardOption: SelectFriendRewardOption,
  confirmFriendRewardOption: ConfirmFriendRewardOption,
  friendRewardClaimed: FriendRewardClaimed,
  friendRewardAlreadyClaimed: FriendRewardAlreadyClaimed,
  fulfilmentExpired: FulfilmentExpired,
  captureFriendDetails: CaptureFriendDetails,
  captureAdditionalFriendDetails: CaptureAdditionalFriendDetails,
  successfulFriendRegistration: SuccessfulFriendRegistration,
  ineligibleForVoucher: IneligibleForVoucher,
  pageDisabled: PageDisabled,
  splashPage: SplashPage,
  seamlessLoginFailure: SeamlessLoginFailure,
  captureReferrerDetails: CaptureReferrerDetails,
  referrerDashboard: ReferrerDashboard,
  partnerPageDisabled: PageDisabled,
  partnerDashboard: PartnerDashboard,
  genericError: GenericError,
  lookupUser: LookupUser,
  clickToRedirectFriend: ClickToRedirectFriend,
  referralLimitReached: ReferralLimitReached,
  continueToClientSite: ContinueToClientSite,
  redeemReward: RedeemReward,
  automaticallyRedirectFriend: AutomaticallyRedirectFriend,
};

const TemplateLoader: FC<{}> = () => {
  const { currentStep } = useContext<DefaultContext>(StepContext);

  return React.createElement(components[currentStep.type], {
    stepData: currentStep,
  });
};

export default TemplateLoader;
