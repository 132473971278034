import React, { FC } from "react";
import InfoMessage from "components/info-message";
import { Context } from ".";

const SignInLinkEmailedMessage: FC<{ context: Context }> = ({ context }) => {
  if (!context.step.optionalContent || !context.session?.config?.showSignInLinkEmailedMessage) {
    return null;
  }

  return <InfoMessage message={context.step.optionalContent.signInLinkEmailedMessage} />;
};

export default SignInLinkEmailedMessage;
