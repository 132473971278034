import styled from "styled-components";
import { resolveFontSize } from "styles/helpers";

type Props = {
  type: string;
};

export const StyledStatusText = styled.div<Props>`
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: ${({ theme }): string => resolveFontSize(theme, "small")};
  color: ${({ theme }): string => theme.layout.color};
`;
