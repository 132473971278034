import React, { FC, useContext, useEffect, useState } from "react";
import {
  RegisterFriendForRedirectionPayload,
  RegisterFriendForRedirectionInput,
  SessionInterface,
  AutomaticallyRedirectFriendStep,
  AutomaticallyRedirectFriendActiveStep,
  AutomaticallyRedirectFriendActiveStepConfig,
} from "types/graphql";
import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";
import useMutation from "hooks/UseMutation";
import { redirectFriendToClientJourneyQueries } from "queries";
import { AppLoaderStyled } from "@/App.styled.ts";

export type AutomaticallyRedirectFriendProps = {
  stepData: AutomaticallyRedirectFriendStep;
};

interface NextSessionPayload extends SessionInterface {
  activeStep: AutomaticallyRedirectFriendActiveStep;
}

const AutomaticallyRedirectFriend: FC<AutomaticallyRedirectFriendProps> = ({ stepData }) => {
  const { appConfig, sessionConfig, goToStep } = useContext<DefaultContext>(StepContext);

  const [registrationError, setRegistrationError] = useState<boolean>(false);

  const flowId = appConfig.flow.id;
  const id = appConfig.id;

  const [submitFriendRegistrationForRedirection, {}] = useMutation<
    { registerFriendForRedirection: RegisterFriendForRedirectionPayload },
    RegisterFriendForRedirectionInput
  >(redirectFriendToClientJourneyQueries.mutations.registerFriendForRedirection, {
    onCompleted: (data: {
      registerFriendForRedirection: RegisterFriendForRedirectionPayload;
    }): void => {
      if (data.registerFriendForRedirection.ok) {
        const nextSession = data.registerFriendForRedirection.session as NextSessionPayload;

        goToStep({
          type: nextSession.activeStep.type,
          config: nextSession.activeStep.config,
          validationErrors: (nextSession.activeStep as any).validationErrors || [],
          contentReplacements: nextSession.contentReplacements,
        });

        doRedirect(nextSession.activeStep.config as AutomaticallyRedirectFriendActiveStepConfig);
      } else {
        setRegistrationError(true);
      }
    },
    onError: (e) => setRegistrationError(true),
  });

  const handleContinueToClientSite = (): void => {
    if (sessionConfig.config?.requiresFriendRegistration) {
      const variables = {
        flowId,
        id,
      } as RegisterFriendForRedirectionInput;

      submitFriendRegistrationForRedirection({ variables });
      return;
    }

    if (sessionConfig.config?.destinationUrl) {
      doRedirect(sessionConfig.config as AutomaticallyRedirectFriendActiveStepConfig);
      return;
    }

    setRegistrationError(true);
  };

  const doRedirect = (config: AutomaticallyRedirectFriendActiveStepConfig): void => {
    if (config.destinationUrl) window.location.replace(config.destinationUrl);
  };

  useEffect(() => {
    handleContinueToClientSite();
  }, []);

  return <>{registrationError || <AppLoaderStyled />}</>;
};

export default AutomaticallyRedirectFriend;
