import { EmbedParameters } from "types/embed-parameters.types";
import { LoginViaSeamlessInput, LoginViaSeamlessMutation, SessionInterface } from "types/graphql";
import { referrerJourneyQueries } from "queries";

import { ApolloClient } from "@apollo/client";
import { newSession } from "./SetupSession";

export default (
  apolloClient: ApolloClient<object>,
  params: EmbedParameters,
): Promise<SessionInterface> | null => {
  const seamless = params.flow.params?.seamless;

  if (!seamless || !seamless.signature || !seamless.signed_keys) {
    return null;
  }

  return new Promise<SessionInterface>((resolve, reject) => {
    const newSessionId = newSession("referrer_journey", params.market, params.campaign);

    const variables: LoginViaSeamlessInput = {
      flowId: params.flow.id,
      id: newSessionId,
      signedKeys: seamless.signed_keys,
      signature: seamless.signature,
      fullName: seamless.full_name,
      email: seamless.email,
      locale: params.locale,
      customFieldValue: seamless.custom_field_value,
      customFields: seamless.custom_fields,
      marketingOptIn: seamless.marketing_opt_in,
    };

    if (seamless.client_data) {
      try {
        if (typeof seamless.client_data === "string") {
          variables.clientData = JSON.parse(seamless.client_data);
        } else {
          variables.clientData = seamless.client_data;
        }
      } catch (e) {
        console.error("BP Error: invalid client_data format, ", e);
      }
    }

    apolloClient
      .mutate<LoginViaSeamlessMutation, LoginViaSeamlessInput>({
        mutation: referrerJourneyQueries.mutations.loginViaSeamless,
        variables: variables,
      })
      .then((result) => {
        const session = result.data?.loginViaSeamless?.session;

        if (session) {
          resolve(session);
        } else {
          reject(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
