import styled from "styled-components";
import { resolveColor, resolveFontSize } from "styles/helpers";

type Props = {
  type: string;
};

export const WrapperStyled = styled.div`
  padding-top: 20px;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  max-width: calc(100vw - 2rem);
  -webkit-overflow-scrolling: touch-action;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const IndividualWrapper = styled.div`
  padding-left: 1rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  margin-left: 1rem;
`;

export const ConfirmReferalTitle = styled.h5<Props>`
  font-size: ${({ theme }): string => resolveFontSize(theme, "medium")};
  margin-bottom: 8px;
  margin-top: 0px;
  padding-bottom: 2px;
  padding-left: 20px;
  font-weight: bold;
  color: ${({ theme, type }): string =>
    type === "complete_referral"
      ? resolveColor(theme, theme.avatar.backgroundColor)
      : theme.layout.color};
`;

export const TitleWrapper = styled.div`
  position: relative;
  margin-right: auto;
`;
