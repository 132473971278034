import { CSVLink } from "react-csv";
import { BaseStyle, ButtonPrimaryStyle } from "components/button/Button.styled";
import styled from "styled-components";
import { setSvgCssFromAttributes } from "styles/helpers";
import { useForSmallContainer } from "hooks/ResponsiveStyle";

export const StyledCSVLink = styled(CSVLink)`
  ${BaseStyle}
  ${ButtonPrimaryStyle}
  ${({ children }) => setSvgCssFromAttributes(children)}

  svg {
    height: 24px;
  }
`;

export const WrapperStyled = styled.div`
  width: fit-content;
  ${() => useForSmallContainer(`margin-top: 1.2rem;`)}
`;
