import React, { FC, PropsWithChildren } from "react";
import { Context } from "./index";
import { FiUser } from "react-icons/fi";
import OfferDetails from "./OfferDetails";

const PartnerOfferDetails: FC<PropsWithChildren<{ context: Context }>> = ({ context }) => {
  const data = context.step.content.partnerOffer;
  const rewards =
    (context.step.config.partnerOffer.displayRewards &&
      context.session.config?.rewards?.partnerRewards) ||
    [];

  return (
    <OfferDetails
      data={data}
      rewards={rewards}
      icon={FiUser}
      showRewards={context.step.config.partnerOffer.displayRewards}
    />
  );
};

export default PartnerOfferDetails;
