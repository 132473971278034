import React, { FC } from "react";
import parse from "html-react-parser";

import { TitleStyled } from "styles/global.styled";

import { Context } from ".";

const title: FC<{ context: Context }> = ({ context }) => {
  return <TitleStyled>{parse(context.step.content.title)}</TitleStyled>;
};

export default title;
