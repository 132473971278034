import React, { FC, useContext, useEffect } from "react";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";

import { ContinueToClientSiteStep, EventEnum } from "types/graphql";

import useRecordEvent from "hooks/RecordEvent";

export type ContinueToClientSiteProps = {
  stepData: ContinueToClientSiteStep;
};

const ContinueToClientSite: FC<ContinueToClientSiteProps> = () => {
  const { sessionConfig } = useContext<DefaultContext>(StepContext);

  const redirectToUrl = sessionConfig.config?.destinationUrl;

  const recordEvent = useRecordEvent();

  useEffect(() => {
    recordEvent(EventEnum.ContinueToClientSite);
  }, [recordEvent]);

  useEffect(() => {
    // in embedding script you have registered and event sso_redirect as window.location = xyz
    if (redirectToUrl)
      (window.top || window.parent).postMessage(
        `buyapowa:sso_redirect:${encodeURIComponent(redirectToUrl)}`,
        "*",
      );
  }, [redirectToUrl]);

  return <div>Redirecting...</div>;
};

export default ContinueToClientSite;
