import React, { FC, useContext, useEffect, useState } from "react";
import {
  EventEnum,
  ClickToRedirectFriendStep,
  RegisterFriendForRedirectionPayload,
  RegisterFriendForRedirectionInput,
  SessionInterface,
  ClickToRedirectFriendActiveStep,
  ClickToRedirectFriendActiveStepConfig,
} from "types/graphql";
import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";
import useRecordEvent from "hooks/RecordEvent";
import { BrickTypes, Context } from "lib/Lego/RedirectFriendToClientJourney/ClickToRedirectFriend";
import { Lego } from "lib/Lego";
import getLayout from "./layouts";
import useMutation from "hooks/UseMutation";
import { redirectFriendToClientJourneyQueries } from "queries";

export type ClickToRedirectFriendProps = {
  stepData: ClickToRedirectFriendStep;
};

interface NextSessionPayload extends SessionInterface {
  activeStep: ClickToRedirectFriendActiveStep;
}

const ClickToRedirectFriend: FC<ClickToRedirectFriendProps> = ({ stepData }) => {
  const { appConfig, sessionContentReplacements, sessionConfig, goToStep } =
    useContext<DefaultContext>(StepContext);

  const recordEvent = useRecordEvent();

  const [registrationError, setRegistrationError] = useState<boolean>(false);

  const flowId = appConfig.flow.id;
  const id = appConfig.id;

  const [
    submitFriendRegistrationForRedirection,
    { loading: submitFriendRegistrationForRedirectionLoading },
  ] = useMutation<
    { registerFriendForRedirection: RegisterFriendForRedirectionPayload },
    RegisterFriendForRedirectionInput
  >(redirectFriendToClientJourneyQueries.mutations.registerFriendForRedirection, {
    onCompleted: (data: {
      registerFriendForRedirection: RegisterFriendForRedirectionPayload;
    }): void => {
      if (data.registerFriendForRedirection.ok) {
        const nextSession = data.registerFriendForRedirection.session as NextSessionPayload;

        goToStep({
          type: nextSession.activeStep.type,
          config: nextSession.activeStep.config,
          validationErrors: (nextSession.activeStep as any).validationErrors || [],
          contentReplacements: nextSession.contentReplacements,
        });

        doRedirect(nextSession.activeStep.config as ClickToRedirectFriendActiveStepConfig);
      } else {
        setRegistrationError(true);
      }
    },
    onError: (e) => setRegistrationError(true),
  });

  const handleContinueToClientSite = (): void => {
    if (sessionConfig.config?.requiresFriendRegistration) {
      const variables = {
        flowId,
        id,
      } as RegisterFriendForRedirectionInput;

      submitFriendRegistrationForRedirection({ variables });
      return;
    }

    if (sessionConfig.config?.destinationUrl) {
      doRedirect(sessionConfig.config as ClickToRedirectFriendActiveStepConfig);
      return;
    }

    setRegistrationError(true);
  };

  const doRedirect = (config: ClickToRedirectFriendActiveStepConfig): void => {
    if (config.destinationUrl) window.open(config.destinationUrl);
  };

  const context: Context = {
    step: stepData,
    session: sessionConfig,
    methods: {
      handleContinueToClientSite,
    },
    state: {
      registrationError,
      sessionContentReplacements,
      loading: submitFriendRegistrationForRedirectionLoading,
    },
    optionalStep: false,
  };

  useEffect(() => {
    recordEvent(EventEnum.VisitVoucherPage);
  }, [recordEvent]);

  return (
    <Lego<Context>
      brickTypes={BrickTypes}
      layout={getLayout("default")}
      context={context}
      isPreview={appConfig.isPreview}
    />
  );
};

export default ClickToRedirectFriend;
