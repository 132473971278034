import styled from "styled-components";

import { useForSmallContainer } from "hooks/ResponsiveStyle";
import { LegalLinkStyle } from "components/legal/Legal.styled";

export const NewLegalStyled = styled.div`
  ${() =>
    useForSmallContainer(`
    padding: 0 1rem;
  `)}

  ${LegalLinkStyle}
`;
