import { SvgWrapperStyled } from "components/svg/Svg.styled";
import { transparentize } from "polished";
import { resolveColor, resolveFontSize, resolveWithDefault } from "styles/helpers";
import styled from "styled-components";
import {
  EmphasisInParagraphStyled,
  LinkStyle as GlobalLinkStyle,
  StrongInParagraphStyled,
} from "styles/global.styled";

type CardStyledProps = {
  isOpen: boolean;
  expandVertically?: boolean;
};

type TitleStyledProps = {
  collapsible: boolean;
  isOpen: boolean;
};

type CardContentStyledProps = {
  collapsible: boolean;
  scrollable: boolean;
};

export const CardStyled = styled.section<CardStyledProps>`
  flex: 1;

  background-color: ${({ theme }) => resolveColor(theme, theme.layout.backgroundColor)};
  box-shadow: 0 0 8px ${({ theme }) => transparentize(0.9, theme.layout.color)};
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  min-width: 10rem;

  max-height: 3.5rem;

  ${(props) => (props.expandVertically ? "height: 100%;" : "")}

  transition: max-height 0.05s ease-out;
  overflow: hidden;

  ${(props) =>
    props.isOpen &&
    `
      max-height: 60rem;
      transition: max-height 0.25s ease-in;
    `}
`;

export const TitleWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  text-align: left;

  padding: 1.2rem;

  color: ${({ theme }): string =>
    resolveWithDefault(resolveColor(theme, theme.title.color), theme.layout.color)};

  > ${SvgWrapperStyled} {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.4rem;

    color: ${({ theme }): string =>
      resolveWithDefault(resolveColor(theme, theme.title.color), theme.layout.color)};
  }
`;

export const TitleStyled = styled.h5`
  margin: 0;
  flex: 1;
  font-size: ${({ theme }) => theme.font && resolveFontSize(theme, "medium")};
  line-height: ${({ theme }) => theme.font && parseInt(resolveFontSize(theme, "medium")) + "px"};
  height: ${({ theme }) => theme.font && resolveFontSize(theme, "medium")};
`;

export const ClickableTitleStyled = styled.button<TitleStyledProps>`
  background: transparent;
  border: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  text-align: left;

  padding: 1.2rem;

  color: ${({ theme }): string =>
    resolveWithDefault(resolveColor(theme, theme.title.color), theme.layout.color)};

  > ${SvgWrapperStyled} {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.4rem;

    color: ${({ theme }): string =>
      resolveWithDefault(resolveColor(theme, theme.title.color), theme.layout.color)};
  }

  ${(props) =>
    props.isOpen &&
    props.collapsible &&
    `
      background-color: ${resolveColor(props.theme, props.theme.button.primary.backgroundColor)};
      color: ${resolveWithDefault(
        resolveColor(props.theme, props.theme.button.primary.color),
        resolveColor(props.theme, props.theme.layout.backgroundColor),
      )};

      > ${SvgWrapperStyled} {
        color: ${resolveWithDefault(
          resolveColor(props.theme, props.theme.button.primary.color),
          resolveColor(props.theme, props.theme.layout.backgroundColor),
        )};
      }
    `}
  ${(props) =>
    props.collapsible &&
    `
      cursor: pointer;

      &:hover {
        background-color: ${resolveColor(props.theme, props.theme.button.primary.backgroundColor)};
        color: ${resolveWithDefault(
          resolveColor(props.theme, props.theme.button.primary.color),
          resolveColor(props.theme, props.theme.layout.backgroundColor),
        )};

        > ${SvgWrapperStyled} {
          color: ${resolveWithDefault(
            resolveColor(props.theme, props.theme.button.primary.color),
            resolveColor(props.theme, props.theme.layout.backgroundColor),
          )};
        }
      }
    `}
`;

export const CardContentStyled = styled.div<CardContentStyledProps>`
  overflow: auto;
  margin: 1.2rem;

  color: ${({ theme }): string => resolveColor(theme, theme.layout.color)};

  ${({ scrollable }) => !scrollable && `overflow: hidden;`}
  ${(props) =>
    !props.collapsible &&
    `
    margin-top: 0;
  `}

  a:not([role="button"]) {
    ${GlobalLinkStyle}

    text-align: left;
  }

  ${StrongInParagraphStyled}
  ${EmphasisInParagraphStyled}
`;
