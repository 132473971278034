import styled from "styled-components";
import { resolveFontSize } from "styles/helpers";
import { transparentize } from "polished";
import { css } from "styled-components";

import { useForSmallContainer } from "hooks/ResponsiveStyle";
import { ParagraphStyled, LinkStyle } from "styles/global.styled";

export const LegalTextStyled = styled(ParagraphStyled)`
  color: ${({ theme }) => theme.legal.color};
  opacity: ${({ theme }) => theme.legal.opacity};
  font-size: ${({ theme }) => resolveFontSize(theme, theme.legal.fontSize)};
  font-family: ${({ theme }) => theme.legal.fontFamily};
  line-height: ${({ theme }) => theme.legal.lineHeight};
`;

export const LegalLinkStyle = css`
  a {
    ${LinkStyle}
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
  }
`;

export const LegalStyled = styled.div`
  ${() => useForSmallContainer("margin: 0 20px;")}
  ${LegalLinkStyle}
`;

export const NewLegalStyled = styled.div`
  ${LegalLinkStyle}
`;

export const V1LegalStyled = styled.div`
  font-size: ${({ theme }) => resolveFontSize(theme, "extra_small")};

  ${() => useForSmallContainer("margin: 0 20px;")}

  > hr {
    display: none;
  }

  p {
    margin: 0;
  }
`;

export const HrStyled = styled.hr`
  border: 0;
  border-top: 1px solid ${({ theme }) => transparentize(0.7, theme.layout.color)};
`;

export const FormLegalStyled = styled.div`
  word-break: break-word;

  ${LegalLinkStyle}
`;
