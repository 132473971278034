import styled, { DefaultTheme } from "styled-components";

import { resolveColor, resolveFontSize, resolveWithDefault } from "styles/helpers";
import { LinkBlendedInParagraphStyled } from "styles/global.styled";

import { useForLargeContainer, useForSmallContainer } from "hooks/ResponsiveStyle";
import {
  TitleStyled as GlobalTitleStyled,
  ParagraphStyled as GlobalParagraphStyled,
  LinkHighlightedInParagraphStyled,
  StrongInParagraphStyled,
} from "styles/global.styled";

const captionColor = (theme: DefaultTheme) =>
  resolveWithDefault(
    resolveColor(theme, theme.form.captionColor),
    resolveColor(theme, theme.layout.color),
  );

export const OfferStyled = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => resolveColor(theme, theme.layout.backgroundColor)};

  ${() =>
    useForLargeContainer(`
        box-shadow: 0 0 15px 0 rgba(21, 40, 50, 0.15);
        border-radius: 14px;
        max-width: 380px;
        width: 100%;
      `)}

  ${LinkHighlightedInParagraphStyled}
  ${StrongInParagraphStyled}
`;

export const OfferImageStyled = styled.div`
  width: 100%;

  img {
    display: block;
    margin: auto;
    max-width: 100%;
    object-fit: cover;
    ${() =>
      useForLargeContainer(`
        border-top-right-radius: 14px;
        border-top-left-radius: 14px;
      `)}
  }
`;

export const OfferDescriptionStyled = styled.div`
  word-break: break-word;
  ${() => useForSmallContainer("padding: 30px 20px 10px;")}
  ${() => useForLargeContainer("padding: 28px 30px 38px;")}
`;

export const TitleStyled = styled(GlobalTitleStyled)`
  margin: 0 0 20px;
`;

export const ParagraphStyled = styled(GlobalParagraphStyled)`
  margin: 0;
`;

export const CaptionStyled = styled(ParagraphStyled)`
  color: ${({ theme }) => captionColor(theme)};
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 15px;
  ${({ theme }) => useForLargeContainer(`font-size: ${resolveFontSize(theme, "medium")};`)}
  ${({ theme }) => useForSmallContainer(`font-size: ${resolveFontSize(theme, "small")};`)}

  ${LinkBlendedInParagraphStyled}
`;

const marginRight = "20px";
const marginLeft = "20px";

export const WrapperStyled = styled.div`
  padding: 15px;
  margin: 20px ${marginRight} 0 ${marginLeft};
  width: calc(100% - (${marginRight} + ${marginLeft}));

  ${() =>
    useForLargeContainer(`
      padding: 0;
      margin: 0;
      width: 100%;
    `)}
`;
