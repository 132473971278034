import styled from "styled-components";
import { transparentize } from "polished";
import { resolveColor, resolveFontSize } from "styles/helpers";
import {
  textInputBackgroundColor,
  textInputColor,
  textInputLabelColor,
} from "components/input/Input.styled";

export const EmailSharingToolsStyled = styled.div`
  border: 1px solid ${({ theme }) => transparentize(0.8, theme.layout.color)};
  border-top: none;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 20px 5px;
`;

export const ToolsGridStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 18px;
  width: 100%;
`;

export const MessageEntryStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  span {
    font-weight: bold;
    margin-left: 15px;
    color: ${({ theme }) => transparentize(0.2, textInputLabelColor(theme))};
    font-size: ${({ theme }): string => resolveFontSize(theme, "small")};
  }

  textarea {
    margin: 10px 15px 0 15px;

    display: block;
    padding: 16px;

    font-family: inherit;
    font-size: ${({ theme }): string => resolveFontSize(theme, "small")};
    border-radius: ${({ theme }) => theme.textInput.borderRadius};
    background-color: ${({ theme }) => textInputBackgroundColor(theme)};
    outline: 0;
    border: 0;
    min-height: calc(${({ theme }) => resolveFontSize(theme, "small")} * 11);
    resize: vertical;

    color: ${({ theme }) => textInputColor(theme)};

    &:focus {
      box-shadow: 0 0 0 2px
        ${({ theme }) => resolveColor(theme, theme.textInput._focus.borderColor)} inset;
    }
  }
`;
