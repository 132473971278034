import { Localisation, PartnerDashboardStep, Urls } from "types/graphql";
import { StepConfig } from "types/StepContext.types";
import PartnerDashboardTitle from "./PartnerDashboardTitle";
import UsefulLinks from "./UsefulLinks";
import SharingTools from "./SharingTools";
import YourReferrals from "./YourReferrals";
import PerformanceTable from "./PerformanceTable";
import ProgramRules from "./ProgramRules";
import { Device } from "hooks/Device";
import PartnerOfferDetails from "./PartnerOfferDetails";
import AudienceOfferDetails from "./AudienceOfferDetails";

export type Context = {
  step: PartnerDashboardStep;
  session: StepConfig;
  methods: {
    handleSuccessfulCopy: (copyService: string) => void;
    handleShareCodeCopy: (copyService: string) => void;
  };
  localisation: Localisation;
  state: {
    device: Device;
    sessionContentReplacements: { [key: string]: string };
    showCopySuccessNotification: boolean;
    showShareCodeCopySuccessNotification: boolean;
    urls: Urls;
  };
};

export const BrickTypes: { [index: string]: React.FC<{ context: Context }> } = {
  PartnerDashboardTitle,
  UsefulLinks,
  SharingTools,
  YourReferrals,
  PerformanceTable,
  ProgramRules,
  PartnerOfferDetails,
  AudienceOfferDetails,
};
