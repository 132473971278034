import React, { FC, PropsWithChildren } from "react";
import BaseForm from "components/form";
import Input from "components/input";
import { LookupUserStep } from "types/graphql";
import { Context } from "lib/Lego/UserLookup/LookupUser";
import {
  ContainerStyled,
  OfferContainer,
  OfferTextAndFormContainer,
  OfferTextBlock,
  OfferTitle,
  OfferText,
  FormStyled,
  ButtonStyled,
  OfferBannerImageStyled,
} from "./Form.styled";

export type LookupUserAccessCodeFormProps = {
  stepData: LookupUserStep;
};

const Form: FC<PropsWithChildren<{ context: Context }>> = ({ context, children }) => {
  const inputContent = context.step.content.form.inputs;

  return (
    <ContainerStyled>
      <OfferContainer>
        <OfferTextAndFormContainer>
          {context.step.content.image?.url && (
            <OfferBannerImageStyled
              src={context.step.content.image.url}
              alt={context.step.content.image.altText}
            />
          )}
          <OfferTextBlock>
            <OfferTitle>{context.step.content.title}</OfferTitle>
            <OfferText>{context.step.content.body}</OfferText>
          </OfferTextBlock>
          <BaseForm
            handleOnSubmit={context.methods.handleLookupUser}
            overrideFormStyled={FormStyled}
          >
            <Input
              placeholder={inputContent.accessCode.placeholder}
              label={inputContent.accessCode.label}
              required
              showLabel
              name="code"
              errorMessage={
                (inputContent.accessCode.validationErrors || []).find((f) => f.key === "default")
                  ?.value || ""
              }
              showError={context.state.showInvalidCodeMessage}
              tooltip={inputContent.accessCode.tooltip || undefined}
            />
            <ButtonStyled appearance="primary" buttonType="submit" isLoading={false}>
              {context.step.content.form.button.text}
            </ButtonStyled>
          </BaseForm>
          {children}
        </OfferTextAndFormContainer>
      </OfferContainer>
    </ContainerStyled>
  );
};

export default Form;
