import React, { FC } from "react";
import { Context } from ".";
import { ContentStyled, HeadingStyled } from "./ReferralLimitReached.styled";
import renderTemplate from "lib/Template";

const Heading: FC<{ context: Context }> = ({ context }) => {
  if (!context.step.optionalContent) {
    return null;
  }

  return (
    <ContentStyled>
      <HeadingStyled>
        {renderTemplate(context.step.optionalContent.title, context.state.sessionContentReplacements)}
      </HeadingStyled>
    </ContentStyled>
  );
};

export default Heading;
