import { useContext } from "react";
import { BreakpointContext } from "context/BreakpointContext";
import { isMobile } from "react-device-detect";

export enum ContainerSize {
  SmallContainer,
  LargeContainer,
}

export type Device = {
  containerSize: ContainerSize;
  nativeSharingSupported: boolean;
};

export const useDevice = (): Device => {
  const breakpoint = useContext(BreakpointContext);
  const isSmallContainer = breakpoint === "small";

  return {
    containerSize: isSmallContainer ? ContainerSize.SmallContainer : ContainerSize.LargeContainer,
    nativeSharingSupported: !!navigator.share && isMobile,
  };
};
