import { EmbedParameters } from "types/embed-parameters.types";
import {
  PartnerLoginViaAuthTokenInput,
  PartnerLoginViaAuthTokenMutation,
  SessionInterface,
} from "types/graphql";
import { partnerJourneyQueries } from "queries";

import { ApolloClient } from "@apollo/client";

export default (
  apolloClient: ApolloClient<object>,
  params: EmbedParameters,
  sessionId: string,
): Promise<SessionInterface> | null => {
  const authToken = params.flow.params?.auth_token;

  if (!authToken) {
    return null;
  }

  return new Promise<SessionInterface>((resolve, reject) => {
    apolloClient
      .mutate<PartnerLoginViaAuthTokenMutation, PartnerLoginViaAuthTokenInput>({
        mutation: partnerJourneyQueries.mutations.partnerLoginViaAuthToken,
        variables: {
          flowId: params.flow.id,
          id: sessionId,
          authToken: authToken,
          locale: params.locale,
        },
      })
      .then((result) => {
        const session = result.data?.partnerLoginViaAuthToken?.session;

        if (session) {
          resolve(session);
        } else {
          reject(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
