import { Layout, LayoutStyle, Modifier } from "lib/Lego";
import { Context } from "lib/Lego/PartnerJourney/PartnerDashboard";

const defaultLayout: Layout<Context> = {
  containerStyle: LayoutStyle.Dashboard,
  bricks: [
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [
            {
              name: "PartnerDashboardTitle",
            },
          ],
        },
      ],
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [
            {
              name: "YourReferrals",
            },
          ],
        },
      ],
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [
            {
              name: "PartnerOfferDetails",
            },
          ],
        },
        {
          name: "Column",
          bricks: [
            {
              name: "AudienceOfferDetails",
            },
          ],
        },
      ],
      modifiers: {
        smallContainer: [Modifier.Hidden],
      },
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [
            {
              name: "PartnerOfferDetails",
            },
          ],
        },
      ],
      modifiers: {
        largeContainer: [Modifier.Hidden],
      },
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [
            {
              name: "AudienceOfferDetails",
            },
          ],
        },
      ],
      modifiers: {
        largeContainer: [Modifier.Hidden],
      },
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [
            {
              name: "SharingTools",
            },
          ],
        },
        {
          name: "Column",
          bricks: [
            {
              name: "UsefulLinks",
            },
          ],
        },
      ],
      modifiers: {
        smallContainer: [Modifier.Hidden],
      },
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [
            {
              name: "SharingTools",
            },
          ],
        },
      ],
      modifiers: {
        largeContainer: [Modifier.Hidden],
      },
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [
            {
              name: "UsefulLinks",
            },
          ],
        },
      ],
      modifiers: {
        largeContainer: [Modifier.Hidden],
      },
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [
            {
              name: "PerformanceTable",
            },
          ],
        },
      ],
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [
            {
              name: "ProgramRules",
            },
          ],
        },
      ],
    },
  ],
};

export default defaultLayout;
