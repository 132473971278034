import React, { FC } from "react";
import { Context } from "../index";
import { RewardLimitMessageStyled, TitleStyled } from "./rewardLimitMessage.styled";
import Svg from "../../../../../components/svg/Svg";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { renderString } from "../../../../Template";
import { Format, getDateFormatter } from "../../../../../components/FormattedDate/FormattedDate";
import { DateTime } from "luxon";

const RewardLimitMessage: FC<{ context: Context }> = ({ context }) => {
  if (!context.session?.config?.showRewardLimitWarningMessage) {
    return null;
  }
  const rewardCappingType = context.session.config.rewardCappingType;
  const sessionContentReplacements = context.state.sessionContentReplacements;
  const timestamp = sessionContentReplacements["reward_capping.refresh_date"];
  const localisedStrftime = getDateFormatter(context.localisation);
  const date = DateTime.fromISO(timestamp);
  const sessionContentReplacementsWithFormattedDate = {
    ...sessionContentReplacements,
    "reward_capping.refresh_date": date.isValid
      ? localisedStrftime(context.localisation.date[Format.Long], date.toJSDate())
      : timestamp,
  };

  const message =
    rewardCappingType === "volume"
      ? context.step.content.rewardCapping.volumeWarningMessage
      : context.step.content.rewardCapping.monetaryWarningMessage;

  return (
    <RewardLimitMessageStyled>
      <TitleStyled>
        <Svg svgImage={AiOutlineInfoCircle} />
        {context.step.content.rewardCapping.warningTitle}
      </TitleStyled>
      {renderString(message, sessionContentReplacementsWithFormattedDate)}
    </RewardLimitMessageStyled>
  );
};

export default RewardLimitMessage;
