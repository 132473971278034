import { css } from "styled-components";
import styled from "styled-components";

type ChildrenStyledProps = {
  isOpen: boolean;
};

export const CollapseStyled = styled.div``;

export const ChildrenStyled = styled.div<ChildrenStyledProps>`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.2s ease-out;

  ${(props) =>
    props.isOpen &&
    css`
      grid-template-rows: 1fr;
    `}
`;

export const ChildrenWrapperStyled = styled.div`
  overflow: hidden;
`;
