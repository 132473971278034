import React, { FC } from "react";
import { Context } from ".";
import PartnerOffer from "./PartnerOffer";
import ReferrerOffer from "./ReferrerOffer";
import { CaptureFriendDetailsActiveStep } from "types/graphql";

const Offer: FC<{ context: Context }> = ({ context }) => {
  if (!context.step.content || !context.step.config) {
    return null;
  }

  const session = context.session as CaptureFriendDetailsActiveStep;
  const rewards = (context.step.config.displayPartnerRewards && session?.config?.rewards) || null;

  return context.state.isPartnerAudience ? (
    <PartnerOffer
      rewards={rewards}
      step={context.step}
      sessionContentReplacements={context.state.sessionContentReplacements}
    />
  ) : (
    <ReferrerOffer
      step={context.step}
      sessionContentReplacements={context.state.sessionContentReplacements}
    />
  );
};

export default Offer;
