import React, { FC } from "react";
import { AiOutlineGoogle } from "react-icons/ai";

import {
  EmailButtonOuterStyled,
  EmailButtonStyled,
  EmailButtonLabelStyled,
} from "../EmailButton.styled";
import usePopupWindow from "../../../hooks/PopupWindow";

import { Props } from "../EmailButton.types";

const GoogleMail: FC<Props> = ({ subject, message, buttonText = "Gmail", onPopupOpen }) => {
  const popupWindow = usePopupWindow();
  const onClick = (): void => {
    popupWindow(
      `https://mail.google.com/mail/?view=cm&fs=1&su=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(message)}`
    );

    onPopupOpen();
  };

  return (
    <EmailButtonOuterStyled>
      <EmailButtonStyled onClick={onClick}>
        <AiOutlineGoogle />
      </EmailButtonStyled>
      <EmailButtonLabelStyled>{buttonText}</EmailButtonLabelStyled>
    </EmailButtonOuterStyled>
  );
};

export default GoogleMail;
