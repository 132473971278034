import React, { FC } from "react";

import { ReferrerDashboardActiveStepConfigShareNetworkShortUrls } from "types/graphql";

import FacebookMessengerShare, {
  Props as FacebookMessengerShareProps,
} from "components/share-button/facebook-messenger-share/FacebookMessengerShare";
import FacebookShare, {
  Props as FacebookShareProps,
} from "components/share-button/facebook-share/FacebookShare";
import TwitterShare, {
  Props as TwitterShareProps,
} from "components/share-button/twitter-share/TwitterShare";
import LineShare, { Props as LineShareProps } from "components/share-button/line-share/LineShare";
import PinterestShare, {
  Props as PinterestShareProps,
} from "components/share-button/pinterest-share/PinterestShare";
import RedditShare, {
  Props as RedditShareProps,
} from "components/share-button/reddit-share/RedditShare";
import TelegramShare, {
  Props as TelegramShareProps,
} from "components/share-button/telegram-share/TelegramShare";
import ViberShare, {
  Props as ViberShareProps,
} from "components/share-button/viber-share/ViberShare";
import VkontakteShare, {
  Props as VkontakteShareProps,
} from "components/share-button/vkontakte-share/VkontakteShare";
import WhatsAppShare, {
  Props as WhatsAppShareProps,
} from "components/share-button/whatsapp-share/WhatsAppShare";

import { NetworkSharingToolsStyled } from "./NetworkSharingTools.styled";

type Props = {
  networks: Array<string>;
  networkShareUrls: ReferrerDashboardActiveStepConfigShareNetworkShortUrls | undefined;
  networkShareMessage: string;
  networkShareImageUrl?: string;
};

const shareNetworkComponents: {
  [key: string]:
    | FC<FacebookMessengerShareProps>
    | FC<FacebookShareProps>
    | FC<TwitterShareProps>
    | FC<PinterestShareProps>
    | FC<RedditShareProps>
    | FC<LineShareProps>
    | FC<TelegramShareProps>
    | FC<ViberShareProps>
    | FC<VkontakteShareProps>
    | FC<WhatsAppShareProps>;
} = {
  facebookMessenger: FacebookMessengerShare,
  facebook: FacebookShare,
  twitter: TwitterShare,
  line: LineShare,
  pinterest: PinterestShare,
  reddit: RedditShare,
  telegram: TelegramShare,
  viber: ViberShare,
  vkontakte: VkontakteShare,
  whatsapp: WhatsAppShare,
};

const NetworkSharingTools: FC<Props> = ({
  networks,
  networkShareUrls,
  networkShareImageUrl,
  networkShareMessage,
}) => {
  if (!networkShareUrls) {
    return null;
  }

  return (
    <NetworkSharingToolsStyled>
      {networks.map((network: string) => {
        const shareNetworkUrl = (networkShareUrls as { [key: string]: string })[network];
        return (
          shareNetworkUrl &&
          React.createElement(
            shareNetworkComponents[network] as FC<{
              shareText: string;
              shareUrl: string;
              shareImageUrl?: string;
              key: string;
            }>,
            {
              shareText: networkShareMessage,
              shareUrl: shareNetworkUrl,
              shareImageUrl: networkShareImageUrl,
              key: network,
            }
          )
        );
      })}
    </NetworkSharingToolsStyled>
  );
};
export default NetworkSharingTools;
