import React, { FC } from "react";
import { TableCell } from "./Item.styled";
import Tooltip from "components/Tooltip/Tooltip";
import { ReferrerDashboardActiveStepConfigReferralStatusReferral } from "types/graphql";
import { StatusText, Name } from "../../../Shared";
import FormattedDate from "components/FormattedDate";

type Props = {
  item: ReferrerDashboardActiveStepConfigReferralStatusReferral;
  type: string;
  statusText: string;
};

const Row: FC<Props> = ({ item, type, statusText }) => {
  return (
    <>
      <TableCell data-tooltip-id="referrer-status-name" data-tooltip-content={item.name}>
        <Tooltip message={item.name} type={"friend-status-name"}>
          <Name>{item.name}</Name>
        </Tooltip>
      </TableCell>
      <TableCell>
        <FormattedDate timestamp={item.date} />
      </TableCell>
      <TableCell>
        <StatusText type={type} text={statusText} />
      </TableCell>
    </>
  );
};

export default Row;
