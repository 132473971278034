import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ContainerSize, useDevice } from "hooks/Device";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { CarouselScrollContainerStyled, CarouselStyled } from "./Carousel.styled";

const Carousel: FC<
  PropsWithChildren<{
    currentPage?: number;
    onScroll?: (pageIndex: number) => void;
    itemWidthPercentage?: number;
    style?: Record<string, any>;
  }>
> = ({ children, currentPage, onScroll, itemWidthPercentage, style }) => {
  const device = useDevice();
  const carouselScrollRef = useRef() as React.MutableRefObject<HTMLTableElement>;
  const carouselScrollContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const smallContainer = device.containerSize === ContainerSize.SmallContainer;

  const pageCount = React.Children.count(children);
  const [pageWidth, setPageWidth] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);

  const moveToPage = useCallback(
    (pageIndex: number): void => {
      carouselScrollRef.current.children[pageIndex - 1]?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
        inline: "center",
      });
    },
    [carouselScrollRef]
  );

  const observer: any = React.useRef(
    new ResizeObserver((entries) => {
      // Only care about the first element, we expect one element ot be watched
      const { width } = entries[0].contentRect;

      setPageWidth(width);
    })
  );

  useLayoutEffect(() => {
    const observerRef = observer.current;

    if (carouselScrollRef.current) {
      const firstChild = carouselScrollRef.current.children[0];
      observerRef.observe(firstChild);
    }

    return (): void => {
      if (observerRef) observerRef.disconnect();
    };
  }, [carouselScrollRef, observer]);

  useScrollPosition(
    ({ currPos }) => {
      if (smallContainer) {
        const newColumn = Math.max(0, Math.round(currPos.x / pageWidth));
        onScroll && onScroll(newColumn);
      }
    },
    [pageWidth, onScroll],
    carouselScrollRef,
    false,
    500,
    carouselScrollContainerRef
  );

  useLayoutEffect(() => {
    if (currentPage !== undefined && currentPage !== selectedPage) {
      setSelectedPage(currentPage);
      moveToPage(currentPage);
    }
  }, [currentPage, moveToPage, selectedPage, setSelectedPage]);

  return (
    <CarouselScrollContainerStyled ref={carouselScrollContainerRef} style={style}>
      <CarouselStyled
        pageCount={pageCount}
        itemWidthPercentage={itemWidthPercentage}
        ref={carouselScrollRef}
      >
        {children}
      </CarouselStyled>
    </CarouselScrollContainerStyled>
  );
};

export default Carousel;
