import Title from "../Shared/Title";
import Image from "../Shared/Image";
import CollapseOffer from "../Shared/CollapseOffer";
import OfferSteps from "../Shared/OfferSteps";
import OfferDetails from "../Shared/OfferDetails";

import Form from "./Form";
import SubmissionError from "./SubmissionError";
import SignInLinkEmailedMessage from "./SignInLinkEmailedMessage";
import EligibilityCheckRejectionMessage from "./EligibilityCheckRejectionMessage";
import FormLegal from "./FormLegal";
import Legal from "./Legal";

import { CaptureReferrerDetailsStep } from "types/graphql";
import { StepConfig } from "types/StepContext.types";
import { Modifier } from "lib/Lego/Modifiers";
import { Device } from "hooks/Device";

export type FormData = {
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  customFieldValue: string;
  marketingOptIn: boolean;
  termsOptIn: boolean;
  customFields: { [id: string]: string };
};

export type Context = {
  step: CaptureReferrerDetailsStep;
  session?: StepConfig;
  methods: {
    handleReferrerRegistration: (formData: FormData) => void;
  };
  state: {
    device: Device;
    sessionContentReplacements: any;
    registrationError: boolean;
    loading: boolean;
  };
  optionalStep: boolean;
};

export const BrickTypes: {
  [index: string]:
    | React.FC<{ context: Context }>
    | React.FC<{ context: Context; modifiers: Modifier[] }>;
} = {
  Title: Title as React.FC<{ context: Context; modifiers: Modifier[] }>,
  Image: Image as React.FC<{ context: Context; modifiers: Modifier[] }>,
  CollapseOffer: CollapseOffer as React.FC<{ context: Context }>,
  OfferSteps: OfferSteps as React.FC<{ context: Context }>,
  OfferDetails: OfferDetails as React.FC<{ context: Context }>,
  Legal: Legal as React.FC<{ context: Context }>,
  Form: Form,
  SubmissionError: SubmissionError,
  EligibilityCheckRejectionMessage: EligibilityCheckRejectionMessage,
  SignInLinkEmailedMessage: SignInLinkEmailedMessage,
  FormLegal: FormLegal,
};
