import React, { FC, PropsWithChildren } from "react";

import Input from "components/input";
import BaseForm from "components/form";
import {
  NewFormStyled,
  NewFormCaptionStyled,
  NewFormElementStyled,
  V1FormStyle,
  V1FormCaptionStyled,
} from "components/form/Form.styled";
import Switch from "components/switch";
import Button from "components/button";

import { Context } from ".";
import SignInLinkEmailedMessage from "./SignInLinkEmailedMessage";
import DateEditor from "components/DateEditor";
import { Modifier } from "lib/Lego/Modifiers";

import { FaPhoneAlt, FaUser } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { BsQuestionLg } from "react-icons/bs";
import { IconType } from "react-icons";
import {
  CustomFieldInputConfig,
  CustomFieldInputConfigInterface,
  CustomFieldListInputConfig,
} from "@/types/graphql";
import Dropdown, { DropdownOption } from "@/components/dropdown/Dropdown";

const Form: FC<PropsWithChildren<{ context: Context; modifiers: Modifier[] }>> = ({
  context,
  modifiers,
  children,
}) => {
  if (!context.step.optionalContent || !context.step.optionalConfig) {
    return null;
  }

  const inputConfig = context.step.optionalConfig.inputs;
  const inputContent = context.step.optionalContent.form.inputs;

  const isV1 = !!modifiers.find((f) => f === Modifier.V1);
  const FormStyleToUse = isV1 ? V1FormStyle : NewFormStyled;

  const prefixIcons: { [key: string]: IconType } = {
    phone_number: FaPhoneAlt,
    text: BsQuestionLg,
  };

  const customFields = inputConfig.customFields.map((cf) => cf as CustomFieldInputConfigInterface);

  return (
    <BaseForm
      handleOnSubmit={context.methods.handleReferrerRegistration}
      formCaption={context.step.optionalContent.form.caption}
      overrideFormStyled={FormStyleToUse}
      overrideFormCaptionStyled={isV1 ? V1FormCaptionStyled : NewFormCaptionStyled}
      overrideFormElementStyled={NewFormElementStyled}
      useMarkdown={isV1}
      canSubmit={!context.session?.config?.showSignInLinkEmailedMessage}
    >
      {inputConfig.fullName.show && (
        <Input
          placeholder={inputContent.fullName.placeholder}
          errorMessage={
            (inputContent.fullName.validationErrors || []).find((f) => f.key === "default")
              ?.value || ""
          }
          required
          label={inputContent.fullName.label}
          name="fullName"
          regex={inputConfig.fullName.pattern}
          prefixIcon={isV1 ? FaUser : undefined}
          tooltip={inputContent.fullName.tooltip || undefined}
        />
      )}
      {inputConfig.firstName.show && (
        <Input
          placeholder={inputContent.firstName.placeholder}
          errorMessage={
            (inputContent.firstName.validationErrors || []).find((f) => f.key === "default")
              ?.value || ""
          }
          required
          label={inputContent.firstName.label}
          name="firstName"
          regex={inputConfig.firstName.pattern}
          prefixIcon={isV1 ? FaUser : undefined}
          tooltip={inputContent.firstName.tooltip || undefined}
        />
      )}
      {inputConfig.lastName.show && (
        <Input
          placeholder={inputContent.lastName.placeholder}
          errorMessage={
            (inputContent.lastName.validationErrors || []).find((f) => f.key === "default")
              ?.value || ""
          }
          required
          label={inputContent.lastName.label}
          name="lastName"
          regex={inputConfig.lastName.pattern}
          prefixIcon={isV1 ? FaUser : undefined}
          tooltip={inputContent.lastName.tooltip || undefined}
        />
      )}
      {inputConfig.email.show && (
        <Input
          placeholder={inputContent.email.placeholder}
          label={inputContent.email.label}
          errorMessage={
            (inputContent.email.validationErrors || []).find((f) => f.key === "default")?.value ||
            ""
          }
          required
          name="email"
          inputType="email"
          regex={inputConfig.email.pattern}
          prefixIcon={isV1 ? GrMail : undefined}
          tooltip={inputContent.email.tooltip || undefined}
        />
      )}

      {customFields
        .slice()
        .sort((a, b) => a.position - b.position)
        .map((field, i) => {
          const fieldContent = inputContent.customFields.find((f) => f.key === field.key);
          const content = fieldContent?.content;

          switch (field.inputFieldType) {
            case "list":
              const listField = field as CustomFieldListInputConfig;

              const options: DropdownOption[] =
                content?.items?.map((item, i) => {
                  return { value: listField?.items[i], label: item };
                }) || [];

              return (
                <Dropdown
                  key={`custom-field-${i}`}
                  label={content?.label || ""}
                  placeholder={content?.placeholder || ""}
                  options={options}
                  name={`customFields[${field.position}]`}
                  prefixIcon={isV1 ? BsQuestionLg : undefined}
                  errorMessage={
                    (content?.validationErrors || []).find((f) => f.key === "default")?.value || ""
                  }
                />
              );
            case "date":
              return (
                <DateEditor
                  key={`custom-field-${i}`}
                  label={content?.label || ""}
                  errorMessage={
                    (content?.validationErrors || []).find((f) => f.key === "default")?.value || ""
                  }
                  required
                  name={`customFields[${field.position}]`}
                  tooltip={content?.tooltip || undefined}
                />
              );
            case "phone_number":
              const textField = field as CustomFieldInputConfig;

              return (
                <Input
                  key={`custom-field-${i}`}
                  placeholder={content?.placeholder || ""}
                  label={content?.label || ""}
                  errorMessage={
                    (content?.validationErrors || []).find((f) => f.key === "default")?.value || ""
                  }
                  required
                  name={`customFields[${textField.position}]`}
                  regex={textField.pattern}
                  tooltip={content?.tooltip || undefined}
                  prefixIcon={isV1 ? prefixIcons[textField.inputFieldType] : undefined}
                  inputType="tel"
                />
              );
            case "numeric":
            case "text":
            default:
              const inputField = field as CustomFieldInputConfig;

              return (
                <Input
                  key={`custom-field-${i}`}
                  placeholder={content?.placeholder || ""}
                  label={content?.label || ""}
                  errorMessage={
                    (content?.validationErrors || []).find((f) => f.key === "default")?.value || ""
                  }
                  required
                  name={`customFields[${inputField.position}]`}
                  regex={inputField.pattern}
                  tooltip={content?.tooltip || undefined}
                  prefixIcon={isV1 ? prefixIcons[inputField.inputFieldType] : undefined}
                  numericKeypad={inputField.inputFieldType === "numeric"}
                />
              );
          }
        })}

      {inputConfig.marketingOptIn.show && (
        <Switch
          label={inputContent.marketingOptIn.label}
          name="marketingOptIn"
          required={inputConfig.marketingOptIn.required}
          errorMessage={
            (inputContent.marketingOptIn.validationErrors || []).find((f) => f.key === "default")
              ?.value || ""
          }
          spaceBetween
          displayAsCheckbox={isV1}
        />
      )}
      {inputConfig.termsOptIn.show && (
        <Switch
          label={inputContent.termsOptIn.label}
          name="termsOptIn"
          required
          errorMessage={
            (inputContent.termsOptIn.validationErrors || []).find((f) => f.key === "default")
              ?.value || ""
          }
          spaceBetween
          displayAsCheckbox={isV1}
        />
      )}

      <SignInLinkEmailedMessage context={context} />

      {context.step.optionalConfig.button.show && (
        <Button appearance="primary" buttonType="submit" isLoading={context.state.loading}>
          {context.step.optionalContent.form.button.text}
        </Button>
      )}

      {children}
    </BaseForm>
  );
};

export default Form;
