import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { Context } from "./index";
import { ImStack } from "react-icons/im";
import CollapsibleCard from "components/collapsible-card/CollapsibleCard";
import { ContainerSize, useDevice } from "hooks/Device";
import FormattedDate, { Format } from "components/FormattedDate";
import ResponsiveTable from "components/responsive-table/ResponsiveTable";
import Pager from "components/pager";
import { formatDate } from "components/FormattedDate/FormattedDate";
import DownloadToCsv from "components/DownloadToCsv/DownloadToCsv";
import { WrapperStyled } from "components/DownloadToCsv/DownloadToCsv.styled";
import Tooltip from "components/Tooltip/Tooltip";
import Svg from "components/svg/Svg";
import { AiOutlineInfoCircle } from "react-icons/ai";
import styled from "styled-components";

const SvgStyled = styled(Svg)`
  height: 1rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
`;

const PerformanceTable: FC<PropsWithChildren<{ context: Context }>> = ({ context }) => {
  const title = context.step.content.performance.title;
  const table = context.step.content.performance.table;
  const { headers, tooltips, downloadButtonText } = table;
  const data = context.session.config?.performance?.details || [];
  const total = context.session.config?.performance?.total;
  const csvHeaders = [
    { label: headers.month, key: "date" },
    { label: headers.visits, key: "visits" },
    { label: headers.created, key: "created" },
    { label: headers.confirmed, key: "confirmed" },
    { label: headers.cancelled, key: "cancelled" },
  ];
  const csvData = data.map((monthlyDetails) => {
    const { date, visits, confirmed, created, cancelled } = monthlyDetails;
    return { date: formatDate(date, Format.MonthOnly), visits, created, confirmed, cancelled };
  });

  if (total) {
    const { visits, confirmed, created, cancelled } = total;
    const csvTotal = { date: table.total, visits, created, confirmed, cancelled };
    csvData.push(csvTotal);
  }

  const [currentPage, setCurrentPage] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [downloadDisabled, setDownloadDisabled] = useState(false);

  const device = useDevice();
  const smallContainer = device.containerSize === ContainerSize.SmallContainer;

  useEffect(() => {
    // we want to disable the download button on a new campaign when all values are still 0
    if (data.length === 1) {
      const { visits, confirmed, created, cancelled } = data[0];
      const columns = [visits, confirmed, created, cancelled];
      const disable = Object.values(columns).every((value) => value === 0);
      setDownloadDisabled(disable);
    } else {
      setDownloadDisabled(false);
    }
  }, [data]);

  return (
    <CollapsibleCard
      title={title}
      icon={ImStack}
      isOpen={true}
      collapsible={false}
      scrollable={false}
      additionalContent={
        smallContainer ? (
          <Pager onPageClick={setSelectedPage} pageCount={4} selectedPage={currentPage} />
        ) : (
          <WrapperStyled>
            <DownloadToCsv
              text={downloadButtonText}
              data={csvData}
              headers={csvHeaders}
              disabled={downloadDisabled}
            />
          </WrapperStyled>
        )
      }
    >
      <ResponsiveTable
        onScroll={(currentPage): void => setCurrentPage(currentPage)}
        currentColumn={selectedPage}
      >
        <thead>
          <tr>
            <th scope="col">{headers.month}</th>
            <th>
              <span>
                {headers.visits}

                <Tooltip message={tooltips.visits} type="visits" direction="top">
                  <SvgStyled svgImage={AiOutlineInfoCircle} />
                </Tooltip>
              </span>
            </th>
            <th>
              <span>
                {headers.created}

                <Tooltip message={tooltips.created} type="created" direction="top">
                  <SvgStyled svgImage={AiOutlineInfoCircle} />
                </Tooltip>
              </span>
            </th>
            <th>
              <span>
                {headers.confirmed}

                <Tooltip message={tooltips.confirmed} type="confirmed" direction="top">
                  <SvgStyled svgImage={AiOutlineInfoCircle} />
                </Tooltip>
              </span>
            </th>
            <th>
              <span>
                {headers.cancelled}

                <Tooltip message={tooltips.cancelled} type="cancelled" direction="top">
                  <SvgStyled svgImage={AiOutlineInfoCircle} />
                </Tooltip>
              </span>
            </th>
          </tr>
        </thead>

        <tbody>
          {data?.map((m, i) => (
            <tr key={i}>
              <td>
                <FormattedDate timestamp={m.date} format={Format.MonthOnly} />
              </td>
              <td>{m.visits}</td>
              <td>{m.created}</td>
              <td>{m.confirmed}</td>
              <td>{m.cancelled}</td>
            </tr>
          ))}
          <tr>
            <td>Total</td>
            <td>{total?.visits}</td>
            <td>{total?.created}</td>
            <td>{total?.confirmed}</td>
            <td>{total?.cancelled}</td>
          </tr>
        </tbody>
      </ResponsiveTable>

      {smallContainer && (
        <WrapperStyled>
          <DownloadToCsv
            text={downloadButtonText}
            data={csvData}
            headers={csvHeaders}
            disabled={downloadDisabled}
          />
        </WrapperStyled>
      )}
    </CollapsibleCard>
  );
};

export default PerformanceTable;
