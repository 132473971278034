import React, { FC } from "react";
import styled from "styled-components";

import { Context, stepContent } from ".";
import { Modifier } from "lib/Lego/Modifiers";
import { ContainerSize, useDevice } from "hooks/Device";

const ImageStyled = styled.div<{ modifiers: Modifier[] }>`
  text-align: center;

  img {
    max-width: 100%;
  }
`;

const Image: FC<Context & { modifiers: Modifier[] }> = ({ context, modifiers }) => {
  const device = useDevice();
  const content = stepContent(context);
  const smallContainer = device.containerSize === ContainerSize.SmallContainer;

  if (!content) {
    return null;
  }

  const image = content.image;
  if (!image?.url) {
    return null;
  }

  let imgUrl = image.url;
  let altText = image.altText;
  if (smallContainer && image?.mobile) {
    imgUrl = image.mobile.url;
    altText = image.mobile.altText;
  }

  return (
    <ImageStyled modifiers={modifiers}>
      <img src={imgUrl} alt={altText} />
    </ImageStyled>
  );
};

export default Image;
