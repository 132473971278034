import React, { createContext, ReactNode, useRef } from "react";
import Rollbar from "rollbar";

type Props = {
  children: ReactNode;
};

export type RollbarType = {
  rollbar: Rollbar | null;
};

export const RollbarContext = createContext<RollbarType>({ rollbar: null });

export default function RollbarProvider({ children }: Props): JSX.Element {
  const rollbarToken = import.meta.env.VITE_ROLLBAR_TOKEN;
  const rollbar = useRef<Rollbar | null>(null);

  if (rollbarToken && rollbarToken.length > 0) {
    rollbar.current = new Rollbar({
      accessToken: rollbarToken,
      captureUncaught: true,
      captureUnhandledRejections: true,
    });
  } else {
    rollbar.current = null;
  }

  const providerValue = {
    rollbar: rollbar.current,
  };
  return <RollbarContext.Provider value={providerValue}>{children}</RollbarContext.Provider>;
}
