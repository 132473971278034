import camelCase from "camelcase";
import { css, DefaultTheme } from "styled-components";
import ReactDOMServer from "react-dom/server";

export const resolveFontSize = (theme: DefaultTheme, fontSize: string): string => {
  const transformedFontSize = camelCase(fontSize);
  const fontSizes = theme.font.sizes as any;
  return fontSizes[transformedFontSize] || fontSize;
};

export const resolveColor = (theme: DefaultTheme, color: string): string => {
  const transformedColor = camelCase(color);
  const colors = theme.colors as any;

  return colors[transformedColor] || color;
};

export const setSvgCssFromAttributes = (component: any) => {
  const template = new DOMParser().parseFromString(
    ReactDOMServer.renderToStaticMarkup(component),
    "text/html",
  );

  const svg = template.getElementsByTagName("svg")[0];
  if (!svg) {
    return null;
  }

  const paths = template.getElementsByTagName("path");
  const pathsArray = Array.from(paths);

  const rects = template.getElementsByTagName("rect");
  const rectsArray = Array.from(rects);

  const circles = template.getElementsByTagName("circle");
  const circlesArray = Array.from(circles);

  return css`
    svg {
      stroke: ${svg.getAttribute("stroke")};
      stroke-width: ${svg.getAttribute("stroke-width")}px;
      fill: ${svg.getAttribute("fill")};
    }
    ${pathsArray.map((path, index) => {
      const [, transformRotateDeg, transformOriginX, transformOriginY] =
        path.getAttribute("transform")?.match(/rotate\(([-]?[0-9.]*)\s([0-9]*)\s([0-9]*)\)/) || [];

      const transform = transformRotateDeg ? "rotate(" + transformRotateDeg + "deg)" : "";
      const transformOrigin =
        transformOriginX && transformOriginY
          ? transformOriginX + "px " + transformOriginY + "px"
          : "";

      const strokeWidth = path.getAttribute("stroke-width");

      return css`
        path:nth-of-type(${index + 1}) {
          d: path("${path.getAttribute("d")}");
          fill: ${path.getAttribute("fill")};
          fill-rule: ${path.getAttribute("fill-rule")};
          clip-rule: ${path.getAttribute("clip-rule")};
          transform: ${transform};
          transform-origin: ${transformOrigin};
          ${strokeWidth ? `stroke-width: ${strokeWidth}px;` : null}
        }
      `;
    })}
    ${rectsArray.map(
      (rect, index) => css`
        rect:nth-of-type(${index + 1}) {
          x: ${rect.getAttribute("x")}px;
          y: ${rect.getAttribute("y")}px;
          width: ${rect.getAttribute("width")}px;
          height: ${rect.getAttribute("height")}px;
          rx: ${rect.getAttribute("rx")}px;
          ry: ${rect.getAttribute("ry")}px;
        }
      `,
    )}
    ${circlesArray.map(
      (circle, index) => css`
        circle:nth-of-type(${index + 1}) {
          x: ${circle.getAttribute("x")}px;
          y: ${circle.getAttribute("y")}px;
          width: ${circle.getAttribute("width")}px;
          height: ${circle.getAttribute("height")}px;
          cx: ${circle.getAttribute("cx")}px;
          cy: ${circle.getAttribute("cy")}px;
          r: ${circle.getAttribute("r")}px;
        }
      `,
    )}
  `;
};

export const resolveWithDefault = (value: string, defaultValue: string): string =>
  !value || value === "default" ? defaultValue : value;

export const cardBackgroundColor = (theme: DefaultTheme): string =>
  resolveWithDefault(
    resolveColor(theme, theme.card.backgroundColor),
    resolveColor(theme, theme.layout.backgroundColor),
  );

export const cardTitleColor = (theme: DefaultTheme): string =>
  resolveWithDefault(
    resolveColor(theme, theme.card.title.color),
    resolveColor(theme, theme.layout.color),
  );

export const cardDescriptionColor = (theme: DefaultTheme): string =>
  resolveWithDefault(
    resolveColor(theme, theme.card.description.color),
    resolveColor(theme, theme.layout.color),
  );
