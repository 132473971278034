import styled from "styled-components";

export const DataMessagesStyled = styled.div`
  background-color: white;
  display: flex;
  border-radius: 4px;
  padding: 15px;
  animation: fadeIn 250ms linear 500ms 1 forwards;
  opacity: 0;
  text-align: center;
  line-height: 20px;
  height: 100vh;
  width: 100%;
  justify-content: center;
  padding: 0;

  p {
    font-family: Arial, Helvetica, sans-serif;
    color: #313131;
    margin: 0;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

export const AppLoaderStyled = styled.span`
  display: flex;
  align-items: center;

  &::before {
    animation: 1s linear infinite spinner;
    border: solid 2px rgba(255, 255, 255, 0.5);
    border-bottom-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    content: "";
    height: 15px;
    width: 15px;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
