import { Layout, LayoutStyle, Modifier } from "lib/Lego";
import { Context } from "lib/Lego/FriendJourney/CaptureAdditionalFriendDetails";

const defaultLayout = {
  containerStyle: LayoutStyle.Wide,
  bricks: [
    {
      name: "Row",
      modifiers: [
        (context: Context) =>
          context &&
          (context.state.firstVisit || context.step.optionalContent?.nextVisit.showButtons) &&
          (context.state.firstVisit ? [Modifier.SixtyFourtySplit] : [Modifier.FourtySixtySplit]),
      ],
      bricks: [
        {
          name: "FirstVisitSection",
          modifiers: [
            (context: Context) => context && !context.state.firstVisit && [Modifier.Hidden],
          ],
        },
        {
          name: "Form",
          modifiers: {
            largeContainer: [Modifier.VerticalAlignTop],
          },
          bricks: [{ name: "SubmissionError" }],
        },
        {
          name: "SecondVisitSection",
          modifiers: [
            (context: Context) =>
              context &&
              context.state.firstVisit &&
              !context.step.optionalContent?.nextVisit.showButtons && [Modifier.Hidden],
          ],
        },
      ],
    },
    {
      name: "Row",
      bricks: [{ name: "Legal" }],
      modifiers: {
        smallContainer: [Modifier.PaddingTopMedium],
      },
    },
  ],
} as Layout<Context>;

export default (layout: string): Layout<Context> => {
  switch (layout) {
    default:
      return defaultLayout;
  }
};
