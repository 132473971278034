import React, { FC } from "react";
import Header from "../Shared/Header";
import { DesktopWrapper, TableWrapper } from "./Desktop.styled";
import Table from "./Table";
import { Context } from "../..";

const Desktop: FC<{ context: Context }> = ({ context }) => {
  const content = context.step.content.referralStatus;
  const config = context.session.config?.referralStatus;
  const stepConfig = context.step.config;
  if (!config || !content || !stepConfig) return null;

  const anyReferrals = config.completeReferrals.length + config.pendingReferrals.length > 0;

  // if we've got nothing to display just exit
  if (!anyReferrals) return null;
  const showPending =
    config.pendingReferrals.length > 0 && context.step.config.showPendingReferrals;

  const showStats = context.step.config.showReferralStats;
  const showStatuses = context.step.config.showReferralStatuses;

  return (
    <DesktopWrapper>
      {showStats && <Header stats={content.stats} config={config} stepConfig={stepConfig} />}
      {showStatuses && (
        <TableWrapper>
          {config.completeReferrals.length > 0 && (
            <Table
              type="complete"
              title={content.table.complete.title}
              headers={content.table.headers}
              items={config.completeReferrals}
              tooltipMessage={content.table.complete.tooltip}
              statuses={content.table.statuses}
            />
          )}
          {showPending && (
            <Table
              type="pending"
              title={content.table.pending.title}
              headers={content.table.headers}
              items={config.pendingReferrals}
              tooltipMessage={content.table.pending.tooltip}
              statuses={content.table.statuses}
            />
          )}
        </TableWrapper>
      )}
    </DesktopWrapper>
  );
};

export default Desktop;
