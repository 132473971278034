import React, { FC } from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import rehypeRaw from "rehype-raw";
import supersub from "remark-supersub";
import { resolveColor, resolveFontSize, resolveWithDefault } from "../../styles/helpers";
import {
  EmphasisInParagraphStyled,
  LinkStyle,
  StrongInParagraphStyled,
} from "../../styles/global.styled";

const MarkdownStyled = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &,
    & > strong {
      font-weight: ${({ theme }): string => theme.title.fontWeight};
      font-family: ${({ theme }): string =>
        resolveWithDefault(theme.title.fontFamily, theme.font.family)};
      color: ${({ theme }): string =>
        resolveWithDefault(resolveColor(theme, theme.title.color), theme.layout.color)};
    }
  }

  h1 {
    font-size: ${({ theme }): string => resolveFontSize(theme, theme.title.fontSize)};
  }

  h2 {
    font-size: ${({ theme }): string =>
      `calc(${resolveFontSize(theme, theme.title.fontSize)} * 0.9)`};
  }

  h3 {
    font-size: ${({ theme }): string =>
      `calc(${resolveFontSize(theme, theme.title.fontSize)} * 0.8)`};
  }

  h4 {
    font-size: ${({ theme }): string =>
      `calc(${resolveFontSize(theme, theme.title.fontSize)} * 0.7)`};
  }

  h5 {
    font-size: ${({ theme }): string =>
      `calc(${resolveFontSize(theme, theme.title.fontSize)} * 0.6)`};
  }

  h6 {
    font-size: ${({ theme }): string =>
      `calc(${resolveFontSize(theme, theme.title.fontSize)} * 0.5)`};
  }

  p {
    white-space: pre-wrap;
  }

  ${StrongInParagraphStyled}
  ${EmphasisInParagraphStyled}
`;

const LinkStyled = styled.a`
  ${LinkStyle}

  font-size: inherit;
`;

const MarkdownContent: FC<{ children: string }> = ({ children }) => {
  return (
    <MarkdownStyled>
      <ReactMarkdown
        remarkPlugins={[supersub]}
        rehypePlugins={[rehypeRaw]}
        components={{
          a: (props): JSX.Element => {
            const StyledLink = LinkStyled as any;
            return <StyledLink {...props} />;
          },
        }}
      >
        {children}
      </ReactMarkdown>
    </MarkdownStyled>
  );
};

export default MarkdownContent;
