import { EmbedParameters } from "types/embed-parameters.types";
import { configPreviewForFlow } from "preview-queries";
import { ApolloClient } from "@apollo/client";
import { Config } from "types/graphql";

export default (apolloClient: ApolloClient<object>, params: EmbedParameters): Promise<Config> => {
  return new Promise<Config>((resolve, reject) => {
    apolloClient
      .query({
        query: configPreviewForFlow(params.flow.name),
        variables: {
          flow: params.flow.name,
          locale: params.locale,
          campaignSlug: params.campaign,
          configurationId: params.preview?.configuration_id,
          flowId: params.flow.id,
        },
      })
      .then((result) => {
        resolve(result.data.configPreview as Config);
      })
      .catch(reject);
  });
};
