import styled from "styled-components";

import { NewFormStyled as GlobalFormStyled } from "components/form/Form.styled";
import { ButtonStyled } from "components/button/Button.styled";
import { resolveColor, resolveFontSize } from "styles/helpers";
import { useForLargeContainer, useForSmallContainer } from "hooks/ResponsiveStyle";
import {
  TitleStyled as GlobalTitleStyled,
  ParagraphStyled as GlobalParagraphStyled,
  LinkHighlightedInParagraphStyled,
  StrongInParagraphStyled,
} from "styles/global.styled";
import { CarouselStyled } from "components/carousel/Carousel.styled";

export const OfferDescriptionStyled = styled.div`
  word-break: break-word;
  ${() => useForSmallContainer("padding: 30px 20px 10px;")}
  ${() => useForLargeContainer("padding: 28px 30px 38px;")}
`;

export const TitleStyled = styled(GlobalTitleStyled)`
  margin: 0;
`;

export const ParagraphStyled = styled(GlobalParagraphStyled)`
  margin: 20px 0;
`;

export const WrapperStyled = styled.div`
  padding-bottom: 40px;
  padding-top: 40px;
  ${() =>
    useForLargeContainer(`
      max-width: 1075px;
      width: 100%;
    `)}
`;

export const EndParagraphStyled = styled(ParagraphStyled)`
  ${() =>
    useForLargeContainer(`
    margin: 20px 0 0;
  `)}
`;

export const BodyWrapperStyled = styled.div`
  display: flex;
  justify-content: center;

  ${() =>
    useForSmallContainer(`
    flex-direction: column;
    align-items: center;
  `)}
  ${() =>
    useForLargeContainer(`
    flex-direction: row;
  `)}
  margin-bottom: 50px;
`;

export const SubmissionErrorStyled = styled(GlobalParagraphStyled)`
  color: ${({ theme }) => resolveColor(theme, theme.form.error.color)};
  text-align: center;
`;

export const UpperSectionWrapperStyled = styled.div`
  ${() =>
    useForLargeContainer(`
      flex: 2;
    `)}
  ${() =>
    useForSmallContainer(`
    width: 100%;
    padding: 1rem;
  `)}

  ${CarouselStyled} {
    margin-top: 1rem;

    & > *:first-of-type {
      margin-left: 32.5px;
    }

    & > *:last-of-type {
      margin-right: 32.5px;
    }
  }
`;

export const LowerSectionWrapperStyled = styled.div`
  ${() =>
    useForLargeContainer(`
      flex: 2;
    `)}
  ${() =>
    useForSmallContainer(`
    width: 100%;

  `)}

  ${CarouselStyled} {
    margin-top: 1rem;

    & > *:first-of-type {
      margin-left: 32.5px;
    }

    & > *:last-of-type {
      margin-right: 32.5px;
    }
  }
`;

export const InstructionsStyled = styled(TitleStyled)``;

export const SecondaryInstructionsStyled = styled(ParagraphStyled)`
  ${({ theme }) => useForLargeContainer(`font-size: ${resolveFontSize(theme, "large")};`)}
  margin-top: 0;
`;

export const FormStyled = styled(GlobalFormStyled)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => resolveColor(theme, theme.layout.backgroundColor)};
  box-sizing: border-box;
  grid-gap: initial;
  width: 100%;
  border-radius: 20px;
  padding: 49px;
  width: 410px;

  ${() =>
    useForSmallContainer(`
      border: none;
      padding: 1rem;
      width: 100%;
    `)}

  ${LinkHighlightedInParagraphStyled}
  ${StrongInParagraphStyled}

  ${() =>
    useForLargeContainer(`
      margin: 0;
    `)}

  ${ButtonStyled} {
    margin-top: 20px;
  }
`;
