import React, { FC, useContext } from "react";

import { EventEnum, FriendRewardClaimedStep } from "types/graphql";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";

import useRecordEvent from "hooks/RecordEvent";
import RewardClaimed from "templates/click_to_claim/RewardClaimed";
import { renderString } from "lib/Template";
import Button from "components/button";
import VisuallyHidden from "components/visually-hidden";

export type FriendRewardClaimedProps = {
  stepData: FriendRewardClaimedStep;
};

const FriendRewardClaimed: FC<FriendRewardClaimedProps> = ({ stepData }) => {
  const { currentStep, sessionContentReplacements, callbacks } =
    useContext<DefaultContext>(StepContext);
  const recordEvent = useRecordEvent();
  const showFooterLink = stepData.config.footerLink.show;

  const handleFooterLinkClick = (): void => {
    recordEvent(EventEnum.ClickToClaimFriendLinkClicks, {
      step: currentStep.type,
      target: "footer_link",
    });

    window.open(renderString(stepData.content.footerLink.url, sessionContentReplacements));
  };

  if (callbacks && callbacks.onLayoutLoaded) callbacks.onLayoutLoaded();

  return (
    <RewardClaimed stepData={stepData}>
      {showFooterLink && (
        <Button handleOnClick={(): void => handleFooterLinkClick()} appearance="link">
          {stepData.content.footerLink.text}
          <VisuallyHidden>Opens in a new tab</VisuallyHidden>
        </Button>
      )}
    </RewardClaimed>
  );
};

export default FriendRewardClaimed;
