import React from "react";
import { RollbarContext } from "providers/RollbarProvider";
type Props = {
  errorContainer: HTMLElement | null;
  children: any;
};

type State = {
  errorMessage: boolean;
  hasError: boolean;
};

export default class AppErrorBoundary extends React.Component<
  Props,
  State,
  null | string | React.ReactNode
> {
  static contextType = RollbarContext;

  // @ts-ignore
  context!: React.ContextType<typeof RollbarContext>;

  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      errorMessage: false,
      hasError: false,
    };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch = (error: Error, info: object): void => {
    // eslint-disable-next-line react/prop-types
    const { errorContainer } = this.props;

    const { rollbar } = this.context;
    if (rollbar) {
      rollbar.error(error, info);
    }
    if (errorContainer instanceof HTMLElement) {
      // eslint-disable-next-line react/prop-types
      errorContainer.style.display = "block";
    } else {
      this.setState({ errorMessage: true });
    }
  };

  render = (): null | React.ReactNode => {
    if (this.state.hasError) {
      return this.state.errorMessage ? "Application encountered an error" : null;
    }
    // eslint-disable-next-line react/prop-types
    return this.props.children;
  };
}
