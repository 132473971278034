import { Layout, LayoutStyle, Modifier } from "lib/Lego";
import { Context } from "lib/Lego/FriendJourney/CaptureFriendDetails";

const defaultLayout = {
  containerStyle: {
    largeContainer: LayoutStyle.Regular,
    smallContainer: LayoutStyle.Flush,
  },
  bricks: [
    {
      name: "Row",
      bricks: [
        {
          name: "Offer",
          modifiers: {
            largeContainer: [
              (context: Context) => {
                if (context.state.isPartnerAudience) {
                  return [Modifier.VerticalAlignTop];
                } else {
                  return [Modifier.VerticalAlignMiddle];
                }
              },
            ],
            smallContainer: [Modifier.VerticalAlignTop],
          },
        },
        {
          name: "Form",
          modifiers: {
            largeContainer: [
              (context: Context) => {
                if (context.state.isPartnerAudience) {
                  return [Modifier.VerticalAlignTop];
                } else {
                  return [Modifier.VerticalAlignMiddle, Modifier.PaddingLeftLarge];
                }
              },
            ],
          },
          bricks: [{ name: "SubmissionError" }, { name: "FormLegal" }],
        },
      ],
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [{ name: "Legal" }],
          modifiers: {
            smallContainer: [Modifier.PaddingTopMedium],
          },
        },
      ],
    },
  ],
} as Layout<Context>;

export default (layout: string): Layout<Context> => {
  switch (layout) {
    default:
      return defaultLayout;
  }
};
