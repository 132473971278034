import styled from "styled-components";
import { CopyButtonStyled as BaseCopyButtonStyled } from "components/share-area/ShareArea.styled";
import { useForSmallContainer } from "hooks/ResponsiveStyle";

import { transparentize } from "polished";

export const CopyButtonStyled = styled(BaseCopyButtonStyled)`
  border-radius: 0.8rem;
  font-weight: 350;
  background-color: ${({ theme }) => transparentize(0.92, theme.colors.highlight)};
`;

export const ShareUrlStyled = styled.div`
  text-overflow: ellipsis;
  padding: 1rem;
  border-radius: 0.8rem;
  background-color: ${({ theme }) => transparentize(0.92, theme.layout.color)};
  margin: 0.8rem 0;
  text-align: center;
  font-size: 0.9rem;
`;

export const PartnerSharingToolsTitle = styled.p`
  margin: 1.2rem 0 0;

  ${() =>
    useForSmallContainer(`
      margin: 1.3rem 0 0.7rem;
  `)}
`;

export const PartnerStandardSharingToolsTitle = styled.p`
  margin: 1.2rem 0 0;
`;
