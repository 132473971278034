import { Layout, LayoutStyle, Modifier } from "lib/Lego";
import { Context } from "lib/Lego/ReferrerJourney/CaptureReferrerDetails";

const defaultLayout = {
  containerStyle: LayoutStyle.Regular,
  bricks: [
    {
      name: "Row",
      bricks: [
        {
          name: "Image",
          modifiers: {
            largeContainer: [Modifier.Hidden],
          },
        },
      ],
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [
            {
              name: "Image",
              modifiers: {
                smallContainer: [Modifier.Hidden],
              },
            },
          ],
        },
        {
          name: "Column",
          bricks: [
            { name: "Title" },
            {
              name: "CollapseOffer",
              modifiers: [Modifier.PaddingBottomMedium],
              bricks: [{ name: "OfferSteps" }, { name: "OfferDetails" }],
            },
            {
              name: "Form",
              bricks: [
                { name: "SubmissionError" },
                { name: "EligibilityCheckRejectionMessage" },
                { name: "FormLegal" },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [{ name: "Legal" }],
          modifiers: {
            smallContainer: [Modifier.PaddingTopMedium],
          },
        },
      ],
    },
  ],
};

const transitionLayout1 = {
  containerStyle: LayoutStyle.Regular,
  bricks: [
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [{ name: "Title", modifiers: [Modifier.TextAlignCenter] }],
        },
      ],
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Image",
          modifiers: {
            largeContainer: [Modifier.Hidden],
          },
        },
      ],
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [
            {
              name: "Image",
              modifiers: {
                smallContainer: [Modifier.Hidden],
              },
            },
            { name: "OfferSteps", modifiers: { smallContainer: [Modifier.Hidden] } },
          ],
        },
        {
          name: "Column",
          bricks: [
            {
              name: "CollapseOffer",
              modifiers: {
                largeContainer: [Modifier.Hidden],
                smallContainer: [Modifier.PaddingBottomMedium],
              },
              bricks: [{ name: "OfferSteps" }],
            },
            { name: "OfferDetails", modifiers: { smallContainer: [Modifier.Hidden] } },
            {
              name: "Form",
              bricks: [
                { name: "SubmissionError" },
                { name: "EligibilityCheckRejectionMessage" },
                { name: "FormLegal" },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Column",
          bricks: [{ name: "Legal" }],
          modifiers: {
            smallContainer: [Modifier.PaddingTopMedium],
          },
        },
      ],
    },
  ],
};

const v1MigrationLayout = {
  containerStyle: LayoutStyle.V1,
  bricks: [
    {
      name: "Row",
      bricks: [
        {
          name: "Title",
          modifiers: [Modifier.V1],
        },
      ],
    },
    {
      name: "V1Row",
      bricks: [
        {
          name: "Column",
          bricks: [
            {
              name: "OfferDetails",
            },
            {
              name: "Image",
            },
          ],
          modifiers: {
            smallContainer: [Modifier.Hidden],
          },
        },
        {
          name: "Column",
          bricks: [
            {
              name: "OfferDetails",
              modifiers: {
                largeContainer: [Modifier.Hidden],
              },
            },
            {
              name: "Form",
              modifiers: [Modifier.V1],
              bricks: [{ name: "SubmissionError" }, { name: "EligibilityCheckRejectionMessage" }],
            },
          ],
        },
      ],
    },
    {
      name: "Row",
      bricks: [{ name: "FormLegal" }],
    },
    {
      name: "Row",
      bricks: [
        {
          name: "Legal",
          modifiers: [Modifier.V1],
        },
      ],
    },
  ],
};

export default (layout: string): Layout<Context> => {
  switch (layout) {
    case "transition_1":
      return transitionLayout1;
    case "v1":
      return v1MigrationLayout;
    default:
      return defaultLayout;
  }
};
