import React, { FC, PropsWithChildren } from "react";
import { Context } from "./index";
import ShareUrl from "components/share-area";
import { ShareCode } from "components/share-area";
import render, { renderString } from "lib/Template";
import { CopyButtonStyled, PartnerStandardSharingToolsTitle } from "./SharingTools.styled";

const PartnerStandardSharingTools: FC<PropsWithChildren<{ context: Context }>> = ({ context }) => {
  const { sharingTools } = context.step.content;
  const { directLink, shareCode } = sharingTools;
  const settings = context.step.config.sharingTools;

  return (
    <>
      {settings.displayDirectLink && (
        <ShareUrl
          title=""
          copySuccessNotification={renderString(
            directLink.copySuccessNotification,
            context.state.sessionContentReplacements,
          )}
          showCopySuccessNotification={context.state.showCopySuccessNotification}
          url={context.session.config?.sharingUrls?.directLink || ""}
          onCopy={context.methods.handleSuccessfulCopy}
          overrideButtonStyle={CopyButtonStyled}
          copyButtonAccessibleLabel={directLink.copyButtonText}
        />
      )}

      {settings.displayShareCode && (
        <>
          <PartnerStandardSharingToolsTitle>
            {render(shareCode.label, context.state.sessionContentReplacements)}
          </PartnerStandardSharingToolsTitle>

          <ShareCode
            title=""
            copySuccessNotification={renderString(
              shareCode.copySuccessNotification,
              context.state.sessionContentReplacements,
            )}
            copyText={renderString(
              shareCode.shareMessage,
              context.state.sessionContentReplacements,
            )}
            showCopySuccessNotification={context.state.showShareCodeCopySuccessNotification}
            shareMessage={
              renderString(shareCode.shareMessage, context.state.sessionContentReplacements) || ""
            }
            onCopy={context.methods.handleShareCodeCopy}
            overrideButtonStyle={CopyButtonStyled}
            copyButtonAccessibleLabel={shareCode.copyButtonText}
          />
        </>
      )}
    </>
  );
};

export default PartnerStandardSharingTools;
