import React, { FC } from "react";
import { FaTelegram } from "react-icons/fa";

import {
  ShareButtonLabelStyled,
  ShareButtonOuterStyled,
  ShareButtonStyled,
} from "../ShareButton.styled";
import usePopupWindow from "hooks/PopupWindow";
import useRecordEvent from "hooks/RecordEvent";

import { EventEnum } from "types/graphql";
import { ShareTypes } from "../ShareButton.types";

export type Props = {
  shareUrl: string;
  shareText: string;
  buttonText?: string;
};

const TelegramShare: FC<Props> = ({ shareUrl, shareText, buttonText = "Telegram" }) => {
  const popupWindow = usePopupWindow();
  const recordEvent = useRecordEvent();

  const handleTelegramShareClick = (): void => {
    popupWindow(
      `https://t.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(
        shareText
      )}`
    );

    recordEvent(EventEnum.Share, { share_network: "Telegram" });
  };

  return (
    <ShareButtonOuterStyled>
      <ShareButtonStyled onClick={handleTelegramShareClick} shareType={ShareTypes.Telegram}>
        <FaTelegram />
      </ShareButtonStyled>
      <ShareButtonLabelStyled>{buttonText}</ShareButtonLabelStyled>
    </ShareButtonOuterStyled>
  );
};

export default TelegramShare;
