import styled from "styled-components";
import { ButtonStyled } from "components/button/Button.styled";
import { useForLargeContainer } from "hooks/ResponsiveStyle";

export const CallToActionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin: 30px 0;

  ${ButtonStyled} {
    max-width: 310px;
  }

  ${() =>
    useForLargeContainer(`
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: left;
    `)}
`;
