import React, { FC, useContext } from "react";
import renderTemplate from "lib/Template";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";

import {
  SwitchGroup,
  SwitchLabel,
  SwitchStyled,
  SwitchWrapper,
  SwitchSpan,
  SwitchErrorWrapper,
  SwitchErrorStyled,
} from "./Switch.styled";

type Props = {
  label: string;
  spaceBetween?: boolean;
  name: string;
  register?: any;
  required?: boolean;
  errorMessage?: string;
  showError?: boolean;
  displayAsCheckbox?: boolean;
};

export const Switch: FC<Props> = ({
  label,
  spaceBetween,
  name,
  register,
  showError,
  errorMessage,
  required,
  displayAsCheckbox,
}) => {
  const { sessionContentReplacements } = useContext<DefaultContext>(StepContext);

  const switchError = showError && (
    <SwitchErrorWrapper>
      <SwitchErrorStyled>{errorMessage || "Required"}</SwitchErrorStyled>
    </SwitchErrorWrapper>
  );

  const labelContent = <SwitchSpan>{renderTemplate(label, sessionContentReplacements)}</SwitchSpan>;

  return (
    <>
      {displayAsCheckbox ? (
        <label>
          <input
            type="checkbox"
            data-testid={name}
            name={name}
            {...(register ? register(name, { required }) : {})}
          />
          {labelContent}
        </label>
      ) : (
        <SwitchGroup>
          <SwitchLabel spaceBetween={spaceBetween}>
            {labelContent}
            <SwitchStyled
              data-testid={name}
              type="checkbox"
              name={name}
              {...(register ? register(name, { required }) : {})}
            />
            <SwitchWrapper />
          </SwitchLabel>
        </SwitchGroup>
      )}
      {switchError}
    </>
  );
};

export default Switch;
