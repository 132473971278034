import styled, { DefaultTheme } from "styled-components";
import { transparentize } from "polished";
import { useForLargeContainer, useForSmallContainer } from "hooks/ResponsiveStyle";
import {
  resolveFontSize,
  resolveColor,
  setSvgCssFromAttributes,
  resolveWithDefault,
} from "styles/helpers";

export const WrapperStyled = styled.div`
  width: 100%;
`;

export const TitleStyled = styled.div`
  padding-bottom: 10px;
  span {
    color: ${({ theme }) => theme.layout.color};
    font-size: ${({ theme }) => resolveFontSize(theme, "medium")};
    font-weight: bold;
  }
`;

const textColor = (theme: DefaultTheme): string =>
  resolveWithDefault(resolveColor(theme, theme.shareArea.color), theme.colors.highlight);

const hoverTextColor = (theme: DefaultTheme): string =>
  resolveWithDefault(
    resolveColor(theme, theme.shareArea._hover.color),
    resolveColor(theme, theme.layout.backgroundColor),
  );

export const CopyButtonStyled = styled.button`
  font-family: ${({ theme }) => resolveWithDefault(theme.shareArea.fontFamily, theme.font.family)};
  font-weight: ${({ theme }) => theme.shareArea.fontWeight};
  width: 100%;
  border: none;
  min-height: ${({ theme }) => theme.shareArea.height};
  align-items: center;
  font-size: ${({ theme }) => resolveFontSize(theme, theme.shareArea.fontSize)};
  cursor: pointer;
  display: flex;
  ${() => useForLargeContainer("text-align: left;")}
  ${() => useForSmallContainer("text-align: center;")}
  background-color: ${({ theme }) =>
    resolveWithDefault(
      resolveColor(theme, theme.shareArea.backgroundColor),
      transparentize(0.8, theme.colors.highlight),
    )};
  padding: 20px;
  border-radius: ${({ theme }) => theme.shareArea.borderRadius};
  color: ${({ theme }) => textColor(theme)};
  &:hover {
    background-color: ${({ theme }) =>
      resolveWithDefault(
        resolveColor(theme, theme.shareArea._hover.backgroundColor),
        theme.colors.highlight,
      )};
    color: ${({ theme }) => hoverTextColor(theme)};
    svg {
      stroke: ${({ theme }) => hoverTextColor(theme)};
      font-size: 20px;
    }
  }

  ${({ children }) => setSvgCssFromAttributes(children)}
  svg {
    stroke: ${({ theme }) => textColor(theme)};
    font-size: 20px;
    margin-left: 15px;
    flex-shrink: 0;
  }
`;

export const CopySpanStyled = styled.span`
  font-weight: bold;
  flex-grow: 1;
  word-break: break-all;
`;

export const ShareCodeCopySpanStyled = styled.span`
  font-weight: bold;
  flex-grow: 1;
`;

export const SuccessCopyNotificationStyled = styled.div`
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => resolveFontSize(theme, "medium")};
  cursor: default;
  background-color: ${({ theme }) =>
    resolveWithDefault(resolveColor(theme, theme.notification.backgroundColor), "transparent")};
  padding: 15px;
  border-radius: ${({ theme }) => theme.shareArea.borderRadius};
  color: ${({ theme }) =>
    resolveWithDefault(
      resolveColor(theme, theme.notification.color),
      resolveColor(theme, theme.layout.color),
    )};
  border: ${({ theme }) =>
    `1px solid ${resolveColor(theme, theme.button.primary.backgroundColor)}`};
  ${({ children }) => setSvgCssFromAttributes(children)}
  svg {
    stroke: ${({ theme }) =>
      resolveWithDefault(
        resolveColor(theme, theme.notification.successful.iconColor),
        resolveColor(theme, theme.layout.color),
      )};
    font-size: 20px;
    margin-right: 15px;
    flex-shrink: 0;
  }
`;

export const SuccessCopySpanStyled = styled.span`
  font-weight: bold;
  text-align: center;
`;
