import React, { FC, useContext } from "react";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";

import { PageDisabledStep } from "types/graphql";

import DefaultLayout from "./layouts";
import { Lego } from "lib/Lego";
import { Context, BrickTypes } from "lib/Lego/Shared/PageDisabled";

export type PageDisabledProps = {
  stepData: PageDisabledStep;
};

const PageDisabled: FC<PageDisabledProps> = ({ stepData }) => {
  const {
    appConfig: { isPreview },
    sessionConfig,
    sessionContentReplacements,
  } = useContext<DefaultContext>(StepContext);

  const context: Context = {
    step: stepData,
    session: sessionConfig,
    methods: {},
    state: {
      sessionContentReplacements,
    },
  };

  return (
    <Lego<Context>
      brickTypes={BrickTypes}
      layout={DefaultLayout}
      context={context}
      isPreview={isPreview}
    />
  );
};

export default PageDisabled;
