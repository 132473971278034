import React, { FC } from "react";
import { FaFacebookF } from "react-icons/fa";

import {
  ShareButtonLabelStyled,
  ShareButtonOuterStyled,
  ShareButtonStyled,
} from "../ShareButton.styled";
import usePopupWindow from "hooks/PopupWindow";
import useRecordEvent from "hooks/RecordEvent";

import { EventEnum } from "types/graphql";
import { ShareTypes } from "../ShareButton.types";

export type Props = {
  shareUrl: string;
  buttonText?: string;
};

const Facebook: FC<Props> = ({ shareUrl, buttonText = "Facebook" }) => {
  const popupWindow = usePopupWindow();
  const recordEvent = useRecordEvent();

  const handleFacebookShareClick = (): void => {
    popupWindow(
      `https://www.facebook.com/dialog/share?app_id=${
        import.meta.env.VITE_FACEBOOK_APP_ID
      }&href=${encodeURIComponent(shareUrl)}`,
    );

    recordEvent(EventEnum.Share, { share_network: "Facebook" });
  };

  return (
    <ShareButtonOuterStyled>
      <ShareButtonStyled onClick={handleFacebookShareClick} shareType={ShareTypes.Facebook}>
        <FaFacebookF />
      </ShareButtonStyled>
      <ShareButtonLabelStyled>{buttonText}</ShareButtonLabelStyled>
    </ShareButtonOuterStyled>
  );
};

export default Facebook;
